// Replace the below API_URL value with valid API endpoint domain for running the application locally
const API_URL = process.env.REACT_APP_API;
//const API_URL = 'https://6jhk5gseb3.execute-api.us-west-2.amazonaws.com/dev';
const DAM_URL =
  window.location.href.includes('localhost') ||
  window.location.href.includes('digital-library-client-dev.airmi-test.nikecloud.com')
    ? 'https://api.preprod.dam.nike.com/v1/airmi'
    : 'https://api.dam.nike.com/v1/airmi';

export const serverHost = `${API_URL}/`;
export const serverHostDAM = `${DAM_URL}/`;

export const apiConfig = {
  helloWorld: {
    name: 'helloWorld',
    endPointUrl: `${serverHost}hello-world`,
    method: 'GET'
  },
  getDashboardData: {
    name: 'getDashboardData',
    endPointUrl: `${serverHost}categories`,
    method: 'GET'
  },
  userStatistics: {
    name: 'getuserStatistics',
    endPointUrl: `${serverHost}user-stats`,
    method: 'GET'
  },
  getAllAirbags: {
    name: 'getAllAirbags',
    endPointUrl: `${serverHost}list-airbags`,
    method: 'GET'
  },
  getOrCreateUserDetails: {
    name: ' getOrCreateUserDetails',
    endPointUrl: `${serverHost}user`,
    method: 'GET & POST'
  },
  getCompleteAirbagDetails: {
    name: 'getCompleteAirbagDetails',
    endPointUrl: `${serverHost}airbag`,
    method: 'GET'
  },
  getMassUploadDetails: {
    name: 'getMassUploadDetails',
    endPointUrl: `${serverHost}massUpdateAirbag`,
    method: 'PUT'
  },
  addProduct: {
    name: ' addProduct',
    endPointUrl: `${serverHost}airbags`,
    method: 'POST'
  },
  addLaunchImg: {
    name: ' addLaunchImg',
    endPointUrl: `${serverHost}airbag`,
    method: 'POST'
  },
  getDynamicOptions: {
    name: 'getDynamicOptions',
    endPointUrl: `${serverHost}field-val`,
    method: 'POST'
  },
  cheatSheet: {
    name: 'getCheatSheet',
    endPointUrl: `${serverHost}cheat-sheet`,
    method: 'GET'
  },
  postCommercialFormReq: {
    name: 'getDynamicOptions',
    endPointUrl: `${serverHost}checkout`,
    method: 'POST'
  },
  faq: {
    name: 'getFAQData',
    endPointUrl: `${serverHost}list-faq`,
    method: 'GET'
  },
  faqCategories: {
    name: 'getFAQCategories',
    endPointUrl: `${serverHost}faq-topics`,
    method: 'GET'
  },
  faqDeleteQstn: {
    name: 'FAQdeleteQstn',
    endPointUrl: `${serverHost}faq/topicid/content/contentid`,
    method: 'DELETE'
  },
  faqDeleteCat: {
    name: 'FAQdeleteCategory',
    endPointUrl: `${serverHost}faq/topicid`,
    method: 'DELETE'
  },
  launchProdDelete: {
    name: 'launchProdDelete',
    endPointUrl: `${serverHost}airbag/id/launchProduct/launchProductId`,
    method: 'DELETE'
  },
  secondaryImgDel: {
    name: 'secondaryImgDel',
    endPointUrl: `${serverHost}air-image/secondaryImageId`,
    method: 'DELETE'
  },
  faqEditQstn: {
    name: 'faqEditQstn',
    endPointUrl: `${serverHost}faq-content`,
    method: 'PUT'
  },
  faqAddCat: {
    name: 'faqAddCat',
    endPointUrl: `${serverHost}add-faq`,
    method: 'POST'
  },
  faqGetAttchmentName: {
    name: 'faqGetAttchmentName',
    endPointUrl: `${serverHost}faq/attachments`,
    methos: 'GET'
  },
  faqGetAttchmentContent: {
    name: 'faqGetAttchmentContent',
    endPointUrl: `${serverHost}faq/attachment`,
    methos: 'GET'
  },
  allUserStatistics: {
    name: 'allUserStatistics',
    endPointUrl: `${serverHost}list-users`,
    methos: 'GET'
  },
  getFilterRange: {
    name: 'getFilterRange',
    endPointUrl: `${serverHost}field-range`,
    methos: 'GET'
  },
  uploadMultiImg: {
    name: 'uploadMultiImg',
    endPointUrl: `${serverHost}air-image`,
    method: 'POST'
  },
  editUploadMultiImg: {
    name: 'edituploadMultiImg',
    endPointUrl: `${serverHost}air-image/secondaryImageId`,
    method: 'PUT'
  },
  sortFaq: {
    name: 'sortFaq',
    endPointUrl: `${serverHost}sort-faq`,
    method: 'PUT'
  },
  fetchSTLMetaData: {
    name: 'fetchSTL',
    endPointUrl: `${serverHostDAM}search?query=attributes.airbagAssetId:`,
    method: 'GET'
  },
  fetchSTL: {
    name: 'fetchSTL',
    endPointUrl: `${serverHostDAM}content/`,
    method: 'GET'
  },
  createSTLMeta: {
    name: 'createSTLMeta',
    endPointUrl: `${serverHostDAM}assets`,
    method: 'POST'
  },
  createSTLImg: {
    name: 'createSTLImg',
    endPointUrl: `${serverHostDAM}content/`,
    method: 'POST'
  },
  deleteSTLImg: {
    name: 'deleteSTLImg',
    endPointUrl: `${serverHostDAM}assets`,
    method: 'POST'
  }
};
