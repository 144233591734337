import { createServiceAction } from '../util/actionHelper.js';
import { dataClear } from '../actions/getdetailsCardActions';
const defaultState = {
  airbagDetails: null,
  userStatsData: [],
  noData: false,
  isLoading: false
};

export default function getDetails(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getDetailsCardData', 'success').type:
      return { ...state, airbagDetails: action.data.data };
    case createServiceAction('userStatistics', 'success').type:
      const noDataFlag =
        action.data.data.userLogs.length === 0 &&
        action.data.data.newUsers.length === 0 &&
        action.data.data.activeUserCount === 0
          ? true
          : false;
      return {
        ...state,
        userStatsData: [...action.data.data.userLogs, ...action.data.data.newUsers],
        totUsersLoggedIn: action.data.data.userLogs,
        activeUserCount: action.data.data.activeUserCount,
        totNewUsers: action.data.data.newUsers,
        noData: noDataFlag,
        isLoading: false
      };
    case createServiceAction('userStatistics', 'loading').type:
      return { ...state, noData: false, isLoading: true };
    case createServiceAction('getAllUserData', 'success').type:
      return { ...state, isLoading: false };
    case dataClear.type:
      return { ...state, airbagDetails: null };

    default:
      return state;
  }
}
