import { oAuthConfig } from './configs/oAuthConfig.js';

const config = {
  appName: 'Digital Library',
  appFullName: 'Digital Library',
  oAuth: {
    client_id: 'nike.airmi.digital-library',
    response_type: 'id_token token',
    scope: 'openid profile email address phone offline_access',
    redirectUri: window.location.origin,
    authorization: oAuthConfig.authorizationUrlProd
  }
};

export default config;
