import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

//const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0px 10px 0`,
  width: '100%',
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#e3e3e3',
  border: '1px solid grey',
  borderRadius: '6px',
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  height: '93%',
  overflowY: 'auto'
});

export default class SubList extends React.Component {
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { subItems, type, category, catId, onSelectQuestion, isAdmin } = this.props;

    return (
      <Droppable droppableId={type} type={`droppableSubItem`}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} className="faq-sublist-area">
            {subItems &&
              subItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!isAdmin}>
                  {(provided, snapshot) => (
                    <div
                      onClick={() =>
                        onSelectQuestion({ ...item, category, topicid: catId, contentid: item.id, faqDoc: item.faqDoc })
                      }
                      //style={{ display: "flex" }}
                      className="mr1-sm"
                    >
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div className="ncss-col-sm-10">{item.qstn}</div>
                        {/* <div
                        {...provided.dragHandleProps}
                        className="ncss-col-sm-2"
                        style={{
                          display: "block",
                          margin: "2px 10px",
                      </div>
                      {provided.placeholder}
                          border: "1px solid #000",
                          padding : '0px 15px',
                          background:'#fff',
                          width:'45px'
                        }}
                      >
                      <i class="fas fa-arrows-alt"></i>
                      </div> */}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}
