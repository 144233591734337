import { createServiceAction } from '../util/actionHelper.js';
import {
  toggleAddProductModal,
  clearFormValueAddProduct,
  changeLoaderStatus,
  resetAdminGrid,
  disableUpdateButton,
  massUploadData,
  massUploadNewData,
  updateAdminInfo,
  removeEditData
} from '../actions/addProductActions.js';

import keys from 'lodash/keys';
import pick from 'lodash/pick';

const defaultState = {
  adminData: {},
  enableSubmit: false,
  loaderStatus: false,
  noData: false,
  isLoading: false,
  openConfirmation: false,
  getCurrentCount: 0,
  nextOffset: 0,
  limit: 250,
  adminGridInfo: [],
  editedData: [],
  newAirbagData: [],
  apiResponse: []
};
const decoration = {
  decorationList: []
};
let model = {
    productCode: null,
    platform: null,
    initialInflationPressure: [],
    availableSizes: [],
    launchProductSampleSizes: [],
    pdmId: null,
    noDecoration: true,
    allDecorationPricePresent: false,
    decorationList: [],
    decName: [],
    decPrice: [],
    launchShoes: null,
    airsoleHeight: [],
    pcxId: null,
    bagType: null,
    status: null,
    landedPrice: null,
    recycledContent: null,
    airbagComments: null,
    launchCategories: null,
    launchSeasons: null,
    fabricHeight: null,
    sheetThickness: null,
    dcsSingleColorPrice: '',
    handed: null,
    notes: null,
    fileName: null,
    imgData: null,
    prevReason: [],
    type: null,
    techSpec: null,
    launchImgs: null,
    toolingExist: null,
    archiveDate: null,
    otherImageNames: [],
    otherImageTypes: [],
    shPrice: null,
    airBagPrice: null
  },
  editInitStatus = null;

function onAttachmentImgFun(action) {
  model.fileName = action.payload.fileName;
  model.imgData = action.payload.base64;
  return validateFields();
}

function onAttachmentDocFun(action) {
  let docArr = [];
  if (model.techSpec) {
    model.techSpec.forEach(value => {
      docArr.push(value);
    });
  }
  docArr.push(action.payload);
  model.techSpec = docArr;
  return validateFields();
}

function onAttachmentLaunchFun(action) {
  let launchImgArr = [];
  if (model.launchImgs) {
    model.launchImgs.forEach(value => {
      launchImgArr.push(value);
    });
  }
  launchImgArr.push(action.payload);
  model.launchImgs = launchImgArr;
  return validateFields();
}

function onClearAttachment(action) {
  if (action.type === 'CLEAR_ATTACHMENT' || action.type === 'CLEAR_SINGLE_AIRBAGIMAGE') {
    model.fileName = null;
    model.imgData = null;
  } else if (action.type === 'CLEAR_LAUNCH_PRDCT') {
    model.launchImgs = null;
  } else {
    model.techSpec = null;
  }
  return validateFields();
}
function onChangeFun(action) {
  switch (action.meta.field) {
    case 'productCode':
      model.productCode = action.payload;
      break;
    case 'platform':
      model.platform = action.payload;
      break;
    case 'bagType':
      model.bagType = model.platform === 'equipment' ? '' : action.payload;
      break;
    case 'availableSizes':
      const availableSizes = action.payload
        ? action.payload.map(item => {
            return item.value;
          })
        : [];
      model.availableSizes = availableSizes;
      break;
    case 'launchProductSampleSizes':
      const launchProductSampleSizes = action.payload
        ? action.payload.map(item => {
            return item.value;
          })
        : [];
      model.launchProductSampleSizes = launchProductSampleSizes;
      break;
    case 'initialInflationPressure':
      const initialInflationPressure = action.payload
        ? action.payload.map(item => {
            return item.value;
          })
        : [];
      model.initialInflationPressure = initialInflationPressure;
      break;
    case 'airsoleHeight':
      const airsoleHeight = action.payload
        ? action.payload.map(item => {
            return item.value;
          })
        : [];
      model.airsoleHeight = airsoleHeight;
      break;
    case 'launchShoes':
      const launchShoes = action.payload
        ? action.payload.map(item => {
            return item.value;
          })
        : null;
      model.launchShoes = launchShoes;
      break;
    case 'launchCategories':
      const launchCategories = action.payload
        ? action.payload.map(item => {
            return item.value;
          })
        : null;
      model.launchCategories = launchCategories;
      break;
    case 'launchSeasons':
      model.launchSeasons = action.payload
        ? action.payload.map(item => {
            return item.value;
          })
        : null;
      break;
    case 'fabricHeight':
      model.fabricHeight = action.payload;
      break;
    case 'sheetThickness':
      model.sheetThickness = action.payload;
      break;
    case 'newreason':
      model.reason = action.payload;
      break;
    case 'pdmId':
      model.pdmId = action.payload;
      break;
    case 'pcxId':
      model.pcxId = action.payload;
      break;
    case 'landedPrice':
      model.landedPrice = action.payload;
      break;
    case 'airBagPrice':
      model.airBagPrice = action.payload;
      break;
    case 'shPrice':
      model.shPrice = action.payload;
      break;
    case 'dcsSingleColorPrice':
      model.dcsSingleColorPrice = action.payload ? action.payload : '';
      break;
    case 'handed':
      model.handed = action.payload;
      break;
    // case 'fileName':
    //   model.fileName = action.payload;
    //   break;
    case 'processedImage':
      model.imgData = action.payload.base64;
      model.fileName = action.payload.fileName;
      break;
    case 'status':
      const status = action.payload ? 'Active' : 'Inactive';
      model.status = status;
      break;
    case 'toolingExist':
      model.toolingExist = action.payload;
      break;
    case 'archiveDate':
      model.archiveDate = action.payload;
      break;
    case 'multiImgArray':
      model.otherImageNames = action.payload.map(image => image.fileName);
      model.otherImageTypes = action.payload.map(image => image.imageType);

      break;
    default:
      break;
  }

  if (action.meta.field.indexOf('decoration[') !== -1) {
    model.noDecoration = false;
    if (
      model &&
      model.decName &&
      model.decName.filter(function(el) {
        return el != null;
      }).length >= 0
    ) {
      model.decName[action.meta.field.slice(11, -1)] = action.payload;
    }
  }
  if (action.meta.field.indexOf(`decorationPrice`) !== -1) {
    model.noDecoration = false;
    if (model && model.decPrice) {
      model.decPrice[action.meta.field.slice(15)] = action.payload;
    }
  }
  if (
    model &&
    model.decName &&
    model.decName.filter(function(el) {
      return el != null;
    }).length <= 0
  ) {
    model.noDecoration = true;
  }
  if (action.meta.field.indexOf(`decorationList`) !== -1) {
    decoration.decorationList = action.payload && action.payload.map(a => a.value);
    let difference = model.decName
      .filter(x => !decoration.decorationList.includes(x))
      .filter(function(el) {
        return el != null;
      });

    // May have some issue in first index remove Will Remove the comment in next PR if new method working all scenario
    //delete model.decName[(model.decName).indexOf(difference[0])]
    model.decName.splice(model.decName.indexOf(difference[0]), 1);
  }
  return validateFields();
}

function validateFields() {
  const decNameLength = model.decName
    ? model.decName.filter(function(el) {
        return el != null;
      }).length
    : 0;
  const decPriceLength = model.decPrice
    ? model.decPrice.filter(function(el) {
        return el;
      }).length
    : 0;

  const ImageNamesList = model.otherImageNames && model.otherImageNames.filter(fileName => fileName != null);
  const ImageTypeList = model.otherImageTypes && model.otherImageTypes.filter(fileName => fileName != null);
  if (decNameLength > 0) {
    for (let i = 0; i < model.decName.length; i++) {
      if (model.decName[i] && model.decPrice[i]) {
        model.allDecorationPricePresent = true;
      } else if (model.decName[i]) {
        model.allDecorationPricePresent = false;
      }
    }
  }

  if (model.status === 'Active') {
    if (ImageNamesList && ImageTypeList && ImageNamesList.length !== ImageTypeList.length) {
      return false;
    }
    if (model.platform !== 'equipment') {
      if (
        model.productCode &&
        model.platform &&
        model.bagType &&
        model.landedPrice &&
        model.availableSizes.length > 0 &&
        model.pcxId &&
        model.pdmId &&
        (model.noDecoration ||
          (!model.noDecoration && decPriceLength >= decNameLength && model.allDecorationPricePresent) ||
          (model.type === 'Edit' && model.decName.length === 0 && model.decPrice.length === 0)) &&
        model.fileName &&
        model.imgData
      ) {
        if (ImageNamesList && ImageTypeList && ImageNamesList.length !== ImageTypeList.length) {
          return true;
        }

        if (model.type === 'Edit' && model.status !== editInitStatus) {
          return model.reason ? true : false;
        }
        return true;
      }
    }
    if (model.platform === 'equipment') {
      if (
        model.productCode &&
        model.platform &&
        (model.noDecoration ||
          (!model.noDecoration && model.allDecorationPricePresent) ||
          (model.type === 'Edit' && model.decName.length === 0 && model.decPrice.length === 0)) &&
        model.fileName &&
        model.imgData
      ) {
        if (model.type === 'Edit' && model.status !== editInitStatus) {
          return model.reason ? true : false;
        }
        return true;
      }
    }

    if (ImageNamesList && ImageTypeList && ImageNamesList.length !== ImageTypeList.length) {
      return false;
    }
    return false;
  } else {
    //if status inactive make productcode,platform,bagtype & reason mandatory & archivedate
    if (ImageNamesList && ImageTypeList && ImageNamesList.length !== ImageTypeList.length) {
      return false;
    }
    if (
      model.productCode &&
      model.platform &&
      model.platform !== 'equipment' &&
      model.bagType &&
      (model.noDecoration ||
        (!model.noDecoration && decPriceLength >= decNameLength && model.allDecorationPricePresent) ||
        (model.type === 'Edit' && model.decName.length === 0 && model.decPrice.length === 0))
    ) {
      if (model.type === 'Edit' && model.status !== editInitStatus) {
        return model.reason ? true : false;
      }
      return true;
    } else if (
      model.productCode &&
      model.platform &&
      model.platform === 'equipment' &&
      (model.noDecoration ||
        (!model.noDecoration && decPriceLength >= decNameLength && model.allDecorationPricePresent) ||
        (model.type === 'Edit' && model.decName.length === 0 && model.decPrice.length === 0)) &&
      model.fileName &&
      model.imgData
    ) {
      if (model.type === 'Edit' && model.status !== editInitStatus) {
        return model.reason ? true : false;
      }
      return true;
    } else {
      return false;
    }
  }
}

function resetModel() {
  model = {
    productCode: null,
    platform: null,
    initialInflationPressure: [],
    availableSizes: [],
    launchProductSampleSizes: [],
    pdmId: null,
    noDecoration: true,
    allDecorationPricePresent: false,
    decName: [],
    decPrice: [],
    decoration: false,
    decorationList: [],
    launchShoes: null,
    airsoleHeight: [],
    pcxId: null,
    bagType: null,
    status: null,
    landedPrice: null,
    recycledContent: null,
    airbagComments: null,
    launchCategories: null,
    launchSeasons: null,
    fabricHeight: null,
    sheetThickness: null,
    dcsSingleColorPrice: '',
    handed: null,
    notes: null,
    fileName: null,
    imgData: null,
    prevReason: [],
    type: null,
    techSpec: null,
    launchImgs: null,
    archiveDate: null,
    toolingExist: null,
    shPrice: null,
    airBagPrice: null
  };
  editInitStatus = null;
}

function formatObject(field, dataList) {
  const result = { decoration: [], decorationPrice: [] };
  const decorationNames = field.map(a => a.decorationName);
  if (field && dataList) {
    dataList
      .filter(element => element != null)
      .map((decoration, index) => {
        if (decorationNames.includes(decoration.value)) {
          result.decoration[index] = decoration.value;
          result.decorationPrice[index] = field
            .find(x => x.decorationName === decoration.value)
            .decorationPrice.replace('$', '');
        } else {
          result.decoration[index] = null;
          result.decorationPrice[index] = null;
        }
        return result;
      });
  }
  return result;
}

function InitFields(initData, decorationList) {
  model = pick(initData, keys(model));
  model.status = initData.status ? 'Active' : 'Inactive';
  model.bagType = model.platform === 'equipment' ? '' : model.bagType;
  const decorationValues = formatObject(initData.decoration, decorationList);
  model.decorationNames = initData.decoration.length > 0 ? initData.decoration.map(a => a.decorationName) : [];
  model.decName = decorationValues.decoration;
  model.decPrice = decorationValues.decorationPrice;
  // model.archiveDate = initData.archiveDate ? initData.archiveDate : null;
  // model.toolingExist = initData.toolingExist ? initData.toolingExist : null;
  editInitStatus = model.status;
}

const formatOptions = data => {
  const obj = {};

  data &&
    data.values &&
    data.map((item, index) => {
      return (obj[item.name] = item.values.map(option => {
        return { id: index, value: option, label: option };
      }));
    });

  return obj;
};

export default function adminInfo(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getAdminData', 'loading').type:
      return {
        ...state,
        isLoading: true,
        noData: false,
        getprogress: 0,
        getCurrentCount: 1,
        nextOffset: 0,
        remainingCount: 0,
        adminGridInfo: []
      };
    case createServiceAction('getAdminData', 'success').type:
      let currentCountValue = 1;
      let totalCountInfo = 0;
      if (action.data.totalHits > state.limit) {
        currentCountValue = state.getCurrentCount + 1;
        totalCountInfo = Math.ceil(parseInt(action.data.totalHits, 10) / state.limit);
      }
      // let currentCountValue = state.getCurrentCount + 1;
      // const totalCount = Math.ceil(parseInt(action.data.totalHits, 10) / state.limit);
      let nextOffsetValue = state.limit;
      // let remainingCountValue = parseInt(totalCount - currentCountValue, 10);
      // if (totalCountInfo > 1 ) {
      //   state.getprogress = ((currentCountValue - 1).toFixed(1) / totalCountInfo) * 100;
      // }

      return {
        ...state,
        adminGridInfo: action.data.assets,
        getCurrentCount: currentCountValue,
        remainingCount: totalCountInfo,
        nextOffset: nextOffsetValue,
        openConfirmation: false,
        getprogress: state.getprogress,
        totalHits: totalCountInfo,
        limit: state.limit,
        isLoading: false
      };

    case createServiceAction('getAdminDataLazyLoad', 'success').type:
      let newData = action.data.assets;
      let temp = [...state.adminGridInfo];

      let InitialData = [];

      InitialData = [...temp, ...newData];

      let currentCountVal = state.getCurrentCount + 1;
      let totalCountVal = Math.ceil(parseInt(action.data.totalHits, 10) / state.limit);
      let nextOffsetVal = state.nextOffset + state.limit;
      if (currentCountVal > totalCountVal) {
        currentCountVal = 1;
        totalCountVal = 0;
      }

      // if (currentCountVal <= totalCountVal && totalCountVal !== 0) {
      //   state.getprogress = ((currentCountVal - 1) / totalCountVal).toFixed(1) * 100;
      // }

      return {
        ...state,
        adminGridInfo: InitialData,
        getCurrentCount: currentCountVal,
        remainingCount:
          Math.ceil(parseInt(action.data.totalHits, 10) / state.limit) - parseInt(state.getCurrentCount, 10),
        nextOffset: nextOffsetVal,
        openConfirmation: false,
        isLoading: false,
        limit: state.limit,
        getprogress: state.getprogress,
        totalHits: totalCountVal
      };
    case createServiceAction('getDynamicOptions', 'success').type:
      const dynamicOptions = formatOptions(action.data);

      return {
        ...state,
        dynamicOptionsList: dynamicOptions
      };
    case createServiceAction('closeConfirmation', 'submitted').type:
      return { ...state, isAddProductModalOpen: true, openConfirmation: false };
    case createServiceAction('closeConfirmation', 'submittedMass').type:
      return { ...state, openConfirmation: false, openMassConfirmation: false };
    case createServiceAction('onConfirmation', 'submitted').type:
      return { ...state, isAddProductModalOpen: false, openConfirmation: false };
    case createServiceAction('addProductSubmit', 'success').type:
      return { ...state, enableSubmit: false, openConfirmation: true, isLoading: false };
    case createServiceAction('editProductSubmit', 'loading').type:
    case createServiceAction('massUploadSubmit', 'loading').type:
    case createServiceAction('addProductSubmit', 'loading').type:
      return { ...state, isLoading: true };
    case clearFormValueAddProduct.type:
      return { ...state, enableSubmit: false };
    case toggleAddProductModal.type:
      resetModel();

      return { ...state, isAddProductModalOpen: action.payload };

    case '@@redux-form/CHANGE':
      const isValid = onChangeFun(action);
      if (isValid) {
        return { ...state, enableSubmit: true };
      }
      return { ...state, enableSubmit: false };

    case 'ATTACHMENT_IMAGE':
      if (action.payload) {
        const isValid = onAttachmentImgFun(action);
        if (isValid) {
          return { ...state, enableSubmit: true };
        }
        return { ...state, enableSubmit: false };
      }
      return { ...state };

    case 'ATTACH_DOCUMENT':
      if (action.payload) {
        const isValid = onAttachmentDocFun(action);
        if (isValid) {
          return { ...state, enableSubmit: true };
        }
        return { ...state, enableSubmit: false };
      }
      return { ...state };
    case 'ATTACHMENT_LAUNCH_PRDCT':
      if (action.payload) {
        const isValid = onAttachmentLaunchFun(action);
        if (isValid) {
          return { ...state, enableSubmit: true };
        }
        return { ...state, enableSubmit: false };
      }
      return { ...state };

    case 'CLEAR_TECH_SPEC':
    case 'CLEAR_ATTACHMENT':
    case 'CLEAR_LAUNCH_PRDCT':
      return { ...state, enableSubmit: onClearAttachment(action) };
    case 'CLEAR_SINGLELAUNCH_PRDCT':
    case 'CLEAR_SINGLE_AIRBAGIMAGE':
      return { ...state, enableSubmit: onClearAttachment(action) };
    case 'CLEAR_SINGLEDOC':
      return { ...state, enableSubmit: validateFields() };

    case createServiceAction('editProductSubmit', 'success').type:
      return {
        ...state,
        enableSubmit: false,
        openConfirmation: true,
        isLoading: false
      };
    case createServiceAction('massUploadSubmit', 'success').type:
      return {
        ...state,
        enableSubmit: false,
        openMassConfirmation: true,
        isLoading: false,
        apiResponse: action.data.errAirbagId
      };
    case disableUpdateButton.type:
      return { ...state, enableSubmit: false };

    case createServiceAction('getEditDetails', 'success').type:
      if (action.data) {
        InitFields(action.data, state.dynamicOptionsList.decoration);
      }

      return { ...state, enableSubmit: false, loaderStatus: false };
    case changeLoaderStatus.type:
      return { ...state, loaderStatus: action.payload };
    case createServiceAction('getAdminData', 'nodata').type:
      return { ...state, noData: true, isLoading: false };
    case massUploadData.type:
      return { ...state, editedData: action.payload };
    case massUploadNewData.type:
      return { ...state, newAirbagData: action.payload };
    case removeEditData.type:
      return { ...state, editedData: [] };
    case updateAdminInfo.type:
      return { ...state, adminGridInfo: action.payload };
    case resetAdminGrid.type:
      return { ...state, adminGridInfo: [] };
    default:
      return state;
  }
}

export const remainingCount = state => state.adminInfo.remainingCount;
export const nextOffset = state => state.adminInfo.nextOffset;
export const limit = state => state.adminInfo.limit;
export const getCurrentCount = state => state.adminInfo.getCurrentCount;
export const totalCount = state => state.adminInfo.totalHits;
