import launchCategoriesOptions from '../../constants/json/launchCategories/launchCategories.json';
import launchShoes from '../../constants/json/launchShoes/launchShoes.json';

export function getAvaliableSizes(valuesList) {
  let JSONobject = [];
  let tmplst = [];
  for (let i = 0; i <= 909; i < 10 || i >= 900 ? i++ : (i += 5)) {
    if (i === 205) {
      i = 900;
    }
    if (valuesList.includes(i)) {
      i >= 900
        ? tmplst.push({ id: i, label: i * -1, value: i })
        : JSONobject.push({ id: i, label: i === 0 ? `-${i}` : i * -1, value: i });
    }
  }
  JSONobject.unshift(...tmplst);
  return JSONobject;
}
export function getSampleSizes(valuesList) {
  let JSONobject = [];
  for (let i = 0; i <= 200; i < 10 ? i++ : (i += 5)) {
    valuesList.includes(i) && JSONobject.push({ id: i, label: i === 0 ? `-${i}` : i * -1, value: i });
  }
  return JSONobject;
}

export function getLaunchCategories(valuesList) {
  return launchCategoriesOptions.filter(function (obj) {
    return valuesList.includes(obj.value);
  });
}

export function getLaunchShoes(valuesList) {
  return launchShoes.filter(function (obj) {
    return valuesList.includes(obj.value);
  });
}

export function getOptins(valuesList) {
  return valuesList.map(function (obj) {
    return { label: obj, value: obj };
  });
}

export function formatEditDetails(data) {
  let editData = { ...data };
  editData.status = editData.status && editData.status === 'Active' ? true : false;
  editData.comments = editData.comments && editData.comments.length > 0 ? editData.comments : [];
  editData.type = 'Edit';
  editData.decName = [];
  editData.decPrice = [];
  editData.noDecoration = editData.decoration.length > 0 ? false : true;
  if (editData.availableSizes) editData.availableSizes = getAvaliableSizes(editData.availableSizes);
  if (editData.launchProductSampleSizes)
    editData.launchProductSampleSizes = getSampleSizes(editData.launchProductSampleSizes);
  if (editData.launchSeasons) editData.launchSeasons = getOptins(editData.launchSeasons);
  if (editData.landedPrice) {
    const price = editData.landedPrice.replace(/\$/g, '');
    editData.landedPrice = price ? Number(price) : '';
  }
  if (editData.airBagPrice) {
    const price = editData.airBagPrice.replace(/\$/g, '');
    editData.airBagPrice = price ? Number(price) : '';
  }
  if (editData.shPrice) {
    const price = editData.shPrice.replace(/\$/g, '');
    editData.shPrice = price ? Number(price) : '';
  }
  if (editData.dcsSingleColorPrice) {
    const dcsprice = editData.dcsSingleColorPrice.replace(/\$/g, '');
    editData.dcsSingleColorPrice = dcsprice ? Number(dcsprice) : '';
  }
  editData.decoration = editData.decoration ? editData.decoration : [];
  editData.secondaryImg =
    editData.secondaryImg &&
    editData.secondaryImg.map(function (item) {
      return {
        fileName: item.name,
        imageType: item.view,
        base64: item.base64,
        id: item.id,
      };
    });
  return editData;
}
