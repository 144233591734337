import { createAction } from '../util/index.js';

export const toggleAirBagModal = createAction('TOGGLE_ADD_MATERIAL_MODAL', requestObject => requestObject);
export const airBagId = createAction('AIR_BAG_ID', requestObject => requestObject);
export const dataReset = createAction('DATA_RESET', requestObject => requestObject);
export const airbagSearchParams = createAction('AIRBAG_SEARCH_PARAMS', requestObject => requestObject);
export const getCartData = createAction('GET_CART_DATA', requestObject => requestObject);
export const getDataForCart = createAction('GET_CART_DATA', requestObject => requestObject);
export const removeCartItem = createAction('REMOVE_CART_DATA', requestObject => requestObject);
export const clearCart = createAction('CLEAR_CART_DATA', requestObject => requestObject);
