import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SubList from './subList/subList.js';
import { IconButton } from '../index.js';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `8px 0 ${grid}px 0`,
  height: '210px',
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',
  boxShadow: '1px 5px 5px 5px #b1acac',
  paddingBottom: '60px',
  width: '90%',
  fontWeight: 'bold',

  //display:'grid',
  // verticalAlign:'top',
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  padding: grid
  // width: 200
});

class DragAndDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      initialLoad: true
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  componentDidMount() {
    const { listItems } = this.props;
    this.setState({ items: listItems });
  }

  componentWillUpdate(nextProps, nextState) {
    const { listItems } = this.props;
    const { initialLoad } = this.state;

    if (listItems !== nextProps.listItems && nextProps.listItems) {
      if (initialLoad) {
        this.setState({ initialItems: nextProps.listItems, initialLoad: false });
      }
      this.setState({ items: nextProps.listItems });
    }
    // if(nextProps.listItems && nextState.items && nextState.items.length ===0 && nextProps.listItems.length>0){
    //   this.setState({initialItems: nextProps.listItems , initialLoad: false})
    // }

    // if(initialImg !== nextProps.initialImg && nextProps.initialImg){

    //   this.setState({ image: nextProps.initialImg, fileName: nextProps.initialImgName });
    // }
  }

  objectDifference = (initialSet, newSet) => {
    // let result =  initialSet.filter(function (x) {
    //     return x.name !== newSet.reduce(function (acc, document, index) {
    //         return (document.name === x.name) ? document.name : false
    //     },0)
    // });
    //  return resul
  };

  onSelectQuestion = (item, category, topicId, contentid, faqDoc) => {
    const { onSelectQuestion } = this.props;
    onSelectQuestion(item, category, topicId, contentid, faqDoc);
  };

  onDragEnd(result) {
    // dropped outside the list

    const { items } = this.state;
    const { listItems, onDragCompleted } = this.props;

    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === 'droppableItem') {
      const currentitems = reorder(items, sourceIndex, destIndex);
      // const drop = currentitems.map(item=>({id:item.id,topic:item.topic}))
      onDragCompleted(currentitems, 'droppableItem', null, null, null);
      this.setState({
        items: currentitems
      });
    } else if (result.type === 'droppableSubItem') {
      const itemSubItemMap = items.reduce((acc, item) => {
        acc[item.id] = item.content;
        return acc;
      }, {});

      //   const sourceParentId = parseInt(result.source.droppableId);
      //   const destParentId = parseInt(result.destination.droppableId);
      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      let newItems = [...items];

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex);
        newItems = newItems.map(item => {
          if (item.id === sourceParentId) {
            item.content = reorderedSubItems;
          }
          return item;
        });

        onDragCompleted(reorderedSubItems, 'droppableSubItem', sourceParentId, destParentId, null);
        this.setState({
          items: newItems
        });
      } else {
        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);

        newItems = newItems.map(item => {
          if (item.id === sourceParentId) {
            item.content = newSourceSubItems;
          } else if (item.id === destParentId) {
            item.content = newDestSubItems;
          }
          return item;
        });
        let sourceDestObject = { sourceSubItems: newSourceSubItems, destSubItems: newDestSubItems };

        onDragCompleted(sourceDestObject, 'droppableSubItem', sourceParentId, destParentId, [draggedItem]);
        this.objectDifference(listItems, newItems);
        this.setState({
          items: newItems
        });
      }
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { items } = this.state;
    const { isAdmin, openModel, enableDeleteQstnsubmit, onClickAddNewCategory } = this.props;
    return (
      <DragDropContext classNae="drag_drop_context" onDragEnd={this.onDragEnd}>
        <Droppable classNae="drag_drop_droppable" droppableId="droppable" type="droppableItem">
          {(provided, snapshot) => (
            <div
              // className="ncss-col-sm-offset-6"
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {isAdmin && (
                <div className="ncss-col-md-4  mt1-sm faq-padding top-vertical">
                  <div className="sheet-card-faq imp-ml-8">
                    <IconButton
                      icon="plus-circle"
                      title="ADD NEW CATEGORY"
                      extraClassName="faq-add add-cat"
                      onClick={() => {
                        onClickAddNewCategory();
                      }}
                    />
                  </div>
                </div>
              )}
              {items.map((item, index) => (
                <Draggable
                  classNae="drag_drop_draggable"
                  key={item.id}
                  draggableId={item.id}
                  isDragDisabled={!isAdmin}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div className="ncss-col-sm-6" {...provided.dragHandleProps}>
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <span className=" faq-category-drag-drop">{item.topic}</span>
                        {isAdmin && (
                          <span className=" cat-option-position">
                            <IconButton
                              icon="pen"
                              extraClassName="faq-add margin-adjust"
                              onClick={() => {
                                openModel(item.topic);
                              }}
                            />
                            <IconButton
                              icon="trash"
                              extraClassName="faq-add margin-adjust"
                              onClick={() => {
                                enableDeleteQstnsubmit(item.id);
                              }}
                            />
                          </span>
                        )}
                        {/* <span
                         
                          style={{
                            display: "inline-block",
                            margin: "0 10px",
                            border: "1px solid #000"
                          }}
                        >
                          Drag
                        </span> */}
                        <hr color="#ccc" size="5" className="margin-line" />
                        <SubList
                          category={item.topic}
                          subItems={item.content}
                          catId={item.id}
                          type={item.id}
                          isAdmin={isAdmin}
                          onSelectQuestion={item => this.onSelectQuestion(item)}
                        />
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DragAndDrop;
