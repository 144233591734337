import {
  toggleAirBagModal,
  airBagId,
  dataReset,
  airbagSearchParams,
  getCartData,
  removeCartItem,
  clearCart
} from '../actions/airBagActions.js';

import { createServiceAction } from '../util/actionHelper.js';

const defaultState = {
  isAirBagModalOpen: false,
  airbagId: null,
  offset: 0,
  pagecount: 0,
  isLoading: false,
  noData: false,
  cartData: [],
  enableSubmit: false,
  tempArray: []
};

export default function statusButton(state = defaultState, action) {
  switch (action.type) {
    case toggleAirBagModal.type:
      return { ...state, isAirBagModalOpen: action.payload };

    case airBagId.type:
      return { ...state, airbagId: action.payload };

    case createServiceAction('getAirbagData', 'success').type:
      const pagecount = Math.ceil(action.data.totalHits / 12);
      defaultState.tempArray = defaultState.tempArray.concat(action.data.assets);
      return {
        ...state,
        adminGridInfo: defaultState.tempArray,
        pageCount: pagecount,
        offset: action.data.nextOffset,
        noData: false,
        isLoading: false,
        totalcount: action.data.totalHits
      };
    case createServiceAction('searchAirbagData', 'loading').type:
      return { ...state, isLoading: true, noData: false };

    case createServiceAction('searchAirbagData', 'success').type:
      const pagecount_search = Math.ceil(action.data.totalHits / 12);
      defaultState.tempArray = defaultState.tempArray.concat(action.data.assets);
      return {
        ...state,
        adminGridInfo: defaultState.tempArray,
        pageCount: pagecount_search,
        offset: action.data.nextOffset,
        noData: false,
        isLoading: false,
        totalcount: action.data.totalHits
      };
    case createServiceAction('searchAirbagData', 'nodata').type:
      return { ...state, noData: true, isLoading: false };

    case createServiceAction('getAirbagData', 'nodata').type:
      return { ...state, noData: true, isLoading: false };
    case createServiceAction('getAirbagData', 'loading').type:
      return { ...state, isLoading: true, noData: false };

    case dataReset.type:
      defaultState.tempArray = [];
      return { ...state, adminGridInfo: null };

    case airbagSearchParams.type:
      sessionStorage.setItem('airbagSearchParamsInfo', JSON.stringify(action.payload));
      return { ...state, airbagSearchParams: action.payload };

    case getCartData.type:
      return { ...state, cartData: action.payload };
    case removeCartItem.type:
      sessionStorage.setItem('cartData', JSON.stringify(action.payload));
      return { ...state, cartData: action.payload };
    case clearCart.type:
      sessionStorage.setItem('cartData', JSON.stringify([]));
      return { ...state, cartData: [] };
    default:
      return state;
  }
}
