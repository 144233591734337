import { createAction } from '../util/index.js';

export const clearFormValueAddProduct = createAction('CLEAR_FORM_VALUE_ADD_PRODUCT', requestObject => requestObject);

export const toggleAddProductModal = createAction('TOGGLE_ADD_MATERIAL_MODAL', requestObject => requestObject);

export const attachmentImage = createAction('ATTACHMENT_IMAGE', requestObject => requestObject);

export const disableUpdateButton = createAction('DISABLE_UPDATE');

export const changeLoaderStatus = createAction('LOADER_STATUS', requestObject => requestObject);

export const resetAdminGrid = createAction('RESET_ADMINGRID');

export const attachDocument = createAction('ATTACH_DOCUMENT', requestObject => requestObject);

export const clearTechSpec = createAction('CLEAR_TECH_SPEC', requestObject => requestObject);

export const clearAttachment = createAction('CLEAR_ATTACHMENT', requestObject => requestObject);

export const attachLaunchProduct = createAction('ATTACHMENT_LAUNCH_PRDCT', requestObject => requestObject);

export const attachSecondaryImg = createAction('ATTACHMENT_SECONDARY_IMG', requestObject => requestObject);

export const clearLaunchProductImg = createAction('CLEAR_LAUNCH_PRDCT', requestObject => requestObject);

export const deleteSingleLaunchProduct = createAction('CLEAR_SINGLELAUNCH_PRDCT', requestObject => requestObject);

export const deleteSingleSecImg = createAction('CLEAR_SINGLESEC_IMG', requestObject => requestObject);

export const deleteSingleAirbagImage = createAction('CLEAR_SINGLE_AIRBAGIMAGE', requestObject => requestObject);

export const deleteSingleDoc = createAction('CLEAR_SINGLEDOC', requestObject => requestObject);

export const massUploadData = createAction('MASS_UPLOAD_DATA', requestObject => requestObject);

export const massUploadNewData = createAction('MASS_UPLOAD_NEW_DATA', requestObject => requestObject);

export const removeEditData = createAction('REMOVE_EDIT_DATA', requestObject => requestObject);

export const updateAdminInfo = createAction('UPDATE_ADMIN_INFO', requestObject => requestObject);
