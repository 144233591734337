import { connect } from 'react-redux';
import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Container, H3, Link } from '../../components/index.js';
import { navMenuToggle } from '../../actions/navigationActions.js';
import { selectIsMenuOpen } from '../../reducers/navigationReducer.js';
import * as Constants from '../../constants/header.js';
import MediaQuery from 'react-responsive';
import { selectFullName, selectUserAccessToken, selectUserDetails } from '../../reducers/authReducer.js';
import logo from '../../img/logo.png';
import '../../stylus/checkout.styl';
const SearchPage = lazy(() => import('../searchAirbag/SearchPage.js'));

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: false
    };
  }

  navMenuToggle = () => {
    const { navMenuToggle } = this.props;
    navMenuToggle();
    document.body.classList.add('hide-scroll');
  };
  render() {
    const { isOpen, name, userToken, userExist, isErrorRoute, cartData } = this.props;
    const { isModalOpen } = this.state;
    let cartArray = [];
    if (cartData && cartData.length > 0) {
      cartArray = cartData;
    } else if (sessionStorage.cartData) {
      cartArray = JSON.parse(sessionStorage.cartData);
    }
    return (
      <span>
        {/* <iframe className="fake-iframe fake-iframe-header-height" title ='fake'id='fake' src='about:blank' allowTransparency="true"></iframe> */}
        <Container section className={`header-container fx-ai-con ncss-row ${isOpen ? 'hide-header' : ''}`}>
          <div className="ncss-col-sm-7 fx-ai-title">
            {userToken && userExist && !isErrorRoute ? (
              <button
                type="button"
                className="inline-display ncss-btn fs16-sm nav-menu-btn"
                onClick={this.navMenuToggle}
              >
                <i className="g72-menu text-color-white " />{' '}
                <MediaQuery minWidth={1025}>
                  <span className="text-color-white u-bold">Menu</span>
                </MediaQuery>
              </button>
            ) : null}
            <MediaQuery minWidth={1025}>
              <H3 className="inline-display  header-brand pl8-sm">
                {/* <i className="g72-swoosh nav-brand fs22-sm menu-logo" /> */}
                <img src={logo} alt="logo" className="logo" />
                {/* <span className="ncss-brand text-color-white u-bold inline-display">{Constants.APPNAME}</span> */}
              </H3>
            </MediaQuery>
            <Link to="/">
              <span className="ncss-brand text-color-white u-bold header-font">{Constants.APPNAME}</span>
            </Link>
          </div>
          <div className="ncss-col-sm-6 ncss-col-md-4 u-bold header-menu-btn">
            {name ? (
              <MediaQuery minWidth={1025}>
                <span className="inline-display relative-position text-color-white top-align ">
                  {Constants.LOGGEDTEXT} {name}
                </span>
              </MediaQuery>
            ) : null}

            {userToken && userExist && !isErrorRoute ? (
              <Link to="/airbag-cart-details" className={cartArray.length > 0 ? 'checkout' : 'noitems checkout'}>
                <i className="fas fa-shopping-cart badge" />
                {cartArray.length > 0 ? (
                  <span className="badge-cart" id="cartCount">
                    {' '}
                    {cartArray.length}{' '}
                  </span>
                ) : null}
              </Link>
            ) : null}
            {userToken && userExist && !isErrorRoute ? (
              <li
                to=""
                className={'noitems searchicon '}
                onClick={() => {
                  this.setState({ isModalOpen: true });
                  return false;
                }}
              >
                <i className="fas fa-search badge" />
                <span className="badge-cart" id="searchAll"></span>
              </li>
            ) : null}
            {isModalOpen && (
              <Suspense fallback={<div>Loading...</div>}>
                <SearchPage
                  isModalOpen={isModalOpen}
                  toggleModal={value => {
                    this.setState({ isModalOpen: value });
                  }}
                />
              </Suspense>
            )}
          </div>
        </Container>
      </span>
    );
  }
}

Header.propTypes = {
  isOpen: PropTypes.bool,
  navMenuToggle: PropTypes.func,
  name: PropTypes.string,
  userToken: PropTypes.string,
  userExist: PropTypes.bool
};

export default connect(
  state => ({
    isOpen: selectIsMenuOpen(state),
    name: selectFullName(state),
    userToken: selectUserAccessToken(state),
    userExist: !!selectUserDetails(state).userId,
    cartData: state.airBag.cartData
  }),
  { navMenuToggle }
)(Header);
