import React from 'react';
import ReactAutocomplete from 'react-autocomplete';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setSearchValue } from '../../actions/autoCompletedAction.js';

export class AutoCompleteTextInput extends React.Component {
  render() {
    const {
      options,
      className,
      wrapperStyle,
      onSelectQuestion,
      hint,
      searchValue,
      setSearchValue,
      showMailOption
    } = this.props;
    let questionsArray = [];
    if (options) {
      questionsArray = options.map(item => item && item.qstn && item.qstn.toLowerCase());
    }

    return (
      <span>
        <ReactAutocomplete
          items={options}
          shouldItemRender={(item, value) => {
            if (value.length > 0) {
              return item.qstn.toLowerCase().indexOf(value.toLowerCase()) > -1;
            }
          }}
          getItemValue={item => item.qstn}
          renderItem={(item, highlighted) => (
            <div key={item.id} style={{ backgroundColor: highlighted ? '#eee' : 'transparent', padding: '5px' }}>
              {item.qstn}
            </div>
          )}
          //renderInput={(item) => (!item?<div>Test</div>:null)}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          onSelect={(value, item) => {
            setSearchValue(value);
            onSelectQuestion({ ...item });
          }}
          wrapperStyle={wrapperStyle}
          wrapperProps={{ className: className }}
          menuStyle={{
            borderRadius: '3px',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 12px',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: '0px',
            fontSize: '90%',
            position: 'absolute',
            overflow: 'auto',
            maxHeight: '50%',
            left: '20%',
            maxWidth: '60%'
          }}
          inputProps={{ placeholder: hint }}
        />
        {showMailOption && searchValue && !questionsArray.some(v => v.includes(searchValue.toLowerCase())) ? (
          <a className="gethelp_background" href="mailto: Lst-Digital.Library.Support@nike.com">
            We don't see any results, would you like to send an e-mail to the admin?
          </a>
        ) : null}
      </span>
    );
  }
}
AutoCompleteTextInput.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  wrapperStyle: PropTypes.object
};

AutoCompleteTextInput.defaultProps = {
  options: [],
  className: ''
};

const mapStateToProps = state => ({
  searchValue: state.autocomplete.searchValue
});

const mapDispatchToProps = dispatch => ({
  setSearchValue: value => {
    dispatch(setSearchValue(value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteTextInput);
