import { createAction } from '../util/index.js';

export const categoryGroups = createAction('CATEGORY_GROUPS', requestObject => requestObject);
export const selectAllDispatch = createAction('SELECT_ALL_DISPATCH', requestObject => requestObject);
export const selectCategory = createAction('SELECT_CATEGORY', requestObject => requestObject);
export const priceRange = createAction('PRICE_RANGE', requestObject => requestObject);
export const priceRangeClear = createAction('PRICE_RANGE_CLEAR', requestObject => requestObject);
export const heightRange = createAction('HEIGHT_RANGE', requestObject => requestObject);
export const availableSizeRange = createAction('SIZE_RANGE', requestObject => requestObject);
export const launchSizeRange = createAction('LAUNCH_RANGE', requestObject => requestObject);
