import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { selectFullName } from '../reducers/authReducer.js';

import { put, takeEvery, call, select } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const name = yield select(selectFullName);
    const options = { name };
    const res = yield call(postRequest, apiConfig.getOrCreateUserDetails.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response));
        yield put(createServiceAction('getUserProfile', 'requested')(action.data));
        // Code for Display Message Will remove in Next PR
        // if(action.data){
        //   // Display success message only for user details added through form
        //   yield call(displayMessage, 'success', response.message, 5000);
        // }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* createUserProfileSagas() {
  const serviceType = 'createUserProfile';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default createUserProfileSagas;
