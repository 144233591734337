import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
    try {
        yield put(createServiceAction(serviceType, 'loading')());
        const options = action && action.data ? action.data : '';
        const infoPopDuration = 1000;

        const res = yield call(getRequest, apiConfig.getDynamicOptions.endPointUrl, options);
        // Display HTTP status error message
        const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
        if (!statusErrorMessage) {
            // Parse response object
            const response = yield res.json();
            // Display server error message
            const serverErrorMessage = yield call(getServerValidationMessage, response);
            if (!serverErrorMessage && response.errorCode === 0) {
                // Dispatch TESTDETAIL_SUCCESS action with response object
                yield put(createServiceAction(serviceType, 'success')(response.data));
            }
        }
    } catch (e) {
        yield put(createServiceAction(serviceType, 'error')(e));
    }
}

function* dynamicOptions() {
    const serviceType = 'getDynamicOptions';
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default dynamicOptions;
