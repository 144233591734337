import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import auth from './authReducer.js';
import user from './userReducer.js';
import navigation from './navigationReducer.js';
import offline from './offlineReducer.js';
import request from './requestReducer.js';
import notifications from './notificationsReducer';
import downloadAttachment from './downloadAttachmentReducer';
import dashboardInfo from './dashboardInfoReducer';
import { clearFormValueAddProduct } from '../actions/addProductActions.js';
import getDetails from './getDetailsReducer';
import airBag from './airBagReducers';
import adminInfo from './adminReducer';
import filter from './filterReducer';
import addEditProductInfo from './addEditProductReducer';
import airmiRequestFormInfo from './airMIRequestFormReducer.js';
import airsoleRequest from './airsoleRequest.js';
import stlDetails from './stlDetailsReducer.js';
import {
  attachmentImage,
  attachDocument,
  clearAttachment,
  clearTechSpec,
  attachLaunchProduct,
  attachSecondaryImg,
  clearLaunchProductImg,
  deleteSingleLaunchProduct,
  deleteSingleAirbagImage,
  deleteSingleSecImg,
  deleteSingleDoc
} from '../actions/addProductActions.js';
import { createServiceAction } from '../util/actionHelper.js';
import { setEditProductDetails } from '../actions/editProductActions.js';
import cheatSheet from './cheatSheetReducer';
import search from './searchReducer.js';
import faq from './faqReducer';
import autocomplete from './autoCompleteReducer';
import airMIRequestForm from './airMIRequestFormReducer';
import difference from 'lodash/difference';

function formatChange(
  fields,
  decorationList,
  fabricHeight,
  sheetThicknessList,
  initialInflationPressure,
  airsoleHeight,
  launchShoes,
  launchCategories
) {
  const result = {
    airbagId: fields.airbagId,
    productCode: fields.productCode,
    platform: fields.platform,
    initialInflationPressure: fields.initialInflationPressure
      ? getOptionFormat(fields.initialInflationPressure, initialInflationPressure, 'initialInflationPressure')
      : [],
    availableSizes: fields.availableSizes,
    launchProductSampleSizes: fields.launchProductSampleSizes,
    decoration: fields.decoration ? formatResponseObject(fields.decoration, decorationList, 'decoration') : [],
    decorationList: decorationList ? decorationList : [],
    dcsMaterialId: fields.dcsMaterialId,
    recycledContent: fields.recycledContent ? fields.recycledContent.split('%').join('') : fields.recycledContent,
    uom: fields.uom,
    description: fields.description,
    airbagComments: fields.airbagComments,
    fabricHeightList: fabricHeight ? fabricHeight : [],
    sheetThicknessList: sheetThicknessList ? sheetThicknessList : [],
    launchCategoriesList: launchCategories ? launchCategories : [],
    launchShoesList: launchShoes ? launchShoes : [],
    initialInflationPressureList: initialInflationPressure ? initialInflationPressure : [],
    airsoleHeightList: airsoleHeight ? airsoleHeight : [],
    launchShoes: fields.launchShoes ? getOptionFormat(fields.launchShoes, launchShoes, 'launchShoes') : [],
    airsoleHeight: fields.maximumAirsoleHeight
      ? getOptionFormat(fields.maximumAirsoleHeight, airsoleHeight, 'airsoleHeight')
      : [],
    pcxId: fields.pcxId,
    pdmId: fields.pdmId,
    bagType: fields.platform === 'equipment' ? '' : fields.bagType,
    status: fields.status,
    landedPrice: fields.landedPrice,
    airBagPrice: fields.airBagPrice,
    shPrice: fields.shPrice,
    launchCategories: fields.launchCategories
      ? getOptionFormat(fields.launchCategories, launchCategories, 'launchCategories')
      : [],
    launchSeasons: fields.launchSeasons,
    fabricHeight: fields.fabricHeight ? formatResponseObject(fields.fabricHeight, fabricHeight, 'fabricHeight') : [],
    sheetThickness: fields.sheetThickness
      ? formatResponseObject(fields.sheetThickness, sheetThicknessList, 'sheetThicknessList')
      : [],
    dcsSingleColorPrice: fields.dcsSingleColorPrice,
    handed: fields.handed,
    notes: fields.notes,
    fileName: fields.fileName,
    imgData: fields.imgData,
    prevReason: fields.prevReason,
    type: fields.type,
    techSpec: fields.techSpec,
    launchImgs: fields.launchProducts,
    comments: fields.comments && fields.comments.length > 0 ? fields.comments : [],
    archiveDate: fields.archiveDate ? new Date(fields.archiveDate + ' 00:00:00') : null,
    toolingExist: fields.toolingExist
  };
  const decorationPrices = getallDecorationPrice(fields.decoration, decorationList);

  return Object.assign({}, result, decorationPrices);
}

function getOptionFormat(values, dataList, field) {
  if (field === 'airsoleHeight') {
    return (
      dataList &&
      dataList.filter(function(obj) {
        return values.includes(parseFloat(obj.value));
      })
    );
  }
  return (
    dataList &&
    dataList.filter(function(obj) {
      return values.includes(obj.value);
    })
  );
}
function getallDecorationPrice(field, dataList) {
  const decorationNames = field.map(a => a.decorationName);

  const resultElement = {};
  if (field && dataList) {
    dataList
      .filter(element => element != null)
      .map((decoration, index) => {
        if (decorationNames.includes(decoration.value)) {
          resultElement[`decorationPrice${index}`] = field
            .find(x => x.decorationName === decoration.value)
            .decorationPrice.replace('$', '');
          resultElement[`decorationDCS${index}`] = field
            .find(x => x.decorationName === decoration.value)
            .decorationDCS.replace('$', '');
          resultElement[`decorationSh`] = field
            .find(x => x.decorationName === decoration.value)
            .decorationSh.replace('$', '');
        }

        return resultElement;
      });
  }

  return resultElement;
}
function formatResponseObject(field, dataList, fieldName) {
  const resultArray = [];
  if (fieldName === 'decoration') {
    const decorationNames = field.map(a => a.decorationName);

    if (field && dataList) {
      dataList
        .filter(element => element != null)
        .map((decoration, index) => {
          if (decorationNames.includes(decoration.value)) {
            resultArray[index] = decoration.value;
          } else {
            resultArray[index] = null;
          }
          return resultArray;
        });
    }

    return resultArray;
  } else {
    if (field && dataList) {
      dataList
        .filter(element => element != null)
        .map((decoration, index) => {
          if (field.includes(decoration.value)) {
            resultArray[index] = decoration.value;
          } else {
            resultArray[index] = null;
          }

          return resultArray;
        });
    }
    return resultArray;
  }
}
const formatOptions = data => {
  const obj = {};

  data &&
    data.values &&
    data.map((item, index) => {
      return (obj[item.name] = item.values.map(option => {
        return { id: index, value: option, label: option };
      }));
    });

  return obj;
};

const form = formReducer.plugin({
  addProduct: (state, action) => {
    switch (action.type) {
      case clearFormValueAddProduct.type:
        return {
          ...state,
          values: null,
          editInitStatus: null,
          attachment: null,
          techSpec: null,
          launchImgs: null,
          secondaryImgs: null,
          removedSecImg: null
        };
      case setEditProductDetails.type:
        return {
          ...state,
          values: action.payload,
          attachment: action.payload.attachment,
          editInitStatus: action.payload.status,
          techSpec: action.payload.techSpec,
          launchImgs: action.payload.launchProducts
        };
      case attachmentImage.type:
        let abc = [];
        if (state.attachment) {
          state.attachment.forEach(value => {
            abc.push(value);
          });
        }
        abc.push(action.payload);
        return { ...state, attachment: abc };
      case attachDocument.type:
        let docArr = [];
        if (state.techSpec) {
          state.techSpec.forEach(value => {
            docArr.push(value);
          });
        }
        docArr.push(action.payload);
        return { ...state, techSpec: docArr };
      case attachLaunchProduct.type:
        let launchImgArr = [];
        if (state.launchImgs) {
          state.launchImgs.forEach(value => {
            launchImgArr.push(value);
          });
        }
        launchImgArr.push(action.payload);
        return { ...state, launchImgs: launchImgArr };
      case attachSecondaryImg.type:
        return { ...state, secondaryImgs: action.payload };
      case clearTechSpec.type:
        return { ...state, techSpec: null };
      case clearAttachment.type:
        return { ...state, attachment: null };
      case clearLaunchProductImg.type:
        return {
          ...state,
          removedLaunchImgs: state.launchImgs.every(val => 'launchProductId' in val) ? state.launchImgs : [],
          launchImgs: null
        };
      case deleteSingleLaunchProduct.type:
        let removedFile = difference(
          state.launchImgs.filter(imgData => imgData.launchProductId),
          action.payload.filter(imgData => imgData.launchProductId)
        );
        return {
          ...state,
          launchImgs: action.payload,
          removedLaunchImgs:
            state.removedLaunchImgs && state.removedLaunchImgs.length > 0
              ? [...state.removedLaunchImgs, ...removedFile]
              : removedFile
        };
      case deleteSingleSecImg.type:
        let removedImg = [];
        const removedArr = state.removedSecImg ? [...state.removedSecImg] : [];
        if (!removedArr.some(ele => ele.id === action.payload[0].id)) {
          removedImg = state.secondaryImgs.filter(function(element) {
            return element.id === action.payload[0].id;
          });
          return {
            ...state,
            removedSecImg:
              state.removedSecImg && state.removedSecImg.length > 0
                ? [...state.removedSecImg, ...removedImg]
                : removedImg
          };
        } else {
          return { ...state };
        }
      case deleteSingleAirbagImage.type:
        return {
          ...state,
          attachment: action.payload
        };
      case deleteSingleDoc.type:
        return {
          ...state,
          techSpec: action.payload
        };
      case createServiceAction('getDynamicOptions', 'success').type:
        const dynamicOptions = formatOptions(action.data);

        return {
          ...state,
          dynamicOptionsList: dynamicOptions,
          decorationList: dynamicOptions.decoration,
          fabricHeightList: dynamicOptions.fabricHeight,
          sheetThicknessList: dynamicOptions.sheetThickness,
          initialInflationPressureList: dynamicOptions.initialInflationPressure,
          airsoleHeightList: dynamicOptions.airsoleHeight,
          launchShoesList: dynamicOptions.launchShoes,
          launchCategoriesList: dynamicOptions.launchCategories
        };

      case createServiceAction('getEditDetails', 'success').type:
        return {
          ...state,
          values: state
            ? formatChange(
                action.data,
                state.values.decorationList ? state.values.decorationList : [],
                state.values.fabricHeightList ? state.values.fabricHeightList : [],
                state.values.sheetThicknessList ? state.values.sheetThicknessList : [],
                state.values.initialInflationPressureList ? state.values.initialInflationPressureList : [],
                state.values.airsoleHeightList ? state.values.airsoleHeightList : [],
                state.values.launchShoesList ? state.values.launchShoesList : [],
                state.values.launchCategoriesList ? state.values.launchCategoriesList : []
              )
            : formatChange(action.data),
          attachment: action.data.attachment,
          editInitStatus: action.data.status,
          loaderStatus: false
        };
      default:
        return state;
    }
  }
});
const rootReducer = combineReducers({
  routing,
  form,
  request,
  auth,
  user,
  navigation,
  offline,
  notifications,
  downloadAttachment,
  dashboardInfo,
  addEditProductInfo,
  getDetails,
  airBag,
  adminInfo,
  airmiRequestFormInfo,
  airsoleRequest,
  filter,
  cheatSheet,
  faq,
  autocomplete,
  airMIRequestForm,
  search,
  stlDetails
});

export default rootReducer;
