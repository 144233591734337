import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PopOver extends Component {
  render() {
    const { children } = this.props;
    return <div className="variant-box">{children}</div>;
  }
}
PopOver.propTypes = {
  children: PropTypes.object
};

export default PopOver;
