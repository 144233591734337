import { createServiceAction } from '../util/actionHelper.js';
import { selectFullName, selectEmail } from './authReducer.js';
const defaultState = {
  airbagDetails: null,
  enableNextBtn: false,
  index: 0,
  nxtpageIndex: 0
};
const defaultName = state => selectFullName(state);
const defaultEmail = state => selectEmail(state);
const defaultPart = state =>
  state.airBag.cartData.map(a => ` ${a.productCode} ${a.size} x ${a.quantity} pairs`).join(',');

let model = {
  name: defaultName,
  requesting: 'commercial airsole',
  email: defaultEmail,
  phone: null,
  dateToday: new Date(),
  needByDate: null,
  title: '',
  part: defaultPart,
  reasonForRequest: null,
  comments: null,
  chk1: false,
  chk2: false,
  chk3: false,
  shippingDestination: null,
  attention: defaultName,
  department: null,
  building: null,
  contactName: defaultName,
  contactEmail: defaultEmail,
  recipientName: defaultName,
  recipientAddress: null,
  recipientPhone: null
};

function validateFields(index, nxtpageIndex) {
  const requestFormArray = [
    ['name', 'email', 'phone', 'dateToday'], //'requesting'
    ['title', 'part', 'reasonForRequest'], // <RequestFormDetails />,
    ['chk1', 'chk2', 'chk3'], // <PolicyDisclaimer />,
    [
      ['shippingDestination'],
      ['attention', 'department', 'building'],
      ['recipientName', 'recipientAddress', 'recipientPhone'],
      ['contactName', 'contactEmail']
    ] // <ShippingInfo nextPage={nextPage} onClickNext={this.onClickNext.bind(this)} />
  ];
  let isValidate = true;
  index < 3 &&
    requestFormArray[index].forEach(function(key) {
      if (model[key] === null || model[key] === '' || ((key === 'chk1' || 'chk2' || 'chk3') && model[key] === false)) {
        isValidate = false;
      }
    });
  const subIndex = model.shippingDestination && model.shippingDestination === 'Inter Office(WHQ Only)' ? 1 : 2;

  index === 3 &&
    nxtpageIndex === 0 &&
    requestFormArray[index][0] &&
    requestFormArray[index][0].forEach(function(key) {
      if (model[key] === null || model[key] === '' || ((key === 'chk1' || 'chk2' || 'chk3') && model[key] === false)) {
        isValidate = false;
      }
    });

  index === 3 &&
    nxtpageIndex === 1 &&
    requestFormArray[index][subIndex] &&
    requestFormArray[index][subIndex].forEach(function(key) {
      if (
        model[key] === null ||
        model[key] === '' ||
        model[key].length === 0 ||
        ((key === 'chk1' || 'chk2' || 'chk3') && model[key] === false)
      ) {
        isValidate = false;
      }
    });

  index === 3 &&
    nxtpageIndex === 2 &&
    requestFormArray[index][3] &&
    requestFormArray[index][3].forEach(function(key) {
      if (
        model[key] === null ||
        model[key] === '' ||
        model[key].length === 0 ||
        ((key === 'chk1' || 'chk2' || 'chk3') && model[key] === false)
      ) {
        isValidate = false;
      }
    });
  return isValidate;
}

function requestOnChangeFun(action, index, nxtpageIndex) {
  switch (action.meta.field) {
    case 'name':
      model.name = action.payload;
      break;
    case 'phone':
      model.phone = action.payload;
      break;
    case 'email':
      model.email = action.payload;
      break;
    case 'needByDate':
      model.needByDate = action.payload;
      break;
    case 'dateToday':
      model.dateToday = action.payload;
      break;
    case 'reasonForRequest':
      model.reasonForRequest = action.payload;
      break;
    case 'title':
      model.title = action.payload;
      break;
    case 'part':
      model.part = action.payload;
      break;

    case 'comments':
      model.comments = action.payload;
      break;
    case 'chk1':
      model.chk1 = action.payload;
      break;
    case 'chk2':
      model.chk2 = action.payload;
      break;
    case 'chk3':
      model.chk3 = action.payload;
      break;
    case 'recipientAddress':
      model.recipientAddress = action.payload;
      break;
    case 'recipientName':
      model.recipientName = action.payload;
      break;
    case 'recipientPhone':
      model.recipientPhone = action.payload;
      break;
    case 'contactName':
      model.contactName = action.payload;
      break;
    case 'contactEmail':
      model.contactEmail = action.payload;
      break;
    case 'building':
      model.building = action.payload;
      break;
    case 'department':
      model.department = action.payload;
      break;
    case 'attention':
      model.attention = action.payload;
      break;
    case 'shippingDestination':
      model.shippingDestination = action.payload;
      break;
    default:
      break;
  }
  return validateFields(index, nxtpageIndex);
}

function resetModel() {
  model = {
    name: defaultName,
    requesting: 'commercial airsole',
    email: defaultEmail,
    phone: null,
    dateToday: new Date(),
    needByDate: null,
    title: '',
    part: defaultPart,
    reasonForRequest: null,
    comments: null,
    chk1: false,
    chk2: false,
    chk3: false,
    shippingDestination: null,
    attention: defaultName,
    department: null,
    building: null,
    contactName: defaultName,
    contactEmail: defaultEmail,
    recipientName: defaultName,
    recipientAddress: null,
    recipientPhone: null
  };
}

export default function airmiRequestFormInfo(state = defaultState, action) {
  switch (action && action.type) {
    case createServiceAction('submitcommercialRequest', 'submitted').type:
      return { ...state, isLoading: true, openConfirmation: false };
    case createServiceAction('submitcommercialRequest', 'success').type:
      return { ...state, openConfirmation: true, isLoading: false };
    case createServiceAction('closeConfirmationView', 'submitted').type:
      // action.data.goBack();
      return { ...state, openConfirmation: false };
    case '@@redux-form/CHANGE':
      const isValid =
        action.meta.form === 'checkoutForm'
          ? requestOnChangeFun(action, state.index, state.nxtpageIndex)
          : state.enableNextBtn;
      if (isValid) {
        return { ...state, enableNextBtn: true };
      }
      return { ...state, enableNextBtn: false };
    case createServiceAction('decrementIndex', 'decrementIndex').type:
      const isValidNxt = validateFields(state.index - 1, 0);
      return { ...state, index: state.index - 1, enableNextBtn: isValidNxt, nxtpageIndex: 0 };
    case createServiceAction('incrementIndex', 'incrementIndex').type:
      const isValidNextBtn = validateFields(state.index + 1, 0);
      return { ...state, index: state.index + 1, enableNextBtn: isValidNextBtn, nxtpageIndex: 0 };
    case createServiceAction('decrementNxtPage', 'decrementNxtPage').type:
      const valid = validateFields(state.index, state.nxtpageIndex - 1);
      return { ...state, nxtpageIndex: state.nxtpageIndex - 1, enableNextBtn: valid };
    case createServiceAction('incrementNxtPage', 'incrementNxtPage').type:
      const validbtn = validateFields(state.index, state.nxtpageIndex + 1);
      return { ...state, nxtpageIndex: state.nxtpageIndex + 1, enableNextBtn: validbtn };
    case '@@redux-form/RESET':
      if (action.meta.form === 'checkoutForm') {
        resetModel();
        return { ...state, index: 0, enableNextBtn: false, nxtpageIndex: 0 };
      } else {
        return { ...state };
      }
    default:
      return state;
  }
}
