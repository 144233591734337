import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage } from '../util/index.js';
import { postRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    const infoPopDuration = 1000;
    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    const res = yield call(postRequest, apiConfig.postCommercialFormReq.endPointUrl, action.data);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        // yield call(displayMessage, 'success', 'Request submitted successfully', infoPopDuration);
        yield put(createServiceAction(serviceType, 'success')());
        if (action.data.is3DScanfromCart) {
          yield put({ type: 'CLEAR_CART_DATA' });
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* submitAirsoleReqSaga() {
  const serviceType = 'submitAirsoleRequest';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default submitAirsoleReqSaga;
