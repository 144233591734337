import { fromJS } from 'immutable';
import get from 'lodash/get';

import {
  startAuthCheck,
  finishAuthCheck,
  setUserLogin,
  setAuthCheckError,
  setUserLogout,
  checkUserExist,
  checkValidGroup
} from '../actions/authActions.js';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  hasAuthCheckStarted: false,
  hasAuthCheckFinished: false,
  isLoading: false,
  loginError: null,
  userToken: null,
  count: 200,
  materialNextPage: 1,
  loginAuthFinish: false,
  logoutUser: false,
  userExist: true,
  validGroup: true
});

export default function auth(state = defaultState, action) {
  state = fromJS(state); // Force immutable in case of rehydration
  switch (action.type) {
    case startAuthCheck.type:
      return state.set('hasAuthCheckStarted', true).set('hasAuthCheckFinished', false);
    case setAuthCheckError.type:
      return state.set('hasAuthCheckFinished', true).set('loginError', action.payload);
    case finishAuthCheck.type:
      return state.set('hasAuthCheckFinished', true);
    case setUserLogin.type:
      return state
        .set('userToken', action.payload && action.payload.accessToken ? fromJS(action.payload) : null)
        .set('loginAuthFinish', false);
    case setUserLogout.type:
      return state.set('logoutUser', action.payload);
    case checkUserExist.type:
      return state.set('userExist', action.payload);
    case checkValidGroup.type:
      return state.set('validGroup', action.payload);
    case createServiceAction('getUserProfile', 'success').type:
      return state
        .setIn(['userToken', 'userId'], get(action.data, 'data.userId', 1))
        .setIn(['userToken', 'email'], get(action.data, 'data.email', ''))
        .setIn(['userToken', 'savedUserType'], get(action.data, 'data.userType', ''))
        .set('loginAuthFinish', true)
        .set('hasAuthCheckFinished', true);
    default:
      return state;
  }
}

export const userLoggedIn = state => state.auth.get('userToken') !== null;
export const selectUserDetails = state => (state.auth.get('userToken') ? state.auth.get('userToken').toObject() : {});
export const selectUsername = state => state.auth.getIn(['userToken', 'username']);
export const selectUserId = state => state.auth.getIn(['userToken', 'userId']);
export const selectEmail = state => state.auth.getIn(['userToken', 'email']);
export const selectFullName = state => state.auth.getIn(['userToken', 'fullName']);
export const hasAuthCheckStarted = state => state.auth.get('hasAuthCheckStarted');
export const hasAuthCheckFinished = state => state.auth.get('hasAuthCheckFinished');
export const selectUserAccessToken = state => state.auth.getIn(['userToken', 'accessToken']);
export const selectLoginError = state => state.auth.get('loginError');
export const selectGroup = state => state.auth.getIn(['userToken', 'group']);
export const isLoading = state => state.auth.get('isLoading');

// identify groups(User group/type)
export const isAdmin = state => state.auth.getIn(['userToken', 'group']) === 'Admins';
export const isUser = state => state.auth.getIn(['userToken', 'group']) === 'Users';
export const loginAuthFinish = state => state.auth.get('loginAuthFinish');
export const logout = state => state.auth.get('logoutUser');
export const validUser = state => state.auth.get('userExist');
export const validGroup = state => state.auth.get('validGroup');
