import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

class NotificationsMessage extends Component {
  render() {
    return (
      <div className="main">
        <ToastContainer position="top-center" pauseOnVisibilityChange draggable pauseOnHover />
      </div>
    );
  }
}

export default NotificationsMessage;
