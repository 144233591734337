import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest } from '../util/index.js';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

function* listener(serviceType, action) {
  try {
    const options =
      action.data.technology !== 'View All'
        ? {
            fields: ['soleHeight', 'landPrice', 'availableSizes', 'launchProductSampleSizes'],
            technology: action.data.technology
          }
        : {
            fields: ['soleHeight', 'landPrice', 'availableSizes', 'launchProductSampleSizes']
          };
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(postRequest, apiConfig.getFilterRange.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, 1000);
    if (!statusErrorMessage) {
      // Parse response object
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response.data));
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* filterRangeSaga() {
  yield takeEvery(createServiceAction('getFilterRange', 'requested').type, listener, 'getFilterRange');
  yield takeEvery(createServiceAction('searchFilterRange', 'requested').type, listener, 'searchFilterRange');
}

export default filterRangeSaga;
