import React from 'react';
import ReactAutocomplete from 'react-autocomplete';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setSearchValue } from '../../actions/autoCompletedAction.js';

class PredictiveSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onKeyPressSearch = event => {
    const { onKeyPress } = this.props;
    if (event.key === 'Enter') {
      onKeyPress();
    }
  };

  render() {
    const {
      options,
      className,
      wrapperStyle,
      hint,
      menuStyle,
      noData,
      showLoader,
      input,
      onSelectingOption,
      isFromDashboard
    } = this.props;
    return (
      <span>
        <ReactAutocomplete
          {...input}
          autoHighlight={false}
          items={options && options.length > 0 ? options : []}
          ref={el => (this.input = el)}
          getItemValue={item => item.productCode}
          renderItem={(item, highlighted) => (
            <div key={item.id}>
              <div
                key={item.id}
                className={`render-item`}
                style={{ backgroundColor: highlighted ? '#eee' : 'transparent' }}
              >
                <img alt="" width="100" height="100" src={item.imageContent} className="renderItem-imgStyle" />
                <div className="search-name-desc">
                  <div className="search-name">{item.productCode ? item.productCode.toUpperCase() : ''}</div>
                  <div className="search-desc">
                    {`${item.productCode ? item.productCode.toUpperCase() : ''} WITH BAGTYPE ${
                      item.bagType ? item.bagType.toUpperCase() : ''
                    } AND PLATFORM ${item.platform ? item.platform.toUpperCase() : ''}`}{' '}
                  </div>
                  <div className="search-price">
                    <span className="search-saleprice">
                      <span className="searchPriceGreyText"></span>
                      {item.landedPrice ? item.landedPrice : ''}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          shouldItemRender={(item, value) => {
            if (item && value && value.length > 0 && isFromDashboard) {
              return (
                (item.productCode && item.productCode.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
                (item.bagType && item.bagType.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
                (item.platform && item.platform.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
                (item.description && item.description.toLowerCase().indexOf(value.toLowerCase()) > -1)
              );
            } else if (value && value.length > 0 && !isFromDashboard) {
              return true;
            } else {
              return false;
            }
          }}
          value={input.value}
          onChange={value => input.onChange(value)}
          onSelect={(val, data) => onSelectingOption(val, data)}
          inputProps={{ placeholder: hint, onKeyDown: e => this.onKeyPressSearch(e) }}
          wrapperStyle={wrapperStyle}
          placeholder={hint}
          wrapperProps={{ className: className }}
          renderMenu={(items, value) => {
            return (
              <div style={menuStyle}>
                {items}
                {value && items.length === 0 && noData && !showLoader && (
                  <div className="noitem link">
                    NO MATCHES FOUND FOR <span className="text-color-grey">{value}</span>
                  </div>
                )}
                {value && items.length === 0 && showLoader && <div className="loaditem">.....Fetching.....</div>}
                {/* {(value && items.length > 0 && showLoader) &&
                  ( <div className="fa-2x"><i className="fas fa-spinner fa-spin"></i></div>)
              } */}
              </div>
            );
          }}
          menuStyle={menuStyle}
        />
      </span>
    );
  }
}
PredictiveSearch.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  wrapperStyle: PropTypes.object
};

PredictiveSearch.defaultProps = {
  options: [],
  className: ''
};

const mapStateToProps = state => ({
  searchValue: state.autocomplete.searchValue,
  airbagDetails: state.search.searchInfo
});

const mapDispatchToProps = dispatch => ({
  setSearchValue: value => {
    dispatch(setSearchValue(value));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PredictiveSearch);
