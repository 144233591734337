import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';
import { store } from '../index';

const toolObj = {
  Yes: 'Y',
  No: 'N',
  'N/A': 'N/A'
};

export function* listener(serviceType, action) {
  try {
    const url = apiConfig.getMassUploadDetails.endPointUrl;
    const newAirbagData = action.data.newData;
    const editVal = action.data.edited.map(editData => {
      if (editData.landedPrice && editData.landedPrice.toString().includes('$')) {
        const price = editData.landedPrice.replace(/\$/g, '');
        editData.landedPrice = price ? Number(price) : '';
      }
      if (editData.airBagPrice && editData.airBagPrice.toString().includes('$')) {
        const price = editData.airBagPrice.replace(/\$/g, '');
        editData.airBagPrice = price ? Number(price) : '';
      }
      if (editData.shPrice && editData.shPrice.toString().includes('$')) {
        const price = editData.shPrice.replace(/\$/g, '');
        editData.shPrice = price ? Number(price) : '';
      }
      if (editData.status === 'Active') {
        editData.archiveDate = null;
        editData.reason = 'Mass Upload';
      }
      if (editData.status === 'Inactive') {
        editData.reason = 'Mass Upload';
      }
      if (editData.toolingExist) {
        editData.toolingExist = toolObj[editData.toolingExist];
      }
      if (editData.airsoleHeight && editData.airsoleHeight.length >= 1) {
        let tempAir = editData.airsoleHeight;
        tempAir = editData.airsoleHeight.map(val => {
          return Number(val);
        });
        editData.airsoleHeight = tempAir;
      }
      if (editData.availableSizes && editData.availableSizes.length <= 0) {
        delete editData.availableSizes;
      }
      return editData;
    });
    const infoPopDuration = 1500;

    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(putRequest, url, editVal);
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      const response = yield res.json();
      yield put(createServiceAction(serviceType, 'success')(response.data));
      store.dispatch({ type: 'REMOVE_EDIT_DATA' });
      store.dispatch({ type: 'MASS_UPLOAD_NEW_DATA', payload: newAirbagData });
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* massUploadSubmitSagas() {
  const serviceType = 'massUploadSubmit';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default massUploadSubmitSagas;
