import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class StatusRenderer extends Component {
  render() {
    const { active } = this.props;
    return (
      <div className={`ncss-col-sm-5`}>
        <span className="">
          <i
            className={`icon-size-adjust fas ${active === 'Active' ? 'dot-active fa-check' : 'dot-inactive fa-times'}`}
          ></i>
        </span>
      </div>
    );
  }
}

StatusRenderer.propTypes = {
  active: PropTypes.string
};
