import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const options = { faqId: action.data.faqId, faqArr: action.data.faqArr },
      infoPopDuration = 1000,
      runDestUpdate = action.data.runDestUpdate ? action.data.runDestUpdate : false;
    let res;
    if (!action.data.hideUpdateMsg) {
      const msg = 'Updating FAQ Order...';
      yield call(displayMessage, 'info', msg, infoPopDuration);
    }

    if (action.data.faqArr && action.data.faqArr.length > 0) {
      res = yield call(putRequest, apiConfig.sortFaq.endPointUrl, options);
    } else if (action.data.faqArr.length === 0 && runDestUpdate) {
      yield put(createServiceAction('getFAQData', 'requested')({ destUpdation: true }));
      // yield put(createServiceAction('destUpdation', 'requested')());
    }
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      // Parse response object
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        // Dispatch TESTDETAIL_SUCCESS action with response object
        yield put(createServiceAction(serviceType, 'success')(response.data));
        if (runDestUpdate) {
          yield put(createServiceAction('getFAQData', 'requested')());
          yield put(createServiceAction('destUpdation', 'requested')());
        } else {
          yield put(createServiceAction('getFAQData', 'requested')());
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* submitFaqEditQstn() {
  const serviceType = 'updateFAQOrder';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default submitFaqEditQstn;
