import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { resetFaq } from '../actions/faqAction';

function* listener(serviceType, action) {
  try {
    const infoPopDuration = 1000,
      requestData = action.data,
      isAdd = requestData.faqId ? false : true,
      msg = isAdd ? 'Submitting your data..' : 'Updating your data..';
    if (!requestData.hideAddMsg) {
      yield call(displayMessage, 'info', msg, infoPopDuration);
    }
    const List = requestData.qstnList;
    if (isAdd) {
      const firstData = { topic: requestData.topic, sort: requestData.sort, ...List[0] },
        firstRes = yield call(postRequest, apiConfig.faqAddCat.endPointUrl, firstData),
        statusErrorMessage1 = yield call(getHTTPStatusValidationMessage, firstRes);
      if (!statusErrorMessage1) {
        const firstResponse = yield firstRes.json(),
          serverErrorMessage = yield call(getServerValidationMessage, firstResponse);
        if (!serverErrorMessage && firstResponse.errorCode === 0) {
          const qstnLength = List.length;
          if (requestData.qustId) {
            yield put(
              createServiceAction('deleteFaq', 'requested')({
                topicid: requestData.sourceId,
                contentid: requestData.qustId,
                hideMessage: true
              })
            );
          } else {
            if (qstnLength === 1) {
              yield put(resetFaq());
              yield put(createServiceAction('getFAQData', 'requested')());
            } else {
              const faqId = firstResponse.data.topicId;
              for (let i = 1; i < qstnLength; i++) {
                const data = { topic: requestData.topic, id: faqId, ...List[i] },
                  res = yield call(postRequest, apiConfig.faqAddCat.endPointUrl, data),
                  statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
                if (!statusErrorMessage) {
                  const response = yield res.json(),
                    serverErrorMessage = yield call(getServerValidationMessage, response);
                  if (!serverErrorMessage && response.errorCode === 0) {
                    yield put(createServiceAction(serviceType, 'success')(response.data));
                    if (i === qstnLength - 1) {
                      yield put(resetFaq());
                      yield put(createServiceAction('getFAQData', 'requested')());
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      for (let i = 0; i < List.length; i++) {
        const data = { topic: requestData.topic, id: requestData.faqId, ...List[i] },
          res = yield call(postRequest, apiConfig.faqAddCat.endPointUrl, data),
          statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
        if (!statusErrorMessage) {
          const response = yield res.json(),
            serverErrorMessage = yield call(getServerValidationMessage, response);
          if (!serverErrorMessage && response.errorCode === 0) {
            yield put(createServiceAction(serviceType, 'success')(response.data));
            if (requestData.qustId) {
              if (requestData.catDelete) {
                yield put(
                  createServiceAction('deleteFaq', 'requested')({ topicid: requestData.sourceId, hideMessage: true })
                );
              } else {
                yield put(
                  createServiceAction('deleteFaq', 'requested')({
                    topicid: requestData.sourceId,
                    contentid: requestData.qustId,
                    hideMessage: true
                  })
                );
              }
            }
            if (i === List.length - 1) {
              yield put(resetFaq());
              yield put(createServiceAction('getFAQData', 'requested')());
            }
          }
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* addFAQCategory() {
  const serviceType = 'addFAQCategory';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}
export default addFAQCategory;
