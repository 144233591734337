import './util/polyfills.js';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import { render } from 'react-dom';
import { LicenseManager } from 'ag-grid-enterprise/main';

import { AG_GRID_LICENSE_KEY } from './constants/appConstants';
import Root from './root/Root.js';
import configureStore from './root/store.js';
// import registerServiceWorker from './registerServiceWorker';
import { watchOnlineState } from './actions/offlineActions.js';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

// Render an empty div until the token check is done
// The createElement is for unit test
const appRoot = document.getElementById('app-host') || document.createElement('div');

export const store = configureStore();

// Track browser's online state
watchOnlineState(store);

// Start the app
render(<Root store={store} />, appRoot);
// registerServiceWorker();
