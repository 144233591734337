export function convertDateToDBFormat(str) {
  var date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join('-');
}

export function convertDateReview(str) {
  var date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join('-');
}

export function convertDate(str) {
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str)) {
    return str
      .substring(0, str.indexOf('T'))
      .split('-')
      .reverse()
      .join('-');
  }
  return '';
}

export function convertDateTimeToDBFormat(str) {
  let dateArray = str.toDateString().split(' ');
  let month = ('0' + (new Date(`${dateArray[3]}-${dateArray[1]}-${dateArray[2]}`).getMonth() + 1)).slice(-2);
  return `${dateArray[3]}-${month}-${dateArray[2]}`;
}
