import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import download from 'downloadjs';
import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
      const options = {
        id: action.data.id,
        fileName: action.data.fileName,
        formType: action.data.formType
      };
      const infoPopDuration = 1000;

      const res = yield call(getRequest, apiConfig.downloadAttachment.endPointUrl, options);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
      if(!statusErrorMessage){
        const response = yield res.json();
        yield call(displayMessage, 'info', action.data.fileName + ' is being downloaded..', infoPopDuration);
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
        if (!serverErrorMessage && response.errorCode === 0) {
          download('data:'+ response.data.contentType +';base64,'+response.data.Content, response.data.fileName, response.data.contentType)
          yield put(createServiceAction(serviceType, 'success')(response));
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* downloadAttachmentSagas() {
  const serviceType = 'downloadAttachment';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default downloadAttachmentSagas;
