import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';
import { resetFaq } from '../actions/faqAction';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const options = action && action.data ? action.data : '',
      url =
        options && options.contentid
          ? apiConfig.faqEditQstn.endPointUrl + '/' + options.contentid
          : apiConfig.faqEditQstn.endPointUrl,
      infoPopDuration = 1000,
      msg = options.update ? 'Updating..' : 'Saving your data..';

    yield call(displayMessage, 'info', msg, infoPopDuration);
    const res = yield call(putRequest, url, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      // Parse response object
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        // Dispatch TESTDETAIL_SUCCESS action with response object
        yield put(createServiceAction(serviceType, 'success')(response.data));
        if (options.update) yield put(resetFaq());
        yield put(createServiceAction('getFAQData', 'requested')());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* submitFaqEditQstn() {
  const serviceType = 'submitFaqEditQstn';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default submitFaqEditQstn;
