import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '../../stylus/grid.styl';

export class Grid extends Component {
  onGridReady = params => {
    const { setParams } = this.props;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
    if (setParams) {
      setParams(params);
    }
  };

  onGridSizeChanged = params => {
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };

  onRowDataChanged = params => {
    params.api.resetRowHeights();
  };

  render() {
    const {
      columnDefs,
      rowData,
      showFilter,
      adjustHeight,
      uploadHeight,
      headerHeight,
      onRowClick,
      onCellValueChanged,
      onCellClicked,
      edit,
      suppressEdit,
      suppressCellSelection,
      customNoRowTemplete,
      overlayLoadingTemplate,
      rowSelection,
      rowMultiSelectWithClick,
      setMaterialHeight,
      onSelectionChanged,
      excelStyles
    } = this.props;
    return (
      uploadHeight,
      (
        <div
          className={`ag-theme-balham ${
            uploadHeight
              ? 'upload-height'
              : !adjustHeight && !setMaterialHeight
              ? 'custom-height'
              : setMaterialHeight
              ? 'custom-height-material'
              : 'custom-height-lg'
          }`}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            enableSorting
            filter
            floatingFilter={showFilter}
            pagination
            paginationAutoPageSize={true}
            singleClickEdit={edit}
            suppressClickEdit={suppressEdit}
            stopEditingWhenGridLosesFocus
            onGridReady={this.onGridReady}
            // onGridSizeChanged={this.onGridSizeChanged}
            onRowDataChanged={this.onRowDataChanged}
            suppressCellSelection={suppressCellSelection}
            rowSelection={rowSelection || 'single'}
            {...(rowMultiSelectWithClick && { rowMultiSelectWithClick: true })}
            {...(onSelectionChanged && { onSelectionChanged: onSelectionChanged })}
            onRowClicked={onRowClick}
            onCellClicked={onCellClicked}
            onCellValueChanged={onCellValueChanged}
            headerHeight={headerHeight}
            overlayLoadingTemplate={overlayLoadingTemplate}
            overlayNoRowsTemplate={customNoRowTemplete}
            excelStyles={excelStyles}
          />
        </div>
      )
    );
  }
}

Grid.propTypes = {
  columnDefs: PropTypes.array,
  rowData: PropTypes.array,
  showFilter: PropTypes.bool,
  edit: PropTypes.bool,
  suppressEdit: PropTypes.bool,
  adjustHeight: PropTypes.bool,
  uploadHeight: PropTypes.bool,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  onCellValueChanged: PropTypes.func,
  setParams: PropTypes.func,
  customNoRowTemplete: PropTypes.object,
  overlayLoadingTemplate: PropTypes.object,
  setMaterialHeight: PropTypes.bool,
  suppressCellSelection: PropTypes.bool
};

Grid.defaultProps = {
  showFilter: true,
  headerHeight: 32
};

export default connect()(Grid);
