import React, { useState } from 'react';
import season from '../../constants/json/season/season.json';
import Modal from 'react-modal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import SizeBubbleRenderer from '../../views/adminGridView/adminRenderers/SizeBubbleRenderer';
import { H5, Button, Checkbox, normalizeBoolean, IconButton, YearSelect, Chip } from '../../components/index.js';
import union from 'lodash/union';
import _ from 'lodash';
import { store } from '../../index';

const LaunchSeason = props => {
  const [edit, setEdit] = useState(false);
  const [toggleModalEdit, setToggleModal] = useState(true);
  const [newLaunchSeasons, setNewLaunchSeasons] = useState(props.params.value);
  const handleClick = () => {
    setEdit(true);
    setToggleModal(true);
  };
  const toggleModal = value => {
    setNewLaunchSeasons(props.params.value);
    let tempAdmin = _.cloneDeep(props.adminGridInfo);
    let index = _.findIndex(tempAdmin, { airbagId: props.params.data.airbagId });
    tempAdmin.splice(index, 1, _.cloneDeep(props.params.data));
    store.dispatch({ type: 'UPDATE_ADMIN_INFO', payload: tempAdmin });
    setToggleModal(value);
    setEdit(false);
  };
  const deleteNewSeason = label => {
    const { change } = props;
    let result = {};
    if (newLaunchSeasons && newLaunchSeasons && newLaunchSeasons.length > 0) {
      result = newLaunchSeasons.filter(obj => {
        return obj !== label;
      });
    }
    change('launchSeasons', result.length > 0 ? result : []);
    setNewLaunchSeasons(result);
  };
  const { formValue } = props;

  const newLaunchSeasonsAdd = formValue => {
    const { change } = props;
    let newShoes = newLaunchSeasons;

    if (formValue && formValue.season && formValue.season.length > 0) {
      formValue.season
        .filter(element => element != null)
        .map(season => {
          if (formValue.Year) {
            newShoes.push(season + ' ' + formValue.Year.getFullYear());
          } else {
            newShoes.push(season + ' ' + new Date().getFullYear());
          }

          change('launchSeasons', newShoes.length > 0 ? union(formValue.launchSeasons, newShoes) : []);
          change('season', []);
          setNewLaunchSeasons(newShoes);
          return newShoes;
        });
    }
  };
  const submit = formValue => {
    let tempData = _.cloneDeep(props.editedData);
    let tempArr = _.cloneDeep(props.params.data);
    tempArr[props.params.colDef.field] = newLaunchSeasons;
    var elementIndex = tempData.findIndex(obj => obj.airbagId === props.params.data.airbagId);
    if (props.params.data.platform === 'equipment' && tempArr.availableSizes && tempArr.availableSizes.length <= 0) {
      delete tempArr.availableSizes;
    }
    if (elementIndex !== -1) {
      tempData[elementIndex] = tempArr;
    } else {
      tempData.push(tempArr);
    }
    store.dispatch({ type: 'MASS_UPLOAD_DATA', payload: tempData });
    let tempAdmin = _.cloneDeep(props.adminGridInfo);
    let index = _.findIndex(tempAdmin, { airbagId: props.params.data.airbagId });
    tempAdmin.splice(index, 1, _.cloneDeep(tempArr));
    store.dispatch({ type: 'UPDATE_ADMIN_INFO', payload: tempAdmin });
    setToggleModal(false);
    setEdit(false);
  };
  return !edit ? (
    <div onClick={handleClick}>
      {props.params.value && props.params.value.length > 0 ? (
        <SizeBubbleRenderer handleClick={handleClick} data={props.params.value} />
      ) : (
        <div onClick={handleClick} style={{ visibility: 'hidden' }}>
          MyEdit
        </div>
      )}
    </div>
  ) : (
    <Modal
      isOpen={toggleModalEdit}
      onRequestClose={() => {
        toggleModal(false);
      }}
      className="modal-container addProductModal"
      overlayClassName="modal-overlay"
    >
      {' '}
      <IconButton
        icon="times"
        className="modal-close-btn"
        onClick={() => {
          toggleModal(false);
        }}
      />
      <div className="ncss-col-sm-12 pb4-sm pr4-sm ncss-input-container">
        <H5 className="u-bold description p2-top-sm  pb2-sm ">Launch Season(s):</H5>
        <div className="season-select-component">
          <div className="ncss-col-sm-5 top-vertical">
            <div className=" p2-sm u-bold ">
              <div className="pl5-sm ncss-col-sm-12 sub-heading u-bold flex-head-center ">Season</div>
              <div className="ncss-col-sm-12">
                {season.map((data, index) => (
                  <div key={data.label} className="ncss-col-sm-3 ncss-checkbox-container">
                    <Field
                      type="checkbox"
                      component={Checkbox}
                      name={`season[${index}]`}
                      normalize={normalizeBoolean(data.label)}
                      label={data.label}
                      value={data.id}
                      id={data.id}
                      checked={true}
                      isIcon={false}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="ncss-col-sm-4">
            <div className=" pl5-sm  p2-sm u-bold ">
              <div className="ncss-col-sm-12 pl5-sm sub-heading u-bold ">Year</div>
              <div className="ncss-col-sm-11">
                <div className="ncss-col-sm-11">
                  <Field component={YearSelect} name="Year" />
                </div>
              </div>
            </div>
          </div>
          <div className="ncss-col-sm-2 pt9-sm  top-vertical">
            {formValue &&
            formValue.season &&
            formValue.season.filter(element => element != null) &&
            formValue.season.filter(element => element != null).length > 0 ? (
              <Button
                className={'grey-button'}
                onClick={() => {
                  newLaunchSeasonsAdd(formValue);
                }}
              >
                <i class="fas fa-plus season-add-new"></i> Add
              </Button>
            ) : null}
          </div>
          <div className="season-select-results">
            {newLaunchSeasons && newLaunchSeasons.length > 0 ? (
              <span>
                {newLaunchSeasons.map(data => (
                  <Chip key={data} label={data} deleteValue={deleteNewSeason} />
                ))}
              </span>
            ) : (
              <span className="season-placeholder">Select Launch Seasons</span>
            )}
          </div>
        </div>
      </div>
      <div className="button-align-right mt3-sm mb2-sm">
        <Button
          className=" mr2-sm pr5-sm pl5-sm bg-black text-color-white"
          onClick={() => submit(formValue)}
          align="right"
        >
          {'Update'}
        </Button>
        <Button
          className=" mr2-sm pr5-sm pl5-sm bg-black text-color-white"
          onClick={() => toggleModal(false)}
          align="right"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
const mapStateToProps = state => ({
  formValue: getFormValues('addProduct')(state),
  editedData: state.adminInfo.editedData,
  adminGridInfo: state.adminInfo.adminGridInfo
});
export default compose(
  reduxForm({
    form: 'addProduct',
    enableReinitialize: true
  }),
  connect(mapStateToProps)
)(LaunchSeason);
