import isEmpty from 'lodash/isEmpty';
import globalHeader from '../services/globalHeader.js';

export const postRequest = (url, payload) =>
  fetch(url, {
    method: 'POST',
    ...{ headers: globalHeader() },
    body: JSON.stringify(payload)
  });

export const getRequest = (url, queryParams, isPathParams = false) => {
  if (!isEmpty(queryParams)) {
    const queryString = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');
    if (isPathParams) {
      url = `${url}/${queryParams}`;
    } else {
      url = `${url}?${queryString}`;
    }
  }
  return fetch(url, {
    method: 'GET',
    ...{ headers: globalHeader() }
  });
};

export const getRequestJSONUrl = url => {
  return fetch(url, { method: 'GET' });
};

export const deleteRequest = url =>
  fetch(url, {
    method: 'DELETE',
    ...{ headers: globalHeader() }
  });

export const putRequest = (url, payload) =>
  fetch(url, {
    method: 'PUT',
    ...{ headers: globalHeader() },
    body: JSON.stringify(payload)
  });

export const imageputRequest = (url, payload) =>
  fetch(url, {
    method: 'PUT',
    ...{ headers: globalHeader(true) },
    body: payload
  });
