import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest } from '../util/index.js';
import { getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call, all } from 'redux-saga/effects';
import every from 'lodash/every';

export function* listener(serviceType, action) {
  try {
    const infoPopDuration = 1000;
    // yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    const res = yield all(
      action.data.multiImgArray.map(imgData => {
        const fileRequest = {
          fileName: imgData.fileName,
          imageData: imgData.base64,
          count: action.data.count,
          airbagId: action.data.airbagId,
          view: imgData.imageType ? imgData.imageType : 'Others'
        };
        let apiUrl = apiConfig.editUploadMultiImg.endPointUrl.replace('secondaryImageId', imgData.id);
        return call(putRequest, apiUrl, fileRequest);
      })
    );
    const resmultiImg = yield all(res.map(res => (res.status === 200 ? call([res, 'json']) : [])));
    if (resmultiImg.length > 0) {
      const serverError = yield all(
        resmultiImg.map(resVal => call(getServerValidationMessage, resVal, infoPopDuration))
      );
      if (serverError.filter(err => err !== '').length === 0 && every(resmultiImg, { errorCode: 0 })) {
        // yield put(createServiceAction(action.data.serviceType, 'success')(res.data));
        return;
      }
    }
    yield put(createServiceAction(action.data.serviceType, 'success')(res.data));
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* editMultiImgSaga() {
  const serviceType = 'multiImgEdit';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default editMultiImgSaga;
