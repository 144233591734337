import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest, getRequestJSONUrl } from '../util/index.js';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import isEmpty from 'lodash/isEmpty';

function* listener(serviceType, action) {
  try {
    const options = {
      technology: action.data.technology,
      limit: 12,
      offset: action.data.offset,
      imgWidth: 300
    };
    // yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(getRequest, apiConfig.getAllAirbags.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      // Parse response object
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0 && !isEmpty(response.data)) {
        const resJSON = yield call(getRequestJSONUrl, response.data);
        const responseJSON = yield resJSON.json();
        const serverJSONErrorMessage = yield call(getServerValidationMessage, responseJSON);
        if (!serverJSONErrorMessage && responseJSON.errorCode === 0) {
          yield put(createServiceAction(serviceType, 'success')(responseJSON.data));
        }
      } else {
        yield put(createServiceAction(serviceType, 'nodata')());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* airBagdetailsSaga() {
  const serviceType = 'getAirbagData';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default airBagdetailsSaga;
