import React from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

Moment.locale('en');
momentLocalizer();

const renderMonthYearPicker = ({ input: { onChange, value }, showTime, defaultValue, minDate, disabled, maxDate }) => (
  <DateTimePicker
    onChange={onChange}
    format={'MMM YYYY'}
    {...(minDate && { min: new Date() })} // Disables all dates less than the given Min date
    {...(maxDate && { max: new Date() })}
    inputProps={{ readOnly: true }}
    time={showTime}
    disabled={disabled}
    value={defaultValue || null}
    views={['year', 'decade']}
  />
);

renderMonthYearPicker.propTypes = {
  handleChange: PropTypes.func,
  minDate: PropTypes.object,
  maxDate: PropTypes.object
};

export default renderMonthYearPicker;
