import React, { useState } from 'react';
import { store } from '../../index';
import moment from 'moment';
import { compose } from 'redux';
import { Switch } from '@mui/material';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import StatusRenderer from '../../views/adminGridView/adminRenderers/statusRenderer';

const ToggleSwitch = props => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(props.params && props.params.data && props.params.value);
  const [status, setStatus] = useState(
    props.params && props.params.data && props.params.value === 'Active' ? true : false
  );
  const handleChange = e => {
    let tempData = _.cloneDeep(props.editedData);
    let tempArr = _.cloneDeep(props.params.data);
    tempArr.handed = e.target.checked;
    var elementIndex = tempData.findIndex(obj => obj.airbagId === props.params.data.airbagId);
    if (props.params.data.platform === 'equipment' && tempArr.availableSizes && tempArr.availableSizes.length <= 0) {
      delete tempArr.availableSizes;
    }
    if (elementIndex !== -1) {
      tempData[elementIndex] = tempArr;
    } else {
      tempData.push(tempArr);
    }
    let tempAdmin = _.cloneDeep(props.adminGridInfo);
    let index = _.findIndex(tempAdmin, { airbagId: props.params.data.airbagId });
    tempAdmin.splice(index, 1, tempArr);
    store.dispatch({ type: 'UPDATE_ADMIN_INFO', payload: tempAdmin });
    store.dispatch({ type: 'MASS_UPLOAD_DATA', payload: tempData });
    setValue(e.target.checked);
  };
  const handleChangeStatus = e => {
    let tempData = _.cloneDeep(props.editedData);
    let tempArr = _.cloneDeep(props.params.data);
    tempArr['status'] = e.target.checked ? 'Active' : 'Inactive';
    tempArr['archiveDate'] = moment(new Date()).format('YYYY-MM-DD');
    tempArr['isStatusChange'] = true;
    var elementIndex = tempData.findIndex(obj => obj.airbagId === props.params.data.airbagId);
    if (props.params.data.platform === 'equipment' && tempArr.availableSizes && tempArr.availableSizes.length <= 0) {
      delete tempArr.availableSizes;
    }
    if (elementIndex !== -1) {
      tempData[elementIndex] = tempArr;
    } else {
      tempData.push(tempArr);
    }
    let tempAdmin = _.cloneDeep(props.adminGridInfo);
    let index = _.findIndex(tempAdmin, { airbagId: props.params.data.airbagId });
    tempAdmin.splice(index, 1, tempArr);
    store.dispatch({ type: 'UPDATE_ADMIN_INFO', payload: tempAdmin });
    store.dispatch({ type: 'MASS_UPLOAD_DATA', payload: tempData });
    setStatus(e.target.checked);
  };
  const handleClick = () => {
    setEdit(true);
  };
  return !edit && props.params.data && props.params.colDef.field === 'status' ? (
    <div onClick={handleClick} className={`ncss-col-sm-2 pt2-sm`}>
      <StatusRenderer active={props.params.data.status} />
    </div>
  ) : !edit && props.params.data ? (
    <div onClick={handleClick} className={`ncss-col-sm-2 pt2-sm`}>
      <span>
        <i className={`icon-size-adjust fas ${value ? 'fa-check' : 'fa-times'}`}></i>
      </span>
    </div>
  ) : edit && props.params.data && props.params.colDef.field === 'status' ? (
    <Switch checked={status} onChange={handleChangeStatus} size="small" />
  ) : edit ? (
    <Switch checked={value} onChange={handleChange} size="small" />
  ) : (
    <>&nbsp;</>
  );
};
const mapStateToProps = state => ({
  dynamicOptionsList: state.adminInfo.dynamicOptionsList,
  editedData: state.adminInfo.editedData,
  adminGridInfo: state.adminInfo.adminGridInfo
});
export default compose(
  reduxForm({
    form: 'addProduct',
    enableReinitialize: true
  }),
  connect(mapStateToProps)
)(ToggleSwitch);
