import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import StlViewer from '../stlViewer/StlViewer';
import { toast } from 'react-toastify';

class STLImageEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stlFile: props.stlFile.url,
      fileName: props.stlFileName
    };
  }

  validateFile = file => {
    let format = file.name.split('.').pop();
    if (format === 'stl') {
      return true;
    } else {
      toast.error('The File Extension Should be stl', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return false;
    }
  };

  fileToDataUri = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event.target.result);
      };
      reader.readAsArrayBuffer(file);
    });

  handleImage = file => {
    const { setStlData } = this.props;
    if (this.validateFile(file)) {
      this.fileToDataUri(file).then(dataUri => {
        this.setState({ stlFile: dataUri, fileName: file.name });
        setStlData(file, file.name);
      });
    }
  };

  handleDropImage = dropped => {
    const { stlFile } = this.props;
    if (stlFile.id) {
      this.removeImageFromList();
    }
    this.setState({ stlFile: null });
    if (dropped && dropped[0]) {
      const file = dropped[0];
      this.handleImage(file);
    }
  };

  handleUploadButton = e => {
    const { stlFile } = this.props;
    if (stlFile.id) {
      this.removeImageFromList();
    }
    this.setState({ stlFile: null });
    const file = e.target.files[0];
    this.handleImage(file);
  };

  removeImageFromList = () => {
    this.setState({ stlFile: '' });
    const { setStlRemovedData, stlFile } = this.props;
    setStlRemovedData(stlFile);
  };

  render() {
    const { stlFile, fileName } = this.state;
    return (
      <div className="ncss-col-sm-12 editor-complete-area">
        <div className="image-editor ncss-col-sm-6">
          <Dropzone onDrop={this.handleDropImage} noClick noKeyboard>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="ncss-col-sm-12">
                {stlFile ? (
                  <div
                    className="ncss-col-sm-1 navbar-close-btn"
                    onClick={() => {
                      this.setState({ stlFile: null, fileName: null });
                      this.removeImageFromList();
                    }}
                  >
                    <i class="fas fa-times"></i>
                  </div>
                ) : null}
                <div className="align-center">
                  <StlViewer url={stlFile} showUploadButton={false} isQuickView={false} isEditor={true} />
                </div>
              </div>
            )}
          </Dropzone>
          <div className="editor-upload-area ncss-col-sm-6">
            <div className="editor-upload-button">
              <input name="newImage" type="file" value="" title="" onChange={this.handleUploadButton} label="label" />
            </div>
            <div className="editor-upload-file-name">{fileName}</div>
          </div>
        </div>
      </div>
    );
  }
}

STLImageEditor.propTypes = {
  label: PropTypes.string
};
STLImageEditor.defaultProps = {};

export default STLImageEditor;
