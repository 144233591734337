import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker/dist/entry.nostyle';

export const renderYearSelect = ({ input, minDate }) => (
  <div>
    <DatePicker
      value={input.value || new Date()}
      maxDetail="decade"
      minDate={minDate ? new Date() : null}
      onChange={date => input.onChange(date)}
    />
  </div>
);

renderYearSelect.propTypes = {
  input: PropTypes.object,
  minDate: PropTypes.bool
};

export default renderYearSelect;
