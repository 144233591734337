function getAuthHost() {
  let retUrl;
  const currentURL = window.location.href;
  if (
    currentURL.includes('digital-library-client-dev.airmi-test.nikecloud.com') ||
    currentURL.includes('localhost') ||
    currentURL.includes('digital-library-client-preprod.airmi-test.nikecloud.com')
  ) {
    retUrl = `https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7`;
  } else {
    retUrl = `https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7`;
  }
  return `${retUrl}/`;
}
function getIssuerHost() {
  let issueUrl;
  const currentURL = window.location.href;
  if (
    currentURL.includes('digital-library-client-dev.airmi-test.nikecloud.com') ||
    currentURL.includes('localhost') ||
    currentURL.includes('digital-library-client-preprod.airmi-test.nikecloud.com')
    // || currentURL.includes('dlairmi-np.nike.com')
  ) {
    issueUrl = 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7';
  } else {
    issueUrl = `https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7`;
  }
  return `${issueUrl}/`;
}
export const issuerHost = getIssuerHost();
export const serverHost = getAuthHost();

export const oAuthConfig = {
  issuer: issuerHost,
  clientId: 'nike.airmi.digitallibrary',
  redirectUriProd: 'https://dlairmi.nike.com',
  authorizationUrlProd: serverHost,
  allowedIdleTime: 1800000, // (milliseconds) Value should be below 1 hour(Current OKTA token expiration time). This is the time by which application will wait for before labelling user as inactive.
};

export const oidc = {
  issuer: issuerHost,
  clientId: 'nike.airmi.digitallibrary',
  redirectUri: window.location.origin + '/implicit/callback',
  responseType: 'code',
  pkce: true,
  tokenManager: {
    autoRenew: true,
    auto_renew: true,
  },
};
