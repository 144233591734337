import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage } from '../util/index.js';
import { postRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    const infoPopDuration = 1000;
    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    const res = yield call(postRequest, apiConfig.postCommercialFormReq.endPointUrl, action.data.commRequest);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        if (action.data.scanRequest !== null) {
          const res = yield call(postRequest, apiConfig.postCommercialFormReq.endPointUrl, action.data.scanRequest);
          const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
          if (!statusErrorMessage) {
            const response = yield res.json();
            // Display server error message
            const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
            if (!serverErrorMessage && response.errorCode === 0) {
              yield put(createServiceAction(serviceType, 'success')(action.data.history));
              yield put({ type: 'CLEAR_CART_DATA' });
            }
          }
        } else {
          yield put(createServiceAction(serviceType, 'success')(action.data.history));
          yield put({ type: 'CLEAR_CART_DATA' });
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* createCommercialReqSaga() {
  const serviceType = 'submitcommercialRequest';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default createCommercialReqSaga;
