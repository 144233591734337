import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import isEmpty from 'lodash/isEmpty';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    const url = apiConfig.fetchSTLMetaData.endPointUrl + action.data;
    const res = yield call(getRequest, url);
    //Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);

    if (!statusErrorMessage) {
      // Parse response object
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      let stlImageArr = [];
      stlImageArr = response.assets.filter(function(vi) {
        return vi.assetClass === '3DImage';
      });
      if (!serverErrorMessage && !isEmpty(response.assets) && stlImageArr.length > 0) {
        if (response && response.assets) {
          yield put(createServiceAction(serviceType, 'success')(stlImageArr));
        }
      } else {
        yield put(createServiceAction(serviceType, 'nodata')());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* stlMetadataSaga() {
  const serviceType = 'getSTLMetaData';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default stlMetadataSaga;
