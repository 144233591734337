import React from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1c1c1c'
    }
  }
});
const DialogHeader = props => (
  <ThemeProvider theme={darkTheme}>
    <AppBar sx={{ position: 'relative' }} color="primary" enableColorOnDark>
      <Toolbar>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          {props.title}
        </Typography>
        <IconButton edge="end" aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  </ThemeProvider>
);
export default DialogHeader;
