import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest, getRequestJSONUrl } from '../util/index.js';
import { call, put, select, takeLatest, takeEvery, delay } from 'redux-saga/effects';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { nextOffset, remainingCount, limit } from '../reducers/searchReducer.js';
import isEmpty from 'lodash/isEmpty';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const options = action.data;
    options.limit = yield select(limit);
    options.offset = yield select(nextOffset);
    options.imgWidth = 100;

    const res = yield call(getRequest, apiConfig.getAllAirbags.endPointUrl, options);
    yield delay(500, true);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      // Parse response object
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0 && !isEmpty(response.data)) {
        const resJSON = yield call(getRequestJSONUrl, response.data);
        const responseJSON = yield resJSON.json();
        const serverJSONErrorMessage = yield call(getServerValidationMessage, responseJSON);
        if (!serverJSONErrorMessage && responseJSON.errorCode === 0) {
          yield put(createServiceAction(serviceType, 'success')(responseJSON.data));
          const remainingPages = yield select(remainingCount);
          if (remainingPages > 0) {
            yield put(createServiceAction(`searchUniversalDataLazyLoad`, 'requested')(action.data));
          } else {
            yield put(createServiceAction(`searchUniversalDataLazyLoad`, 'completed')(true));
          }
        }
      } else {
        yield put(createServiceAction(serviceType, 'nodata')());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* searchUniversalData() {
  const serviceType = 'searchUniversalData';
  yield takeLatest(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  yield takeEvery(
    createServiceAction('searchUniversalDataLazyLoad', 'requested').type,
    listener,
    'searchUniversalDataLazyLoad'
  );
}

export default searchUniversalData;
