import isEmpty from 'lodash/isEmpty';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import { createServiceAction } from '../util/actionHelper.js';
import { resetCheatSheet } from '../actions/cheatSheetAction';

const defaultState = {
  cheatSheetInfo: {},
  fetchingStatus: false
};

const customizer = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

const formatCheatSheetData = data => {
  let formatData = {};
  if (data && !isEmpty(data)) {
    Object.keys(data).map(category => {
      if (category === 'emax' || category === 'vapormax' || category === 'tuned air') {
        formatData['others'] = formatData['others']
          ? mergeWith(formatData['others'], data[category], customizer)
          : data[category];
        return formatData;
      } else {
        formatData[category] = data[category];
        return formatData;
      }
    });
  }
  return formatData;
};

export default function cheatSheet(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getCheatSheetData', 'success').type:
      const airbagData = formatCheatSheetData(action.data.airbags);

      return { ...state, cheatSheetInfo: airbagData, fetchingStatus: true, equipmentInfo: action.data.equipment };

    case resetCheatSheet.type:
      return { ...state, cheatSheetInfo: null, fetchingStatus: false };

    default:
      return state;
  }
}
