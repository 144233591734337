import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

class ToolTip extends Component {
  render() {
    const { tooltip_id, display, toolTip, tooltip_class, position } = this.props;
    return (
      <span>
        <span href="#" data-tip data-for={tooltip_id}>
          {' '}
          {display}{' '}
        </span>
        <ReactTooltip id={tooltip_id} type="light" className={tooltip_class} place={position}>
          {toolTip}
        </ReactTooltip>
      </span>
    );
  }
}
ToolTip.propTypes = {
  display: PropTypes.object,
  toolTip: PropTypes.object
};

export default ToolTip;
