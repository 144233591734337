import { createServiceAction } from '../util/actionHelper.js';

const defaultState = {
  attachmentData: {},
};

export default function downloadAttachment(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('downloadAttachment', 'success').type:
      return { ...state, attachmentData: action.data };
    default:
      return state;
  }
}
