import React, { Component } from 'react';
import { Container, H1 } from '../../components/index.js';

export class UserMismatchError extends Component {
  render() {
    return (
      <Container section className="user">
        <H1>
          <span role="img" aria-label=" Not Authorized" className="color-error">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <span className="invalid-res">
            Assigned user role in OKTA and Digital Library application is different. Please contact
            Lst-Digital.Library.Support@nike.com.
          </span>
        </H1>
      </Container>
    );
  }
}

export default UserMismatchError;
