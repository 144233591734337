import React, { useState } from 'react';
import Modal from 'react-modal';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { H3, Button, IconButton } from '../../components/index.js';
import SizeBubbleRenderer from '../../views/adminGridView/adminRenderers/SizeBubbleRenderer';
import _ from 'lodash';
import { store } from '../../index';
import { Checkbox, TextField, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
const DecorationPopup = props => {
  const [edit, setEdit] = useState(false);
  const [toggleModalEdit, setToggleModal] = useState(true);
  const [newDecorations, setNewDecoration] = useState(props.params.data.decoration);
  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
    Object.entries(data).forEach(([key, value]) => {
      if (value !== '') {
        newDecorations.forEach(names => {
          if (key === names.decorationName) {
            names.decorationPrice = '$' + value;
          }
        });
      }
    });
    newDecorations.forEach(obj => {
      obj.decorationDCS = '$' + Number(_.trim(obj.decorationDCS, '$'));
      obj.decorationSh = '$' + Number(_.trim(obj.decorationSh, '$'));
    });
    let tempData = _.cloneDeep(props.editedData);
    let tempArr = _.cloneDeep(props.params.data);
    tempArr[props.params.colDef.field] = newDecorations;
    var elementIndex = tempData.findIndex(obj => obj.airbagId === props.params.data.airbagId);
    if (props.params.data.platform === 'equipment' && tempArr.availableSizes && tempArr.availableSizes.length <= 0) {
      delete tempArr.availableSizes;
    }
    if (elementIndex !== -1) {
      tempData[elementIndex] = tempArr;
    } else {
      tempData.push(tempArr);
    }
    store.dispatch({ type: 'MASS_UPLOAD_DATA', payload: tempData });
    let tempAdmin = _.cloneDeep(props.adminGridInfo);
    let index = _.findIndex(tempAdmin, { airbagId: props.params.data.airbagId });
    tempAdmin.splice(index, 1, _.cloneDeep(tempArr));
    store.dispatch({ type: 'UPDATE_ADMIN_INFO', payload: tempAdmin });
    setNewDecoration(newDecorations);
    setToggleModal(false);
    setEdit(false);
  };

  const handleClick = () => {
    setEdit(true);
    setToggleModal(true);
  };
  const toggleModal = value => {
    let tempAdmin = _.cloneDeep(props.adminGridInfo);
    let index = _.findIndex(tempAdmin, { airbagId: props.params.data.airbagId });
    tempAdmin.splice(index, 1, _.cloneDeep(props.params.data));
    store.dispatch({ type: 'UPDATE_ADMIN_INFO', payload: tempAdmin });
    setToggleModal(value);
    setEdit(false);
  };
  const submitDecoration = e => {
    if (e.target.checked) {
      let tempDec = _.cloneDeep(newDecorations);
      tempDec.push({
        decorationName: e.target.name,
        decorationPrice: 0,
        decorationSh:
          newDecorations && newDecorations.length > 0 && Object.keys(newDecorations[0]).includes('decorationSh')
            ? newDecorations[0].decorationSh
            : '$0',
        decorationDCS: 0
      });
      setNewDecoration(tempDec);
    } else {
      var removed = _.reject(newDecorations, function(el) {
        return el.decorationName === e.target.name;
      });
      setNewDecoration(removed);
    }
  };
  const handleDecorationDCS = (e, decoration) => {
    let tempArr = _.cloneDeep(newDecorations).map(obj => {
      if (obj.decorationName === decoration.label) {
        obj[e.target.name] = e.target.value;
      }
      return obj;
    });
    setNewDecoration(tempArr);
  };
  const handleDecorationSh = e => {
    let tempDec = _.cloneDeep(newDecorations).map(obj => {
      obj.decorationSh = e.target.value;
      return obj;
    });
    setNewDecoration(tempDec);
  };
  const getLandedValue = decoration => {
    for (let obj of newDecorations) {
      if (obj.decorationName === decoration.label) {
        return Number(_.trim(obj.decorationPrice, '$'));
      }
    }
    return 0;
  };
  const getDCSValue = decoration => {
    for (let obj of newDecorations) {
      if (obj.decorationName === decoration.label) {
        return Number(_.trim(obj.decorationDCS, '$'));
      }
    }
    return 0;
  };
  return !edit ? (
    <div onClick={handleClick}>
      {newDecorations && newDecorations.length > 0 ? (
        <SizeBubbleRenderer handleClick={handleClick} data={newDecorations} field="decorations" />
      ) : (
        <div onClick={handleClick} style={{ visibility: 'hidden' }}>
          MyEdit
        </div>
      )}
    </div>
  ) : (
    <div>
      <Modal
        isOpen={toggleModalEdit}
        onRequestClose={() => {
          toggleModal(false);
        }}
        className="modal-container decorationModal"
        overlayClassName="modal-overlay"
      >
        {' '}
        <IconButton
          icon="times"
          className="modal-close-btn"
          onClick={() => {
            toggleModal(false);
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="ncss-col-sm-12 pb4-sm pr4-sm ncss-input-container">
            <H3 className="u-bold description p2-top-sm  pb2-sm " style={{ marginBottom: 16 }}>
              Decorations:
            </H3>
            {props.dynamicOptionsList.decoration.map((decoration, index) => (
              <div key={decoration.label} className="ncss-col-sm-6 ncss-checkbox-container">
                <div
                  className="ncss-col-sm-4 u-bold"
                  style={{ textTransform: 'capitalize', position: 'relative', top: 10 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: '#000000cc' }}
                        checked={newDecorations.find(obj => (obj.decorationName === decoration.label ? true : false))}
                        onChange={submitDecoration}
                        name={`${decoration.label}`}
                      />
                    }
                    label={`${decoration.label}`}
                  />
                </div>
                <div className="ncss-col-sm-3">
                  <TextField
                    type="number"
                    inputProps={{
                      step: 0.01
                    }}
                    label="DCS Price"
                    name="decorationDCS"
                    disabled={!newDecorations.find(obj => (obj.decorationName === decoration.label ? true : false))}
                    defaultValue={getDCSValue(decoration)}
                    style={{ margin: '2px', background: 'white' }}
                    onChange={e => handleDecorationDCS(e, decoration)}
                  />
                </div>
                <div className="ncss-col-sm-3">
                  <TextField
                    type="number"
                    label="Landed Price"
                    disabled={!newDecorations.find(obj => (obj.decorationName === decoration.label ? true : false))}
                    defaultValue={getLandedValue(decoration)}
                    style={{ margin: '2px', background: 'white' }}
                    inputProps={{ ...register(`${decoration.label}`), step: '0.01' }}
                  />
                </div>
              </div>
            ))}
            <div style={{ marginTop: '24px' }}>
              <TextField
                type="number"
                label="S&H Price"
                inputProps={{
                  step: 0.01
                }}
                value={
                  newDecorations && newDecorations.length > 0 && Object.keys(newDecorations[0]).includes('decorationSh')
                    ? Number(_.trim(newDecorations[0].decorationSh, '$'))
                    : 0
                }
                onChange={handleDecorationSh}
                style={{ margin: '2px', background: 'white' }}
              />
            </div>
            <div className="button-align-right mt3-sm mb2-sm">
              <Button type="submit" className=" mr2-sm pr5-sm pl5-sm bg-black text-color-white" align="right">
                {'Update'}
              </Button>
              <Button
                className=" mr2-sm pr5-sm pl5-sm bg-black text-color-white"
                onClick={() => toggleModal(false)}
                align="right"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};
const mapStateToProps = state => ({
  dynamicOptionsList: state.adminInfo.dynamicOptionsList,
  decorationList:
    state.form.addProduct && !state.form.addProduct.editInitStatus ? state.form.addProduct.decorationList : [],
  editedData: state.adminInfo.editedData,
  formValue: getFormValues('addProduct')(state),
  adminGridInfo: state.adminInfo.adminGridInfo
});

export default compose(
  reduxForm({
    form: 'addProduct',
    enableReinitialize: true
  }),
  connect(mapStateToProps)
)(DecorationPopup);
