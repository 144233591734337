import React from 'react';

import { Container, H1 } from '../index.js';

const NotAuthorized = () => (
  <div className="ncss-container">
    <H1 className="align-center u-bold h1 invalid-message"></H1>
    <Container section className="user-404">
      <H1>
        <span role="img" aria-label=" Not Authorized" className="color-error">
          🔒
        </span>
        <span className="invalid-message">
          {' '}
          Sorry, you are not authorized to view the requested page.For support contact
          Lst-Digital.Library.Support@nike.com
        </span>
      </H1>
    </Container>
  </div>
);

export default NotAuthorized;
