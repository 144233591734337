// chip
import React from 'react';
import PropTypes from 'prop-types';

export const Chip = ({ label, deleteValue }) => {
  return (
    <div class="chip">
      {label}
      <span class="closebtn" onClick={() => deleteValue(label)}>
        &times;
      </span>
    </div>
  );
};

Chip.propTypes = {
  label: PropTypes.string
};

export default Chip;
