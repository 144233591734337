import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { H5, Button, IconButton } from '../index.js';

class ConfirmationBox extends Component {
  render() {
    const { submitModal, submitValue, closeModal, message, buttonsDisplay } = this.props;
    return (
      <Modal isOpen={submitModal} className="confirm-modal-container" overlayClassName="confirm-modal-overlay">
        <IconButton icon="times" className="confirm-modal-close-btn" onClick={() => closeModal()} />
        <div className="ncss-col-sm-12 confirm-modal-height">
          <H5 className="confirm-modal-text">{message}</H5>
        </div>
        {buttonsDisplay ? (
          <div className="ncss-col-sm-12 confirm-display">
            <div className="bottom-section p4-top-sm popup-bottom">
              <Button className="ncss-btn-black u-bold no-button button-width-adjust" onClick={() => closeModal()}>
                CANCEL
              </Button>
            </div>
            <div className="bottom-section p4-top-sm popup-bottom">
              <Button
                className="ncss-btn-black u-bold no-button button-width-adjust"
                onClick={() => {
                  submitValue();
                  closeModal('submit_close');
                }}
              >
                OK
              </Button>
            </div>
          </div>
        ) : (
          <div className="ncss-col-sm-12 ">
            <div className="bottom-section p4-top-sm popup-bottom">
              <Button className="ncss-btn-black u-bold no-button button-width-adjust" onClick={() => closeModal()}>
                OK
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
ConfirmationBox.propTypes = {
  submitModal: PropTypes.bool,
  submitValue: PropTypes.func,
  closeModal: PropTypes.func,
  message: PropTypes.string,
  buttonsDisplay: PropTypes.bool,
  hideCancel: PropTypes.bool
};
ConfirmationBox.defaultProps = {
  buttonsDisplay: true,
  hideCancel: true
};
export default ConfirmationBox;
