import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { deleteRequest, displayMessage } from '../util/index.js';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { resetFaq } from '../actions/faqAction';

function* listener(serviceType, action) {
  try {
    const requestData = action.data;
    let url = '';
    if (requestData.contentid) {
      url = apiConfig.faqDeleteQstn.endPointUrl.replace('contentid', requestData.contentid);
    } else {
      url = apiConfig.faqDeleteCat.endPointUrl;
    }
    url = url.replace('topicid', requestData.topicid);
    const infoPopDuration = 1000;
    if (!requestData.hideMessage) {
      yield call(displayMessage, 'info', 'Deleting ...', infoPopDuration);
    }
    if (requestData.lastQuest) {
      yield call(displayMessage, 'info', 'Deleted Last question from Category ... Categry also Deleted', 1200);
    }
    const res = yield call(deleteRequest, url, true),
      statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json(),
        serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response.data));
        if (!requestData.contentid) yield put(resetFaq());
        yield put(createServiceAction('getFAQData', 'requested')());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* deleteFaq() {
  const serviceType = 'deleteFaq';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}
export default deleteFaq;
