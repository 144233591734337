import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};


const formatGroupLabel = data => (
  <div style={groupStyles} className='group-styles'>
    <span>{data.label}</span>
    <span className='group-badge-styles'>{data.options.length}</span>
  </div>
);

export const renderMultiSelect = ({ input, label, value, options, placeholder, type, className }) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: '999'

    }),
    control: (provided) => ({
      ...provided,
      marginTop: "5%",
    })
  }

  return (
    <Select
      className="basic-multi-select"
      classNamePrefix="Select"
      isMulti={true}
      options={options}
      defaultOptions
      placeholder={placeholder}
      name={label}
      styles={customStyles}
      value={input.value}
      formatGroupLabel={formatGroupLabel}
      onChange={value => input.onChange(value)}
    />
  );
};

renderMultiSelect.propTypes = {
  input: PropTypes.object,
  minDate: PropTypes.bool
};

export default renderMultiSelect;
