import { createServiceAction } from '../util/actionHelper.js';

const defaultState = {
  filterRange: [],
  getCurrentCount: 0,
  nextOffset: 0,
  limit: 50,
  searchInfo: []
};

export default function search(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('searchUniversalData', 'loading').type:
      return {
        ...state,
        isLoading: true,
        noData: false,
        getprogress: 0,
        getCurrentCount: 1,
        nextOffset: 0,
        remainingCount: 0,
        searchInfo: []
      };
    case createServiceAction('searchFilterRange', 'success').type:
      const availableSizeRange = { domain: [action.data.availableSizes.min, action.data.availableSizes.max], unit: '' };
      const soleHeightRange = { domain: [action.data.soleHeight.min, action.data.soleHeight.max], unit: 'mm' };
      const landPrice = { domain: [action.data.landPrice.min, action.data.landPrice.max], unit: '$' };
      const launchProductSampleSizes = {
        domain: [action.data.launchProductSampleSizes.min, action.data.launchProductSampleSizes.max],
        unit: ''
      };
      return { ...state, filterRange: { availableSizeRange, soleHeightRange, landPrice, launchProductSampleSizes } };

    case createServiceAction('searchUniversalData', 'nodata').type:
      return { ...state, noData: true, isLoading: false };

    case createServiceAction('searchUniversalData', 'success').type:
      let currentCountValue = 1;
      let totalCountInfo = 0;
      if (action.data.totalHits > state.limit) {
        currentCountValue = state.getCurrentCount + 1;
        totalCountInfo = Math.ceil(parseInt(action.data.totalHits, 10) / state.limit);
      }
      // let currentCountValue = state.getCurrentCount + 1;
      // const totalCount = Math.ceil(parseInt(action.data.totalHits, 10) / state.limit);
      let nextOffsetValue = state.limit;
      // let remainingCountValue = parseInt(totalCount - currentCountValue, 10);
      // if (totalCountInfo > 1 ) {
      //   state.getprogress = ((currentCountValue - 1).toFixed(1) / totalCountInfo) * 100;
      // }

      return {
        ...state,
        searchInfo: action.data.assets,
        getCurrentCount: currentCountValue,
        remainingCount: totalCountInfo,
        nextOffset: nextOffsetValue,
        openConfirmation: false,
        getprogress: state.getprogress,
        totalHits: totalCountInfo,
        limit: state.limit,
        isLoading: false
      };

    case createServiceAction('searchUniversalDataLazyLoad', 'success').type:
      let newData = action.data.assets;
      let temp = [...state.searchInfo];

      let InitialData = [];

      InitialData = [...temp, ...newData];

      let currentCountVal = state.getCurrentCount + 1;
      let totalCountVal = Math.ceil(parseInt(action.data.totalHits, 10) / state.limit);
      let nextOffsetVal = state.nextOffset + state.limit;
      if (currentCountVal > totalCountVal) {
        currentCountVal = 1;
        totalCountVal = 0;
      }
      return {
        ...state,
        searchInfo: InitialData,
        getCurrentCount: currentCountVal,
        remainingCount:
          Math.ceil(parseInt(action.data.totalHits, 10) / state.limit) - parseInt(state.getCurrentCount, 10),
        nextOffset: nextOffsetVal,
        openConfirmation: false,
        isLoading: false,
        limit: state.limit,
        getprogress: state.getprogress,
        totalHits: totalCountVal
      };
    default:
      return state;
  }
}

export const remainingCount = state => state.search.remainingCount;
export const nextOffset = state => state.search.nextOffset;
export const limit = state => state.search.limit;
export const getCurrentCount = state => state.search.getCurrentCount;
export const totalCount = state => state.search.totalHits;
