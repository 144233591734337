import React from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

Moment.locale('en');
momentLocalizer();

const renderDatePicker = ({ input: { onChange, value }, showTime, defaultValue, minDate, disabled, max }) => (
  <DateTimePicker
    onChange={onChange}
    format="DD MMM YYYY"
    {...(minDate && { min: minDate })} // Disables all dates less than the given Min date
    {...(max && { max: new Date() })} // Disables all dates greater than the given Max date
    inputProps={{ readOnly: true }}
    time={showTime}
    disabled={disabled}
    value={defaultValue || null}
  />
);

renderDatePicker.propTypes = {
  handleChange: PropTypes.func,
  minDate: PropTypes.object
};

export default renderDatePicker;
