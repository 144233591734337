import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

class ImageEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      allowZoomOut: true,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      width: 300,
      height: 300,
      rotate: 0,
      imageUrl: null,
      editor: null,
      fileName: 'No File Chosen',
      imageType: null,
      testing: false,
      isEdited: false
    };
    this.onLoadSuccess = this.onLoadSuccess.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
  }
  componentDidMount() {
    const { initialImgName, initialImg, isAdd } = this.props;

    if (initialImgName && initialImg && !isAdd) {
      this.setState({ image: initialImg, fileName: initialImgName, testing: true });
    }
  }
  componentWillUpdate(nextProps, nextState) {
    const { image } = this.state;
    const { initialImgName } = this.props;

    if (image !== nextState.image && !nextState.image) {
      this.setState({ image: image });
    }
    if (initialImgName !== nextProps.initialImgName && nextProps.initialImgName && nextProps.initialImg) {
      this.setState({ image: nextProps.initialImg, fileName: nextProps.initialImgName });
    }

    // if(initialImg !== nextProps.initialImg && nextProps.initialImg){

    //   this.setState({ image: nextProps.initialImg, fileName: nextProps.initialImgName });
    // }
  }

  handleDrop = dropped => {
    if (dropped && dropped[0]) {
      this.setState({ image: dropped[0], fileName: dropped[0].name });
    }
  };

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale, isEdited: true });
  };
  rotateLeft = e => {
    this.setState(prevState => ({
      rotate: prevState.rotate - 90
    }));
    this.setState({ testing: true, isEdited: true });
  };
  rotateRight = e => {
    this.setState(prevState => ({
      rotate: prevState.rotate + 90
    }));
    this.setState({ testing: true, isEdited: true });
  };
  handlePositionChange = position => {
    this.setState({ position, isEdited: true });
  };

  onImageChange = event => {
    const { editor, imageType, isEdited } = this.state;
    const { editedImage, initialImgName, imageNumber, savedImageType, imgId } = this.props;

    if (editor) {
      let ctx = editor.getImageScaledToCanvas();
      let context = ctx.getContext('2d');
      let canvas = context.canvas;

      let data;
      data = context.getImageData(0, 0, canvas.width, canvas.height);

      //store the current globalCompositeOperation
      let compositeOperation = context.globalCompositeOperation;

      //set to draw behind current content
      context.globalCompositeOperation = 'destination-over';

      //set background color
      context.fillStyle = '#ffffff';

      //draw background / rect on entire canvas
      context.fillRect(0, 0, canvas.width, canvas.height);

      //get the image data from the canvas
      let imageData = canvas.toDataURL('image/jpeg');

      //clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height);

      //restore it with original / cached ImageData
      context.putImageData(data, 0, 0);

      //reset the globalCompositeOperation to what it was
      context.globalCompositeOperation = compositeOperation;

      let type = savedImageType ? savedImageType : imageType;
      let isEditedFlag = isEdited;
      this.setState({ imageUrl: imageData, isEdited: false });

      if (editor.props.image && editor.props.image.name) {
        editedImage(
          {
            base64: imageData.split(',')[1],
            fileName: Number.isInteger(imageNumber) ? imageNumber + editor.props.image.name : editor.props.image.name,
            imageType: Number.isInteger(imageNumber) ? type : 'Primary',
            isEditedFlag
          },
          {
            base64: imageData,
            fileName: Number.isInteger(imageNumber) ? imageNumber + editor.props.image.name : editor.props.image.name,
            imageType: Number.isInteger(imageNumber) ? type : 'Primary',
            isEditedFlag
          },
          imageNumber
        );
      } else if (editor.props.image && !editor.props.image.name && initialImgName) {
        editedImage(
          {
            base64: imageData.split(',')[1],
            fileName: initialImgName,
            imageType: Number.isInteger(imageNumber) ? type : 'Primary',
            id: imgId || null,
            isEdited: isEditedFlag
          },
          {
            base64: imageData,
            fileName: initialImgName,
            imageType: Number.isInteger(imageNumber) ? type : 'Primary',
            id: imgId || null,
            isEdited: isEditedFlag
          },
          imageNumber
        );
      } else if (editor.props.image === null) {
        this.setState({ imageUrl: null });
      }
    }
  };
  onLoadSuccess = () => {
    const { editor, imageType } = this.state;
    const { editedImage, initialImgName, imageNumber, savedImageType, imgId } = this.props;
    if (editor) {
      let ctx = editor.getImageScaledToCanvas();

      let context = ctx.getContext('2d');
      let canvas = context.canvas;

      let data;
      data = context.getImageData(0, 0, canvas.width, canvas.height);

      let compositeOperation = context.globalCompositeOperation;

      context.globalCompositeOperation = 'destination-over';

      context.fillStyle = '#ffffff';

      context.fillRect(0, 0, canvas.width, canvas.height);

      let imageData = canvas.toDataURL('image/jpeg');

      context.clearRect(0, 0, canvas.width, canvas.height);

      context.putImageData(data, 0, 0);

      context.globalCompositeOperation = compositeOperation;

      this.setState({ imageUrl: imageData, testing: false });

      let type = savedImageType ? savedImageType : imageType;

      editedImage(
        {
          base64: imageData.split(',')[1],
          fileName:
            (Number.isInteger(imageNumber) && editor.props.image.name
              ? imageNumber + editor.props.image.name
              : editor.props.image.name) || initialImgName,
          imageType: Number.isInteger(imageNumber) ? type : 'Primary',
          id: imgId || null
        },
        {
          base64: imageData,
          fileName:
            (Number.isInteger(imageNumber) && editor.props.image.name
              ? imageNumber + editor.props.image.name
              : editor.props.image.name) || initialImgName,
          imageType: Number.isInteger(imageNumber) ? type : 'Primary',
          id: imgId || null
        },
        imageNumber
      );
    }
  };
  handleNewImage = e => {
    this.setState({ image: e.target.files[0], fileName: e.target.files[0].name });
  };
  imageTypeChange = event => {
    const { imageTypeChanged, imageNumber } = this.props;
    this.setState({ imageType: event.target.value });
    imageTypeChanged(event.target.value, imageNumber);
  };

  setEditorRef = editor => this.setState({ editor });

  render() {
    const { scale, image, position, rotate, imageUrl, fileName, width, height } = this.state;
    const { setCrop, setRotate, initialImg, initialImgName, imageNumber, savedImageType } = this.props;

    return (
      <div className="ncss-col-sm-12 editor-complete-area">
        <div className="image-editor ncss-col-sm-6">
          <Dropzone onDrop={this.handleDrop} noClick noKeyboard accept="image/png, image/jpeg">
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="ncss-col-sm-12">
                <div className="ncss-col-sm-12">
                  {(image && setCrop) || (initialImg && setCrop) ? (
                    <div className="ncss-col-sm-5 editor-options">
                      <span className="option-head">Zoom</span>
                      <br />
                      <input
                        name="scale"
                        type="range"
                        onChange={e => {
                          this.handleScale(e);
                        }}
                        min={this.state.allowZoomOut ? '0.1' : '1'}
                        max="2"
                        step="0.01"
                        defaultValue="1"
                        className="editor-slider"
                      />
                    </div>
                  ) : null}
                  {(image && setRotate) || (initialImg && setRotate) ? (
                    <div className="ncss-col-sm-5 editor-options ml4-sm">
                      <span className="option-head">Rotate</span>
                      <br />
                      <div className="ncss-col-sm-5 pl0-sm ">
                        <button onClick={this.rotateLeft} className="rotate-left-button">
                          <i class="fas fa-undo"></i>
                        </button>
                      </div>
                      <div className="ncss-col-sm-5 pl0-sm ">
                        <button onClick={this.rotateRight} className="rotate-right-button">
                          <i class="fas fa-redo" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                {image || initialImg ? (
                  <span>
                    <hr className="editor-divider" />
                    <br />
                  </span>
                ) : null}

                <div className="align-center">
                  <AvatarEditor
                    ref={this.setEditorRef}
                    className="editor-area"
                    scale={parseFloat(scale)}
                    width={width}
                    height={height}
                    border={0}
                    borderRadius={0}
                    image={image}
                    position={position}
                    rotate={rotate}
                    onPositionChange={this.handlePositionChange}
                    onImageChange={this.onImageChange}
                    onLoadSuccess={this.onLoadSuccess}
                  />
                </div>
              </div>
            )}
          </Dropzone>
          <div className="editor-upload-area ncss-col-sm-6">
            <div className="editor-upload-button">
              <input
                name="newImage"
                type="file"
                value=""
                title=""
                onChange={this.handleNewImage}
                label="label"
                accept="image/x-png,image/jpeg"
              />
            </div>
            <div className="editor-upload-file-name">{initialImg ? initialImgName : fileName}</div>
          </div>
          {imageUrl ? (
            <div className="ncss-col-sm-6 editor-dropdown-area">
              <label for="type" className="image-type-label">
                Image Type:
              </label>
              <select
                name="types"
                id="types"
                className="image-type-dropdown editor-dropdown"
                disabled={Number.isInteger(imageNumber) ? false : true}
                onChange={e => {
                  this.imageTypeChange(e);
                }}
              >
                {!Number.isInteger(imageNumber) ? (
                  <option value="Primary">Primary</option>
                ) : (
                  <option value="">--select--</option>
                )}
                <option value="Side View" selected={savedImageType === 'Side View'}>
                  Side View
                </option>
                <option value="Others" selected={savedImageType === 'Others'}>
                  Others
                </option>
              </select>
            </div>
          ) : null}
        </div>
        {imageUrl ? (
          <div className=" ncss-col-sm-6 pl0-sm pr0-sm align-center editor-preview">
            <div className="editor-preview-heading"> PREVIEW </div>
            <div>
              <img
                src={imageUrl}
                color={[255, 255, 255, 0.6]}
                alt="Background"
                style={{ backgroundColor: 'red' }}
                className="editor-preview-area"
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

ImageEditor.propTypes = {
  label: PropTypes.string
};
ImageEditor.defaultProps = {
  setCrop: false,
  setRotate: false
};

export default ImageEditor;
