/* eslint-disable */
import React, { Component, lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import '../stylus/app.styl';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import config from '../config.js';
import Header from '../views/header/header.js';
const SlideMenuContainer = lazy(() => import('../views/navigation/SlideMenuContainer.js'));
import { navMenuclose } from '../actions/navigationActions.js';
import OfflineBanner from '../views/offline/offline.js';
// import { Spinner, GenericError } from '../components/index.js';
// import { authCheckRequested } from '../actions/authActions.js';
import { LoginRequired } from '../views/auth/requireLogin.js';
import { hasAuthCheckFinished, userLoggedIn, selectLoginError, isUser } from '../reducers/authReducer.js';
import NotificationsMessage from '../components/notifications/NotificationsMessage';
import Footer from '../views/footer/footer';

class App extends Component {
  componentDidMount() {
    // const { authCheckRequested } = this.props;
    // authCheckRequested();
  }
  onNavClose = () => {
    const { navMenuclose } = this.props;
    document.body.classList.remove('hide-scroll');

    navMenuclose();
  };

  render() {
    const { navMenuclose, children, location, isUser, navRoutes } = this.props;
    const navLinks = navRoutes.map(r => ({
      to: r.path,
      label: r.title,
      icon: r.icon,
      heading: r.heading,
      admin: r.admin ? r.admin : false,
      id: r.id,
      url: r.url || ''
    }));
    return (
      <DocumentTitle title={config.appName}>
        <div className="u-full-height">
          <OfflineBanner />
          <Header isErrorRoute={location.pathname && location.pathname.indexOf('/error') === -1 ? false : true} />
          <NotificationsMessage />
          <Suspense fallback={<div>Loading...</div>}>
          <SlideMenuContainer
            title={config.appName}
            description={config.appFullName}
            navLinks={isUser ? navLinks.filter(r => !r.admin) : navLinks}
            onClose={this.onNavClose}
          >
            <div className="app-container">
              <LoginRequired>{children}</LoginRequired>
            </div>
          </SlideMenuContainer>
          </Suspense>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

App.propTypes = {
  authCheckRequested: PropTypes.func,
  navMenuclose: PropTypes.func,
  children: PropTypes.object,
  loginFinished: PropTypes.bool,
  loginError: PropTypes.bool
};

export { App };
export default withRouter(
  connect(
    state => ({
      // loginError: selectLoginError(state),
      // loginFinished: hasAuthCheckFinished(state),
      // userLoggedIn: userLoggedIn(state),
      isUser: isUser(state),
      cartData: state.airBag.cartData
    }),
    { navMenuclose }
  )(App)
);
