import { createServiceAction } from '../util/actionHelper.js';
import { selectFullName, selectEmail } from './authReducer.js';

const defaultState = {
  airbagDetails: null,
  enableNextBtn: false,
  index: 0,
  nxtpageIndex: 0,
  openConfirmation: false
};

const defaultName = state => selectFullName(state);
const defaultEmail = state => selectEmail(state);

let model = {
  name: defaultName,
  requesting: 'Approved Innovation Roadmap Sample / Consumer Code Prior to W33',
  email: defaultEmail,
  phone: null,
  dateToday: new Date(),
  needByDate: null,
  airmiContact: '',
  projectName: '',
  productCode: '',
  shoeSize: '',
  reasonforreq: null,
  pairs: '',
  additionalDetails: '',
  chk1: false,
  chk2: false,
  chk3: false,
  shippingDestination: null,
  attention: defaultName,
  department: null,
  building: null,
  contactName: defaultName,
  contactEmail: defaultEmail,
  recipientName: defaultName,
  recipientAddress: null,
  recipientPhone: null,
  whatAreYouRequesting: null,
  title: '',
  partSize: '',
  typeOfRequest: '',
  pressure: null,
  fabricHeight: null,
  innovationContact: null,
  performanceRequirement: null,
  modelName: null,
  launchSeason: null,
  categoryGroup: null,
  firstSeasonVolume: null,
  firstYearForecastByQuarter: null
};

function validateFields(index, nxtpageIndex) {
  const requestFormArray = [
    ['name', 'email', 'phone', 'dateToday'], //'requesting'
    ['airmiContact', 'projectName', 'productCode', 'shoeSize', 'reasonForRequest', 'pairs', 'additionalDetails'], // <RequestFormDetails />,
    ['chk1', 'chk2', 'chk3'], // <PolicyDisclaimer />,
    [
      ['shippingDestination'],
      ['attention', 'department', 'building'],
      ['recipientName', 'recipientAddress', 'recipientPhone'],
      ['contactName', 'contactEmail']
    ] // <ShippingInfo nextPage={nextPage} onClickNext={this.onClickNext.bind(this)} />
  ];

  let isValidate = true;

  index < 3 &&
    requestFormArray[index].forEach(function(key) {
      if (model[key] === null || model[key] === '' || ((key === 'chk1' || 'chk2' || 'chk3') && model[key] === false)) {
        isValidate = false;
      }
    });

  const subIndex = model.shippingDestination && model.shippingDestination === 'Inter Office(WHQ Only)' ? 1 : 2;

  index === 3 &&
    nxtpageIndex === 0 &&
    requestFormArray[index][0] &&
    requestFormArray[index][0].forEach(function(key) {
      if (model[key] === null || model[key] === '' || ((key === 'chk1' || 'chk2' || 'chk3') && model[key] === false)) {
        isValidate = false;
      }
    });

  index === 3 &&
    nxtpageIndex === 1 &&
    requestFormArray[index][subIndex] &&
    requestFormArray[index][subIndex].forEach(function(key) {
      if (
        model[key] === null ||
        model[key] === '' ||
        model[key].length === 0 ||
        ((key === 'chk1' || 'chk2' || 'chk3') && model[key] === false)
      ) {
        isValidate = false;
      }
    });

  index === 3 &&
    nxtpageIndex === 2 &&
    requestFormArray[index][3] &&
    requestFormArray[index][3].forEach(function(key) {
      if (
        model[key] === null ||
        model[key] === '' ||
        model[key].length === 0 ||
        ((key === 'chk1' || 'chk2' || 'chk3') && model[key] === false)
      ) {
        isValidate = false;
      }
    });
  return isValidate;
}

function validateFieldsForScan(index) {
  const requestFormArray = [
    ['reasonforreq', 'name', 'email', 'phone', 'dateToday'], //'requesting'
    ['title', 'whatAreYouRequesting', 'partSize'], // <RequestFormDetails />,
    ['contactName', 'contactEmail']
  ];

  let isValidate = true;

  index <= 3 &&
    requestFormArray[index].forEach(function(key) {
      if (model[key] === null || model[key] === '') {
        isValidate = false;
      }
    });

  return isValidate;
}

function validateFieldsForNewIdea(index, nxtpageIndex) {
  const requestFormArray = [['reasonforreq', 'name', 'email', 'phone', 'dateToday'], ['typeOfRequest']];

  let isValidate = true;

  index <= 1 &&
    requestFormArray[index].forEach(function(key) {
      if (model[key] === null || model[key] === '') {
        isValidate = false;
      }
    });
  if (index > 1) {
    if (model.typeOfRequest === 'Customized Airsole Sample') {
      requestFormArray.splice(2);
      requestFormArray.push(
        ['title', 'whatAreYouRequesting', 'pressure', 'fabricHeight'],
        [
          ['shippingDestination'],
          ['attention', 'department', 'building'],
          ['recipientName', 'recipientAddress', 'recipientPhone'],
          ['contactName', 'contactEmail']
        ]
      );

      index < 3 &&
        requestFormArray[index].forEach(function(key) {
          if (model[key] === null || model[key] === '') {
            isValidate = false;
          }
        });

      const subIndex = model.shippingDestination && model.shippingDestination === 'Inter Office(WHQ Only)' ? 1 : 2;

      index === 3 &&
        nxtpageIndex === 0 &&
        requestFormArray[index][0] &&
        requestFormArray[index][0].forEach(function(key) {
          if (model[key] === null || model[key] === '') {
            isValidate = false;
          }
        });

      index === 3 &&
        nxtpageIndex === 1 &&
        requestFormArray[index][subIndex] &&
        requestFormArray[index][subIndex].forEach(function(key) {
          if (model[key] === null || model[key] === '' || model[key].length === 0) {
            isValidate = false;
          }
        });

      index === 3 &&
        nxtpageIndex === 2 &&
        requestFormArray[index][3] &&
        requestFormArray[index][3].forEach(function(key) {
          if (model[key] === null || model[key] === '' || model[key].length === 0) {
            isValidate = false;
          }
        });
    }
    if (model.typeOfRequest === 'New Innovation Project') {
      requestFormArray.splice(2);
      requestFormArray.push(
        [
          'title',
          'innovationContact',
          'performanceRequirement',
          'additionalDetails',
          'modelName',
          'categoryGroup',
          'launchSeason',
          'firstYearForecastByQuarter',
          'firstSeasonVolume'
        ],
        ['contactName', 'contactEmail']
      );
      requestFormArray[index].forEach(function(key) {
        if (model[key] === null || model[key] === '') {
          isValidate = false;
        }
      });
    }
  }

  return isValidate;
}

function requestOnChangeFun(action, index, nxtpageIndex) {
  switch (action.meta.field) {
    case 'name':
      model.name = action.payload;
      break;
    case 'phone':
      model.phone = action.payload;
      break;
    case 'email':
      model.email = action.payload;
      break;
    case 'needByDate':
      model.needByDate = action.payload;
      break;
    case 'dateToday':
      model.dateToday = action.payload;
      break;
    case 'reasonforreq':
      model.reasonforreq = action.payload;
      break;
    case 'airmiContact':
      model.airmiContact = action.payload;
      break;
    case 'projectName':
      model.projectName = action.payload;
      break;
    case 'productCode':
      model.productCode = action.payload;
      break;
    case 'shoeSize':
      model.shoeSize = action.payload;
      break;
    case 'pairs':
      model.pairs = action.payload;
      break;
    case 'additionalDetails':
      model.additionalDetails = action.payload;
      break;
    case 'chk1':
      model.chk1 = action.payload;
      break;
    case 'chk2':
      model.chk2 = action.payload;
      break;
    case 'chk3':
      model.chk3 = action.payload;
      break;
    case 'recipientAddress':
      model.recipientAddress = action.payload;
      break;
    case 'recipientName':
      model.recipientName = action.payload;
      break;
    case 'recipientPhone':
      model.recipientPhone = action.payload;
      break;
    case 'contactName':
      model.contactName = action.payload;
      break;
    case 'contactEmail':
      model.contactEmail = action.payload;
      break;
    case 'building':
      model.building = action.payload;
      break;
    case 'department':
      model.department = action.payload;
      break;
    case 'attention':
      model.attention = action.payload;
      break;
    case 'shippingDestination':
      model.shippingDestination = action.payload;
      break;
    case 'title':
      model.title = action.payload;
      break;
    case 'whatAreYouRequesting':
      model.whatAreYouRequesting = action.payload;
      break;
    case 'partSize':
      model.partSize = action.payload;
      break;
    case 'typeOfRequest':
      model.typeOfRequest = action.payload;
      break;
    case 'pressure':
      model.pressure = action.payload;
      break;
    case 'fabricHeight':
      model.fabricHeight = action.payload;
      break;
    case 'innovationContact':
      model.innovationContact = action.payload;
      break;
    case 'performanceRequirement':
      model.performanceRequirement = action.payload;
      break;
    case 'modelName':
      model.modelName = action.payload;
      break;
    case 'firstSeasonVolume':
      model.firstSeasonVolume = action.payload;
      break;
    case 'firstYearForecastByQuarter':
      model.firstYearForecastByQuarter = action.payload;
      break;
    case 'categoryGroup':
      model.categoryGroup = action.payload;
      break;
    case 'launchSeason':
      model.launchSeason = action.payload;
      break;
    default:
      break;
  }
  if (model.reasonforreq === 'Approved Innovation Roadmap Sample / Consumer Code Prior to W33') {
    return validateFields(index, nxtpageIndex);
  } else if (model.reasonforreq === '3D Scan File / Airsole Spec') {
    return validateFieldsForScan(index);
  } else if (model.reasonforreq === 'New Concept or Idea') {
    return validateFieldsForNewIdea(index, nxtpageIndex);
  }
}

function resetModel() {
  model = {
    name: defaultName,
    requesting: 'Approved Innovation Roadmap Sample / Consumer Code Prior to W33',
    email: defaultEmail,
    phone: null,
    dateToday: new Date(),
    needByDate: null,
    airmiContact: '',
    projectName: '',
    productCode: '',
    shoeSize: '',
    reasonforreq: null,
    pairs: null,
    additionalDetails: null,
    chk1: false,
    chk2: false,
    chk3: false,
    shippingDestination: null,
    attention: defaultName,
    department: null,
    building: null,
    contactName: defaultName,
    contactEmail: defaultEmail,
    recipientName: defaultName,
    recipientAddress: null,
    recipientPhone: null,
    whatAreYouRequesting: null,
    title: '',
    partSize: '',
    typeOfRequest: '',
    pressure: null,
    fabricHeight: null,
    innovationContact: null,
    performanceRequirement: null,
    modelName: null,
    launchSeason: null,
    categoryGroup: null,
    firstSeasonVolume: null,
    firstYearForecastByQuarter: null
  };
}

export default function airmiApprovedInnovation(state = defaultState, action) {
  switch (action && action.type) {
    case createServiceAction('submitAirsoleRequest', 'submitted').type:
      return { ...state, isLoading: true, openConfirmation: false };
    case createServiceAction('submitAirsoleRequest', 'success').type:
      return { ...state, openConfirmation: true, isLoading: false };
    case createServiceAction('closeConfirmationModals', 'submitted').type:
      // action.data.goBack();
      return { ...state, openConfirmation: false };
    case '@@redux-form/CHANGE':
      const isValid =
        action.meta.form === 'airsoleRequestForm'
          ? requestOnChangeFun(action, state.index, state.nxtpageIndex)
          : state.enableNextBtn;
      if (isValid) {
        return { ...state, enableNextBtn: true };
      }
      return { ...state, enableNextBtn: false };
    case createServiceAction('indexDecrement', 'indexDecrement').type:
      let isValidNxt = false;
      if (model.reasonforreq === 'Approved Innovation Roadmap Sample / Consumer Code Prior to W33') {
        isValidNxt = validateFields(state.index - 1, 0);
      } else if (model.reasonforreq === '3D Scan File / Airsole Spec') {
        isValidNxt = validateFieldsForScan(state.index - 1);
      } else if (model.reasonforreq === 'New Concept or Idea') {
        isValidNxt = validateFieldsForNewIdea(state.index - 1, 0);
      }
      return { ...state, index: state.index - 1, enableNextBtn: isValidNxt, nxtpageIndex: 0 };
    case createServiceAction('indexIncrement', 'indexIncrement').type:
      let isValidNextBtn = false;
      if (model.reasonforreq === 'Approved Innovation Roadmap Sample / Consumer Code Prior to W33') {
        isValidNextBtn = validateFields(state.index + 1, 0);
      } else if (model.reasonforreq === '3D Scan File / Airsole Spec') {
        isValidNextBtn = validateFieldsForScan(state.index + 1);
      } else if (model.reasonforreq === 'New Concept or Idea') {
        isValidNextBtn = validateFieldsForNewIdea(state.index + 1, 0);
      }
      return { ...state, index: state.index + 1, enableNextBtn: isValidNextBtn, nxtpageIndex: 0 };
    case createServiceAction('nxtPageDecrement', 'nxtPageDecrement').type:
      let valid = false;
      if (model.reasonforreq === 'Approved Innovation Roadmap Sample / Consumer Code Prior to W33') {
        valid = validateFields(state.index, state.nxtpageIndex - 1);
      } else if (model.reasonforreq === 'New Concept or Idea') {
        valid = validateFieldsForNewIdea(state.index, state.nxtpageIndex - 1);
      }
      return { ...state, nxtpageIndex: state.nxtpageIndex - 1, enableNextBtn: valid };
    case createServiceAction('nxtPageIncrement', 'nxtPageIncrement').type:
      let validbtn = false;
      if (model.reasonforreq === 'Approved Innovation Roadmap Sample / Consumer Code Prior to W33') {
        validbtn = validateFields(state.index, state.nxtpageIndex + 1);
      } else if (model.reasonforreq === 'New Concept or Idea') {
        validbtn = validateFieldsForNewIdea(state.index, state.nxtpageIndex + 1);
      }
      return { ...state, nxtpageIndex: state.nxtpageIndex + 1, enableNextBtn: validbtn };
    case '@@redux-form/RESET':
      if (action.meta.form === 'airsoleRequestForm') {
        resetModel();
        return { ...state, index: 0, enableNextBtn: false, nxtpageIndex: 0 };
      } else {
        return { ...state };
      }
    default:
      return state;
  }
}
