export default function request(state = {}, action) {
  if (action.request) {
    return {
      ...state,
      [action.request.id]: {
        isPending: action.request.isPending,
        error: action.request.error
      }
    };
  }

  return state;
}

export const selectIsRequestPending = (state, action) =>
  (state.request[action.event.name] || {}).isPending === true;

export const selectRequestError = (state, action) => (state.request[action.event.name] || {}).error;
