// import authSagas from './authSagas.js';
import notificationSagas from './notificationSagas.js';
import getProfileSagas from './getProfileSagas';
import downloadAttachmentSagas from './downloadAttachmentSagas.js';
import getDashboardDataSagas from './getDashboardData.js';
import airBagDetailsSagas from './airBagDetails.js';
import filterRangeSagas from './filterRange.js';
import getDetailsCardSagas from './getDetailsCardData.js';
import getAdminDataSagas from './getAdminData.js';
import CreateProfileSagas from './CreateProfileSagas.js';
import addProductSubmitSagas from './addProductSubmitSagas.js';
import createCommercialReqSaga from './createCommercialReqSaga.js';
import submitAirsoleReqSaga from './submitAirsoleReqSaga.js';
import editProductSagas from './editProductSagas.js';
import massUploadSubmitSagas from './massUploadSagas.js';
import decorationOptions from './decorationOptions.js';
import dynamicOptions from './dynamicOptionsSagas';
import getEditAirbagDetails from './fetchEditAirbagDetails.js';
import fabricHeightOptions from './fabricHeightOptions';
import sheetThicknessOptions from './sheetThicknessOptions';
import getCheatSheetData from './cheatsheet.js';
import getFAQData from './faq.js';
import getFAQCategories from './faqCategories.js';
import searchAirbagDetails from './searchAirbagDetails';
import deleteFaq from './faqDelete.js';
import submitFaqEditQstn from './faqEditQstn.js';
import addFAQCategory from './faqAdd.js';
import userStatistics from './userStatistics.js';
import login from './loginSagas';
import uploadMultiImgSaga from './uploadMultiImgSaga';
import editMultiImgSaga from './editMultiImgSaga';
import faqOrderUpdateSagas from './faqOrderUpdateSagas';
import searchUniversalData from './searchUniversalData';
import getSTLData from './getSTLDetailsSaga';
import getSTLMeatData from './getSTLMetaSagas';

import { fork, all } from 'redux-saga/effects';

function* rootSaga() {
  yield all([
    // fork(authSagas),
    fork(notificationSagas),
    fork(getProfileSagas),
    fork(downloadAttachmentSagas),
    fork(getDashboardDataSagas),
    fork(airBagDetailsSagas),
    fork(getDetailsCardSagas),
    fork(getAdminDataSagas),
    fork(CreateProfileSagas),
    fork(addProductSubmitSagas),
    fork(massUploadSubmitSagas),
    fork(editProductSagas),
    fork(decorationOptions),
    fork(dynamicOptions),
    fork(getEditAirbagDetails),
    fork(fabricHeightOptions),
    fork(sheetThicknessOptions),
    fork(getCheatSheetData),
    fork(getFAQData),
    fork(getFAQCategories),
    fork(searchAirbagDetails),
    fork(deleteFaq),
    fork(submitFaqEditQstn),
    fork(addFAQCategory),
    fork(submitAirsoleReqSaga),
    fork(createCommercialReqSaga),
    fork(userStatistics),
    fork(filterRangeSagas),
    fork(login),
    fork(uploadMultiImgSaga),
    fork(editMultiImgSaga),
    fork(faqOrderUpdateSagas),
    fork(searchUniversalData),
    fork(getSTLData),
    fork(getSTLMeatData)
  ]);
}

export default rootSaga;
