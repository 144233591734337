const defaultState = {
  searchValue: ''
};

export default function autocomplete(state = defaultState, action) {
  switch (action.type) {
    case 'SET_FAQ_SEARCHVALUE':
      return { ...state, searchValue: action.payload };
    case 'FAQ_RESET':
      return { ...state, searchValue: '' };
    default:
      return state;
  }
}
