import keys from 'lodash/keys';
import pick from 'lodash/pick';
import every from 'lodash/every';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, imageputRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call, all } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    const model = {
      productCode: null,
      platform: null,
      fileName: null,
      imgData: null,
      techSpec: null,
      stlImg: null,
      launchImgs: null,
      initialInflationPressure: null,
      availableSizes: null,
      launchProductSampleSizes: null,
      launchShoes: null,
      pdmId: null,
      pcxId: null,
      airsoleHeight: null,
      bagType: null,
      handed: null,
      landedPrice: null,
      launchCategories: null,
      airbagComments: null,
      launchSeasons: null,
      status: null,
      sheetThickness: null,
      dcsMaterialId: null,
      recycledContent: null,
      decoration: null,
      newDec: null,
      newInflPress: null,
      newAirHeight: null,
      removeDec: null,
      newSheetThick: null,
      removeSheetThick: null,
      fabricHeight: null,
      newFabHeight: null,
      removeFabHeight: null,
      newLaunchCateg: null,
      newLaunchShoes: null,
      description: null,
      uom: null,
      toolingExist: null,
      archiveDate: null,
      multiImgArray: null,
      shPrice: null,
      airBagPrice: null
    };
    const result = pick(action.data, keys(model));
    const infoPopDuration = 1000;
    for (var prop in result) {
      if (result[prop] === null || result[prop] === undefined) {
        delete result[prop];
      }
    }
    let stlImgPayload = null;
    if (result.stlImg) {
      stlImgPayload = result.stlImg;
      delete result['stlImg'];
    }

    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(postRequest, apiConfig.addProduct.endPointUrl, result);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      // let showSuccessMsg = true;
      if (!serverErrorMessage && response.errorCode === 0) {
        const respAirId = response.data.airbagId;
        if (result.launchImgs) {
          const id = response.data.airbagId;
          const res = yield all(
            result.launchImgs.map(imgData => {
              const fileRequest = { ...imgData, count: result.launchImgs.length };
              return call(postRequest, apiConfig.addLaunchImg.endPointUrl + `/${id}/launchProduct`, fileRequest);
            })
          );
          const resLaunchImg = yield all(res.map(res => (res.status === 200 ? call([res, 'json']) : [])));
          if (resLaunchImg.length > 0) {
            const serverError = yield all(
              resLaunchImg.map(resVal => call(getServerValidationMessage, resVal, infoPopDuration))
            );
            if (serverError.filter(err => err !== '').length === 0 && every(resLaunchImg, { errorCode: 0 })) {
              yield put(createServiceAction(serviceType, 'success')(response.data));
            }
          }
          // yield put(createServiceAction(serviceType, 'success')(response.data));
        }
        if (result.multiImgArray && result.multiImgArray.length > 0) {
          const airbagId = response.data.airbagId;
          yield put(
            createServiceAction(
              'multiImgUpload',
              'submitted'
            )({
              airbagId: airbagId,
              multiImgArray: result.multiImgArray,
              count: result.multiImgArray.length,
              type: serviceType
            })
          );
        }
        if (stlImgPayload) {
          const airbagId = respAirId;
          const resMeta = yield call(postRequest, apiConfig.createSTLMeta.endPointUrl, {
            name: stlImgPayload.fileName,
            contentType: 'application/octet-stream',
            assetClass: '3DImage',
            attributes: {
              airbagAssetId: airbagId
            }
          });
          const response = yield resMeta.json();
          if (response.assetId) {
            const url = apiConfig.createSTLImg.endPointUrl + response.assetId;
            yield call(imageputRequest, url, stlImgPayload.data);
          }
        }
        yield put(createServiceAction(serviceType, 'success')(response.data));
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* addProductSubmitSagas() {
  const serviceType = 'addProductSubmit';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default addProductSubmitSagas;
