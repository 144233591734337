import { createServiceAction } from '../util/actionHelper.js';
const defaultState = {
  stlFile: null,
  stlAssetId: null,
  stlFileName: null,
  isLoading: false
};

export default function stlDetails(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getSTLMetaData', 'loading').type:
      return { ...state, stlAssetId: null, isLoading: true };
    case createServiceAction('getSTLMetaData', 'error').type:
      return { ...state, stlAssetId: null, stlFile: null, isLoading: false };
    case createServiceAction('getSTLMetaData', 'nodata').type:
      return { ...state, stlAssetId: null, stlFile: null, isLoading: false };
    case createServiceAction('getSTLMetaData', 'success').type:
      return {
        ...state,
        stlAssetId: action.data[0].assetId,
        stlFileName: action.data[0].name,
        stlFile: null,
        isLoading: false
      };

    case createServiceAction('getSTLData', 'loading').type:
      return { ...state, isLoading: true };
    case createServiceAction('getSTLData', 'error').type:
      return { ...state, isLoading: false };
    case createServiceAction('getSTLData', 'success').type:
      return { ...state, stlFile: action.data, isLoading: false };
    case createServiceAction('getSTLData', 'nodata').type:
      return { ...state, stlAssetId: null, stlFileName: null, isLoading: false };
    default:
      return state;
  }
}
