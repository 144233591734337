import { connect } from 'react-redux';
import React from 'react';

import * as Constants from '../../constants/footer.js';

export const Footer = () => (
  <span>
    <iframe
      className="fake-iframe fake-iframe-footer-height"
      title="fake"
      id="fake"
      src="about:blank"
      allowtransparency="true"
    ></iframe>
    <div className="footer-container bg-dark-grey footer">
      <div className="m12-sm footer-margin-adjust">
        <hr className="footer-hr" />
        <span className="text-color-white fs12-sm lh12-sm">&#x24B8; {Constants.NIKEINC}</span>
      </div>
    </div>
  </span>
);
export default connect()(Footer);
