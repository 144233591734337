import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slider, Handles, Tracks, Ticks, Rail } from 'react-compound-slider';
import { Handle, Track, Tick, SliderRail } from './slider.js';

const sliderStyle = {
  position: 'relative',
  width: '100%'
};

export class ReactSlider extends Component {
  render() {
    const { domain, values, onUpdate, onChange, unit, unitconcat, fromFilter, styleTopfix, itemclass } = this.props;
    return (
      <div
        style={fromFilter ? { margin: '10%', height: 120, width: '80%' } : { margin: '10%', width: '88%', height: 120 }}
      >
        <Slider
          domain={domain}
          rootStyle={sliderStyle}
          onUpdate={onUpdate}
          onChange={onChange}
          values={values.length > 0 ? values : domain}
          step={0.01}
        >
          <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map(handle => (
                  <Handle key={handle.id} handle={handle} domain={domain} getHandleProps={getHandleProps} />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={4}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <Tick key={tick.id} tick={tick} count={ticks.length} />
                ))}
              </div>
            )}
          </Ticks>
          {values.length ? (
            <span className={fromFilter ? 'price_range' : styleTopfix ? 'range_label_top_fix' : itemclass}>
              {' '}
              {unitconcat === 'right'
                ? values[0].toFixed(2) + unit + ' to ' + values[1].toFixed(2) + unit
                : unit === '-'
                ? parseInt(values[0]) + ' to ' + parseInt(values[1])
                : unit + values[0].toFixed(2) + ' to ' + unit + values[1].toFixed(2)}
            </span>
          ) : (
            <span className={fromFilter ? 'price_range' : styleTopfix ? 'range_label_top_fix' : itemclass}>
              {' '}
              {unitconcat === 'right' && domain && domain.length > 0
                ? domain[0].toFixed(2) + unit + ' to ' + domain[1].toFixed(2) + unit
                : unit === '-'
                ? parseInt(domain[0]) + ' to ' + parseInt(domain[1])
                : unit + domain[0].toFixed(2) + ' to ' + unit + domain[1].toFixed(2)}
            </span>
          )}
        </Slider>
        {/* {values.length ? (
          <span className={fromFilter ? 'price_range' : styleTopfix ? 'range_label_top_fix' : itemclass}>
            {' '}
            {unitconcat === 'right'
              ? values[0].toFixed(2) + unit + ' to ' + values[1].toFixed(2) + unit
              : unit === '-'
              ? parseInt(values[0]) + ' to ' + parseInt(values[1])
              : unit + values[0].toFixed(2) + ' to ' + unit + values[1].toFixed(2)}
          </span>
        ) : (
          <span className={fromFilter ? 'price_range' : styleTopfix ? 'range_label_top_fix' : itemclass}>
            {' '}
            {unitconcat === 'right' && domain && domain.length > 0
              ? domain[0].toFixed(2) + unit + ' to ' + domain[1].toFixed(2) + unit
              : unit === '-'
              ? parseInt(domain[0]) + ' to ' + parseInt(domain[1])
              : unit + domain[0].toFixed(2) + ' to ' + unit + domain[1].toFixed(2)}
          </span>
        )} */}
      </div>
    );
  }
}

export default ReactSlider;

ReactSlider.propTypes = {
  values: PropTypes.array,
  domain: PropTypes.array
};
ReactSlider.defaultProps = {
  domain: [],
  values: [],
  fromFilter: false,
  styleTopfix: false
};
