import { store } from '../index.js';
import { selectUserDetails } from '../reducers/authReducer.js';

const globalHeader = (isFormData = false) => {
  const { email, group, accessToken } = selectUserDetails(store.getState());
  // Sample Token
  //const sampltToken ='eyJraWQiOiJ0dHF4bzlJZGJYT3hRRUZMY2tQY2pCYjRJR3hEQXhLdkRvSDNCSkY5STJZIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULmpSamZjMDQ2WGc0NWRZNGp1SktUem5JYTZuQ0otd3oxT3d4b2JpNVlzbDgiLCJpc3MiOiJodHRwczovL25pa2Uub2t0YS5jb20vb2F1dGgyL2F1czI3ejdwNzZhczlEejBIMXQ3IiwiYXVkIjoiaHR0cHM6Ly9uaWtlLm9rdGEuY29tIiwiaWF0IjoxNTY0NTY5MDQ0LCJleHAiOjE1NjQ1NzI2NDQsImNpZCI6Im5pa2UubmlrZXRlY2gudG9rZW4tZ2VuZXJhdG9yIiwidWlkIjoiMDB1OGV4Y3RhOGtNTVdLSXIxdDciLCJzY3AiOlsiZW1haWwiLCJvcGVuaWQiLCJwcm9maWxlIl0sInN1YiI6Ik1hbnUuTWVldGhhbEBuaWtlLmNvbSJ9.c_-Y49401N-O2TIDtHU6Nwpyf5v8msmpmhrcAWCQuO7L4-DU7zFH2wYhaW7spanGKHJOEvJIZ-fOzKQ9q4rwuex8qONw8oQdImRx0hvaEt1GejMUOkyN7yF7a0egIbEarKeAgNkQH4LOfonHUy8CVl-BJzD2Ze1o1SDWky4YJ5Rmk5GRMBalYk59lXiQA6Tad0diHnFHkz8a-rJ_uVEKeqenDRhjMUkSUXIjnrVdYyLgSPl4nfCwJGDD85Yc4es49udSUeK9GoSwQBZGQepPkIbOLC-LUYOs8FEpExGCAzClPJzo6le88ndarcQsQQYNnwEo7Lri2XyWx3c8yKnWow'
  if (!isFormData) {
    return {
      email: email,
      user_type: group,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    };
  } else {
    return {
      email: email,
      user_type: group,
      Authorization: `Bearer ${accessToken}`
    };
  }
};

export default globalHeader;
