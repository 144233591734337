import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call, all } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    // Pass a parameter true atlast if it is a path params
    const res = yield call(getRequest, apiConfig.getCompleteAirbagDetails.endPointUrl, action.data, true);
    //Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      let res_image = [];
      const response = yield res.json();
      if (response.data) {
        const res_launch = yield all(
          response.data.launchProducts.map(id =>
            call(
              getRequest,
              apiConfig.getCompleteAirbagDetails.endPointUrl,
              action.data + `/launchProduct/${id.launchProductId}`,
              true
            )
          )
        );

        const statusError_launchProducts = yield call(getHTTPStatusValidationMessage, res_launch);
        if (!statusError_launchProducts) {
          res_image = yield all(res_launch.map(dat => dat.json()));
        }
      }
      Object.assign(response.data, { products: res_image }); //concat complete details & launchproduct
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response));
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getDetailsCardData() {
  const serviceType = 'getDetailsCardData';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default getDetailsCardData;
