import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { call, put, takeEvery } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { displayMessage } from '../util/index.js';
function* listener(serviceType, action) {
  try {
    const res = yield call(getRequest, apiConfig.faq.endPointUrl),
      statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json(),
        serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response.data));
        if (action.data.destUpdation) {
          yield put(createServiceAction('destUpdation', 'requested')());
          yield call(displayMessage, 'info', 'Deleted Last question from Category ... Categry also Deleted', 1200);
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* fetchAttachment(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(
        getRequest,
        apiConfig.faqGetAttchmentName.endPointUrl,
        `${action.data.faqId}/${action.data.contentId}`,
        true
      ),
      statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json(),
        serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        if (!isEmpty(response.data)) {
          const fName = response.data.fileNames[0];
          const res = yield call(
              getRequest,
              apiConfig.faqGetAttchmentContent.endPointUrl,
              `${action.data.faqId}/${action.data.contentId}/${fName}`,
              true
            ),
            statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
          if (!statusErrorMessage) {
            const response = yield res.json(),
              serverErrorMessage = yield call(getServerValidationMessage, response);
            if (!serverErrorMessage && response.errorCode === 0) {
              yield put(createServiceAction(serviceType, 'success')(response.data));
            }
          }
        } else yield put(createServiceAction(serviceType, 'nodata')());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getFAQData() {
  const serviceType = 'getFAQData';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  yield takeEvery(createServiceAction('getFAQAttachment', 'requested').type, fetchAttachment, 'getFAQAttachment');
}
export default getFAQData;
