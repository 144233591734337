import React from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import { getFormValues, Field, reduxForm } from 'redux-form';

import userStatGridConfig from '../../configs/gridConfigs/userStatistics/userStatGridConfig.js';
import { Spinner, Grid, H5, NotAuthorized, MonthYearPicker } from '../../components/index.js';
import ConfirmationBox from '../../components/confirmationBox/ConfirmationBox.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { displayMessage } from '../../util/index.js';
import { isUser } from '../../reducers/authReducer.js';
import moment from 'moment';

class UserStatistics extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      paramsForExcel: {},
      submitModal: false,
      type: ''
    };
  }

  componentWillMount() {
    const { requestAdminData } = this.props;
    var date = new Date();
    var formattedDate = moment(date).format('MMM YYYY');
    // console.log(formattedDate);
    requestAdminData(formattedDate);
  }

  openConfirmationPopup = ArbgStatus => {
    this.setState({ submitModal: true, type: ArbgStatus });
  };

  exportExcelForAll = () => {
    const { getExcelData } = this.props;
    displayMessage('info', 'Downloading ...', 3000);
    getExcelData();
  };

  exportUserInfo = (params, ArbgStatus) => {
    displayMessage('info', 'Downloading ...', 3000);
    const date = new Date().getDate();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const currDate = `${month}-${date}-${year} `;
    if (ArbgStatus === 'Active') {
      params.fileName = `Statistics_${currDate}`;
    }
    params.processCellCallback = params => {
      params.column.colDef.cellClass = 'aliment-left';
      if (params.column.getColId() === 'newuser') {
        const isNewUser = params.value ? 'Yes' : 'No';
        return isNewUser;
      }
      if (params.column.getColId() === 'userName') {
        const name = params.value || params.node.data.name;
        return name;
      }
      if (params.column.getColId() === 'date') {
        const name = params.node.data.eventDate || params.node.data.createdOn;
        return name;
      }
      return params.value;
    };
    params.api.exportDataAsExcel(params);
  };

  setParams = params => {
    const { paramsForExcel } = this.state;
    if (paramsForExcel) {
      this.setState({ paramsForExcel: params });
    }
  };

  closeSubmitModal = () => {
    this.setState({ submitModal: false });
  };

  render() {
    const {
      userStatsData,
      noData,
      isLoading,
      isUser,
      formDateValue,
      requestAdminData,
      totNewUsers,
      activeUserCount
    } = this.props;
    const { paramsForExcel, submitModal, type } = this.state;

    if (isUser) return <NotAuthorized />;
    return (
      <div
        className={`p0-top-sm m6-sm border-light-grey p4-sm mb8-sm bg-white ${
          noData && !isLoading ? 'extra-margin' : ''
        }`}
      >
        <div className="ncss-col-sm-2 pb4-sm pr4-sm">
          <H5 className="u-bold description m1-sm p3-top-sm  pb2-sm pr3-sm">
            Month<span className="text-color-red"></span>:
          </H5>
          <span className="main-datepicker">
            <div className="main-calender">
              <Field
                component={MonthYearPicker}
                showTime={false}
                name="month"
                defaultValue={formDateValue && formDateValue.month ? formDateValue.month : new Date()}
                onChange={(e, value) => {
                  const dateForSearch = moment(value).format('MMM YYYY');
                  requestAdminData(dateForSearch);
                }}
                maxDate={new Date()}
              />
            </div>
          </span>
        </div>
        {activeUserCount >= 0 && (
          <div className="inline-flex-align pb4-sm pl4-sm">
            <H5 className="u-bold description m1-sm p3-top-sm  pb2-sm pr3-sm ">
              Active Users: {`${activeUserCount}`}
              <span className="text-color-red"></span>
            </H5>
          </div>
        )}
        {totNewUsers >= 0 && (
          <div className="ncss-col-sm-2 pb4-sm pr4-sm">
            <H5 className="u-bold description m1-sm p3-top-sm  pb2-sm pr3-sm">
              New Users: {`${totNewUsers}`}
              <span className="text-color-red"></span>
            </H5>
          </div>
        )}
        <p
          className="link-text text-excel-color-grid float-right gap"
          onClick={() => this.openConfirmationPopup('All')}
        >
          <i className="fa fas fa-download"></i> Export All
        </p>
        {userStatsData && userStatsData.length > 0 ? (
          <p
            className="link-text text-excel-color-grid float-right gap"
            onClick={() => this.openConfirmationPopup('Active')}
          >
            <i className="fa fas fa-download"></i> Export Active Users
          </p>
        ) : null}
        <ConfirmationBox
          message={'Do you want to download the file ?'}
          submitModal={submitModal}
          submitValue={() => (type === 'All' ? this.exportExcelForAll() : this.exportUserInfo(paramsForExcel, type))}
          closeModal={this.closeSubmitModal}
        />
        {userStatsData && userStatsData.length > 0 ? (
          <div className="ncss-row admin-grid-view">
            <div className="ncss-col-sm-12 grid-height-adjust">
              <Grid
                rowData={userStatsData}
                columnDefs={userStatGridConfig}
                // headerHeight={40}
                setMaterialHeight={true}
                setParams={this.setParams}
                suppressCellSelection={true}
              />
            </div>
          </div>
        ) : (
          <>{isLoading ? <Spinner large /> : null}</>
        )}
        {noData && !isLoading ? <div className="no_result ncss-col-sm-3">No Results found</div> : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isUser: isUser(state),
    userStatsData: state.getDetails.userStatsData,
    noData: state.getDetails.noData,
    isLoading: state.getDetails.isLoading,
    formDateValue: getFormValues('userstats')(state),
    totNewUsers:
      state.getDetails.totNewUsers && state.getDetails.totNewUsers.length >= 0
        ? state.getDetails.totNewUsers.length
        : 0,
    activeUserCount: state.getDetails.activeUserCount ? state.getDetails.activeUserCount : 0
  };
}

export const mapDispatchToProps = dispatch => ({
  requestAdminData: dateVal => {
    const options = {
      month: dateVal
    };
    dispatch(createServiceAction('userStatistics', 'requested')(options));
  },
  getExcelData: () => {
    dispatch(createServiceAction('getAllUserData', 'requested')());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'userstats'
  })(UserStatistics)
);
