import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createServiceAction } from '../../util/actionHelper.js';
import PropTypes from 'prop-types';
import { H2, ConfirmationBox, ToolTip } from '../index.js';
import download from 'downloadjs';

export class ListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      base64: '',
      fileName: '',
      contentType: ''
    };
    this.openConfirmationPopup = this.openConfirmationPopup.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }
  openConfirmationPopup = (base64, fileName, contentType) => {
    this.setState({ submitModal: true, base64, fileName, contentType });
  };

  downloadFile = () => {
    download(
      'data:' + this.state.contentType + ';base64,' + this.state.base64,
      this.state.fileName,
      this.state.contentType
    );
  };

  closeSubmitModal = () => {
    this.setState({ submitModal: false });
  };

  checkLength = data => {
    let formatedData = { ...data };
    if (data) {
      Object.entries(data).map(([key, value]) => {
        if (value && value.length === 0 && key !== 'techSpec') {
          formatedData[key] = ['N/A'];
        }
        return 'NA';
      });
    }
    return formatedData;
  };

  formatFileName = fname => {
    let file_name = fname;
    const fileFormat = fname.split('.');
    if (fileFormat[0].length > 11) {
      file_name = fileFormat[0].substring(0, 6) + '...' + fileFormat[1];
    }
    return file_name;
  };

  render() {
    const { getViewData, listHead } = this.props;
    const { fileName, submitModal } = this.state;
    const details = this.checkLength(getViewData);

    return (
      <div className="bag-filter-card  shadow-class">
        <H2 className="bag-title ">{listHead} </H2>
        <table className="bag-ul  shadow-class card-list">
          {details &&
            Object.entries(details).map(([key, value]) => {
              return (
                <tr key={key}>
                  {key === 'Decoration & Price' ? (
                    ''
                  ) : (
                    <td>
                      {key &&
                      key !== 'techSpec' &&
                      key !== 'fabricHeight' &&
                      key !== 'uom' &&
                      key !== 'launchShoes' &&
                      key !== 'shPrice' &&
                      key !== 'launchCategories'
                        ? (key.charAt(0).toUpperCase() + key.slice(1)).replace(/([A-Z])/g, ' $1').trim()
                        : key === 'techSpec'
                        ? 'Technical File'
                        : key === 'shPrice'
                        ? 'S&H Price'
                        : key === 'launchShoes'
                        ? 'Launch/Signature Product'
                        : key === 'uom'
                        ? 'UOM'
                        : key === 'launchCategories'
                        ? 'Launch/Signature Category'
                        : 'Fabric Height'}
                    </td>
                  )}

                  {value &&
                  typeof value === 'object' &&
                  value.length > 0 &&
                  key !== 'techSpec' &&
                  key !== 'Decoration & Price' ? (
                    <td>
                      {value.map((data, indx) =>
                        indx !== value.length - 1 ? <span>{data + ', '}</span> : <span>{data}</span>
                      )}
                    </td>
                  ) : value && typeof value === 'object' && key === 'techSpec' ? (
                    <td>
                      {value.length > 0
                        ? value.map((data, indx) => (
                            <ToolTip
                              display={
                                <div>
                                  <span className="attach-name">
                                    {data.fileName}
                                    <span
                                      onClick={() => {
                                        this.openConfirmationPopup(data.specFile, data.fileName, data.contentType);
                                      }}
                                    >
                                      <i className="fa fa-download  attach-icon" aria-hidden="true"></i>
                                    </span>
                                  </span>
                                </div>
                              }
                              tooltip_id={data.fileName}
                              toolTip={data.fileName}
                              tooltip_class="tooltip-listcard"
                              position="left"
                            ></ToolTip>
                          ))
                        : 'N/A'}
                    </td>
                  ) : (
                    <td className="listcard-items">
                      {typeof value === 'boolean' ? (value === true ? 'Yes' : 'No') : value}
                    </td>
                  )}
                </tr>
              );
            })}
          <div className="card-space-adjust"></div>
        </table>
        <ConfirmationBox
          message={'Do you want to download ' + fileName + '?'}
          submitModal={submitModal}
          submitValue={() => {
            this.downloadFile();
          }}
          closeModal={this.closeSubmitModal}
        />
      </div>
    );
  }
}

ListCard.propTypes = {
  downloadAttachment: PropTypes.func
};
function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => ({
  downloadAttachment: data => {
    dispatch(createServiceAction('downloadAttachment', 'requested')(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ListCard);
