export const downloadCSV = (fName, csvData) => {
  let csvDataArr = typeof csvData != 'object' ? JSON.parse(csvData) : csvData;

  let csvVal = '';
  let isHeader = true;
  if (isHeader) {
    let row = '';
    for (let index in csvDataArr[0]) {
      row += index + ',';
    }
    row = row.slice(0, -1);
    csvVal += row + '\r\n';
  }

  for (let i = 0; i < csvDataArr.length; i++) {
    let row = '';
    for (let index in csvDataArr[i]) {
      row += '"' + csvDataArr[i][index] + '",';
    }
    row.slice(0, row.length - 1);
    csvVal += row + '\r\n';
  }
  let fileName = `${fName}`;
  const uri = 'data:text/csv;charset=utf-8,' + escape(csvVal);

  let link = document.createElement('a');
  link.href = uri;

  link.style = 'visibility:hidden';
  link.download = fileName + '.csv';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
