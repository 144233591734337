import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Spinner, SheetCard, IconButton, PdfGenerator } from '../../components/index.js';
import { displayMessage, sortJSONAlphabatic } from '../../util/index.js';
import { Container, H3 } from '../../components/index.js';
import * as Constants from '../../constants/header.js';
import logo from '../../img/logo.png';
import defaultlogo from '../../img/ViewAll.jpg';
import ConfirmationBox from '../../components/confirmationBox/ConfirmationBox.js';
import { createServiceAction } from '../../util/actionHelper.js';

class CheatSheet extends React.Component {
  constructor() {
    super();
    this.state = {
      submitModal: false,
      cardData: [],
      date: new Date().toLocaleString()
    };
    this.openConfirmationPopup = this.openConfirmationPopup.bind(this);
  }
  componentWillMount() {
    const { requestCheetSheetData } = this.props;
    requestCheetSheetData();
  }

  openConfirmationPopup = () => {
    this.setState({ submitModal: true });
  };

  handleDownload = () => {
    displayMessage('info', 'Downloading ...', 3000);
    this.exportPDF();
  };

  exportPDF = () => {
    this.testPdf.save();
  };

  getCategoryLogo = category => {
    const { dashboardInfo } = this.props;
    if (category !== 'others' || dashboardInfo) {
      const data =
        dashboardInfo &&
        dashboardInfo.find(item => {
          return item.title.toLowerCase() === category;
        });
      return data && data.img_url ? `data:image/gif;base64,${data.img_url} ` : defaultlogo;
    } else {
      return defaultlogo;
    }
  };

  formatePrice = price => {
    let finalPrice = null;
    if (price.includes('-')) {
      const priceRange = price.split('-');
      priceRange.map(p => {
        return (finalPrice = finalPrice ? finalPrice + '-' + parseFloat(p).toFixed(2) : parseFloat(p).toFixed(2));
      });
    } else {
      finalPrice = parseFloat(price).toFixed(2);
    }
    return finalPrice;
  };

  closeSubmitModal = () => {
    this.setState({ submitModal: false });
  };

  render() {
    const { cheatSheetInfo, fetchingStatus, equipmentInfo } = this.props;
    const { date, submitModal } = this.state;

    if (equipmentInfo) {
      equipmentInfo.equipment.equipment.sort(function(a, b) {
        return sortJSONAlphabatic(
          a.isSeries && a.series ? a.series : a.code,
          b.isSeries && b.series ? b.series : b.code
        );
      });
    }
    return (
      <div>
        {cheatSheetInfo && !isEmpty(cheatSheetInfo) ? (
          <span>
            <PdfGenerator
              setRef={r => (this.testPdf = r)}
              margin={{ top: 60, left: 95, right: 100 }}
              paperSize="auto"
              imgResolution={70}
              pdfName={`ProductBrochure.pdf`}
            >
              <div id="divToPrint" className="fixed-fluid ncss-container">
                <Container section className="pdf-header">
                  <div className="ncss-col-sm-12 remove-padding">
                    <H3 className="inline-display  header-brand pl5-sm">
                      <img src={logo} alt="logo" className="logo" />
                    </H3>
                    <span className="text-color-white u-bold header-font">{Constants.APPNAME} PRODUCT BROCHURE</span>
                    <span className="hidden-text">
                      Nike, Inc. Highly Confidential: Digital Library Product Brochure
                    </span>
                  </div>
                </Container>
                <div className="footwear-style">FOOTWEAR PRODUCTS</div>
                <div className="date-style">Version:{date}</div>
                <div className="ncss-col-sm-12  mt5-sm sheet_border remove-padding">
                  {Object.keys(cheatSheetInfo).map((category, indx) =>
                    cheatSheetInfo[category] && !isEmpty(cheatSheetInfo[category]) ? (
                      <div
                        className={
                          indx % 2 === 0
                            ? 'blueClass ncss-col-sm-12 remove-padding'
                            : 'greenClass ncss-col-sm-12 remove-padding'
                        }
                      >
                        <div className="ncss-col-sm-2 vertical-top remove-padding category-border left-float">
                          <h3 className="sheet-category">
                            {category !== 'Others' ? category.toUpperCase() : 'OTHERS: VAPORMAX, EMAX AND TUNED AIR'}
                          </h3>
                          <div
                            className="sheet-category-backgorund"
                            style={{ backgroundImage: `url(${this.getCategoryLogo(category.toLocaleLowerCase())})` }}
                          ></div>
                        </div>
                        <div className="sheet_category_container right-float ncss-col-sm-10 remove-padding remove-margin">
                          {Object.keys(cheatSheetInfo[category]).map((bagtype, bagIndex) =>
                            cheatSheetInfo[category][bagtype] && cheatSheetInfo[category][bagtype].length > 0 ? (
                              <div className="category-collection">
                                <div
                                  className={`ncss-col-sm-2  vertical-top left-float bagtype-heading ${
                                    bagIndex !== 0 ? 'top-border' : 'category-border'
                                  }`}
                                >
                                  <h4 className="sheet-bagtype ">{bagtype.toUpperCase().replace(' ', '-')}</h4>
                                </div>

                                <div
                                  className={`ncss-col-md-10  pdf-image-align ${
                                    bagIndex !== 0 ? 'top-border' : 'category-border'
                                  }`}
                                >
                                  {cheatSheetInfo[category][bagtype].map((item, prdtIndx) => {
                                    return (
                                      <div className="ncss-col-md-3  ncss-col-sm-12 mt1-sm ">
                                        <p>
                                          <SheetCard
                                            code={item.isSeries && item.series ? item.series : item.code}
                                            price={this.formatePrice(item.price)}
                                            image_url={item.imgData}
                                            airsole_height={item.maximumAirsoleHeight}
                                          />
                                        </p>
                                        {/* </div> */}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : null
                          )}
                        </div>
                      </div>
                    ) : null
                  )}
                </div>

                <div className="footwear-style pt5-sm">EQUIPMENT PRODUCTS</div>
                <div className="ncss-col-sm-12  mt5-sm sheet_border remove-padding">
                  {Object.keys(equipmentInfo).map((category, indx) =>
                    equipmentInfo[category] && !isEmpty(equipmentInfo[category]) ? (
                      <div className={'orangeClass ncss-col-sm-12 remove-padding'}>
                        <div className="ncss-col-sm-2 vertical-top remove-padding category-border left-float">
                          <h3 className="sheet-category">{category.toUpperCase()}</h3>
                          <div
                            className="sheet-category-backgorund"
                            //style={{ backgroundImage: `url(${this.getCategoryLogo(category.toLocaleLowerCase())})` }}
                            style={{ backgroundImage: `url(images/equipment_Cheatsheet.png)`, marginTop: '20px' }}
                          ></div>
                        </div>
                        <div className="sheet_category_container right-float ncss-col-sm-10 remove-padding remove-margin">
                          {Object.keys(equipmentInfo[category]).map((bagtype, bagIndex) =>
                            equipmentInfo[category][bagtype] && equipmentInfo[category][bagtype].length > 0 ? (
                              <div className="category-collection">
                                <div
                                  className={`ncss-col-sm-2  vertical-top bagtype-heading ${
                                    bagIndex !== 0 ? 'top-border' : 'category-border'
                                  }`}
                                >
                                  <h4 className="sheet-bagtype">{bagtype.toUpperCase().replace(' ', '-')}</h4>
                                </div>
                                <div
                                  className={`ncss-col-md-10 pdf-image-align ${
                                    bagIndex !== 0 ? 'top-border' : 'category-border'
                                  }`}
                                >
                                  {equipmentInfo[category][bagtype].map((item, prdtIndx) => {
                                    return (
                                      <span>
                                        <div className="ncss-col-md-3  ncss-col-sm-11 mt1-sm ">
                                          <SheetCard
                                            equipment
                                            code={item.isSeries && item.series ? item.series : item.code}
                                            comments={item.comments}
                                            price={this.formatePrice(item.price)}
                                            image_url={item.imgData}
                                            airsole_height={item.maximumAirsoleHeight}
                                          />
                                        </div>
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : null
                          )}
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </PdfGenerator>
            <div className="p2-sm align-center download-button-trf download-position">
              <IconButton
                icon="download"
                title="Download Product Brochure"
                onClick={() => {
                  this.openConfirmationPopup();
                }}
              />
            </div>
          </span>
        ) : fetchingStatus ? (
          <div className="no_result ncss-col-sm-3">Product Brochure details not found.</div>
        ) : (
          <Spinner large />
        )}
        <ConfirmationBox
          message={'Do you want to download the Product Brochure ?'}
          submitModal={submitModal}
          submitValue={this.handleDownload}
          closeModal={this.closeSubmitModal}
        />
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  cheatSheetInfo: state.cheatSheet.cheatSheetInfo,
  equipmentInfo: state.cheatSheet.equipmentInfo,
  fetchingStatus: state.cheatSheet.fetchingStatus,
  dashboardInfo: state.dashboardInfo.dashboardInfo
});

export const mapDispatchToProps = dispatch => ({
  requestCheetSheetData: () => {
    dispatch(createServiceAction('getCheatSheetData', 'requested')());
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CheatSheet)
);
