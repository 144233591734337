import React from 'react';

import Label from './Label';

const defaultInputClass = 'ncss-input pt2-sm pr4-sm pb2-sm pl4-sm';
const defaultTextAreaClass = 'ncss-textarea p4-sm';
const defaultTextNumberClass = 'ncss-input p4-sm';

export const Input = ({
  textArea,
  onChange,
  type,
  className,
  label,
  name,
  disabled,
  value,
  checked,
  maxlength,
  ...props
}) => {
  if (type === 'textarea' || textArea) {
    return (
      <div className={`ncss-textarea-container ${className}`}>
        <Label className="ncss-label pr3-sm u-bold">
          <div>{label}</div>
        </Label>
        <textarea
          className={props.className || defaultTextAreaClass}
          {...props}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </div>
    );
  }
  if (type === 'number') {
    return (
      <input
        type="number"
        className={className || defaultTextNumberClass}
        {...props}
        value={value}
        onChange={e => onChange(e.target.value)}
        disabled={disabled ? 'disabled' : null}
        min="0"
      />
    );
  }

  if (type === 'checkbox') {
    return (
      <label className="switch mb2-sm">
        <input
          type="checkbox"
          className={props.className}
          {...props}
          checked={checked}
          onChange={e => onChange(e.target.checked)}
        />
        <span className="slider round"></span>
      </label>
    );
  }

  return (
    <input
      type={type}
      className={className || defaultInputClass}
      {...props}
      value={value}
      onChange={e => onChange(e.target.value)}
      disabled={disabled ? 'disabled' : null}
      maxlength={maxlength}
    />
  );
};

const renderTextInput = ({
  input,
  placeholder,
  type,
  label,
  containerClass,
  className,
  disabled,
  onKeyPress,
  maxlength,
  checked,
  meta: { touched, error, warning }
}) => (
  <div className={`${containerClass && containerClass}`}>
    <Input
      {...input}
      name={input.name}
      label={label}
      value={input.value}
      type={type}
      checked={checked}
      disabled={disabled}
      className={className}
      onKeyPress={onKeyPress}
      onChange={input.onChange}
      placeholder={placeholder}
      maxlength={maxlength}
    />
    {touched && ((error && <span className="error-text">{error}</span>) || (warning && <span>{warning}</span>))}
  </div>
);
export default renderTextInput;
