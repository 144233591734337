import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { formatEditDetails } from '../views/addProduct/editProductInitHelper';
import { put, takeEvery, call } from 'redux-saga/effects';
import {
  attachmentImage,
  attachDocument,
  attachLaunchProduct,
  attachSecondaryImg,
  disableUpdateButton
} from '../actions/addProductActions.js';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    // Pass a parameter true atlast if it is a path params
    const res = yield call(getRequest, apiConfig.getCompleteAirbagDetails.endPointUrl, action.data, true);
    //Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        const formatData = formatEditDetails(response.data);
        yield put(createServiceAction(serviceType, 'success')(formatData));
        const attachmentInfo =
          formatData.fileName && formatData.imgData
            ? { fileName: formatData.fileName, base64: formatData.imgData }
            : null;
        if (attachmentInfo) {
          yield put(attachmentImage(attachmentInfo));
        }
        const attachmentDocInfo = formatData.techSpec && formatData.techSpec;
        if (attachmentDocInfo.length > 0) {
          for (var i = 0; i < attachmentDocInfo.length; i++) {
            yield put(attachDocument(attachmentDocInfo[i]));
          }
        }
        const attachmentLaunchInfo = formatData.launchProducts;
        if (attachmentLaunchInfo.length > 0) {
          for (var j = 0; j < attachmentLaunchInfo.length; j++) {
            yield put(attachLaunchProduct(attachmentLaunchInfo[j]));
          }
        }
        const attachmentSecondaryImg = formatData.secondaryImg;
        if (attachmentSecondaryImg.length > 0) {
          yield put(attachSecondaryImg(attachmentSecondaryImg));
        }
        yield put(disableUpdateButton());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getEditAirbagDetails() {
  const serviceType = 'getEditDetails';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default getEditAirbagDetails;
