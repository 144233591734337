import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PDFExport } from '@progress/kendo-react-pdf';

export default class PdfGenerator extends Component {
  render() {
    const {
      setRef,
      children,
      margin,
      pdfName,
      imgResolution,
      paperSize,
      scale,
      keepTogether,
      repeatHeaders
    } = this.props;
    return (
      <PDFExport
        paperSize={paperSize}
        margin={margin}
        fileName={pdfName}
        imageResolution={imgResolution}
        scale={scale}
        keepTogether={keepTogether}
        repeatHeaders={repeatHeaders}
        ref={setRef}
      >
        {children}
      </PDFExport>
    );
  }
}

PdfGenerator.propTypes = {
  children: PropTypes.any,
  pdfName: PropTypes.string
};
