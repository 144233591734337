import React, { Component } from 'react';
import STLViewer from 'stl-viewer';
import PropTypes from 'prop-types';

class StlViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stl: null,
      fileName: null
    };
  }
  handleNewSTL = e => {
    this.setState({ stl: e.target.files[0], fileName: e.target.files[0].name });
  };

  render() {
    const { url, showUploadButton, isQuickView, isEditor, isDetailsView } = this.props;
    const width = isQuickView
      ? window.innerWidth / 2 - 360
      : isEditor
      ? window.innerWidth / 2 - 360
      : isDetailsView
      ? 460
      : window.innerWidth / 2;

    const height = isDetailsView ? 450 : 400;
    return (
      <div className="stl_viewer_div">
        <STLViewer
          model={url}
          width={width}
          height={height}
          className="stl_viewer"
          modelColor=""
          backgroundColor="#EAEAEA"
          rotate={false}
          orbitControls={true}
          lights={[[0.5, 1, -1], [1, 1, 1]]}
        />
        {showUploadButton ? (
          <div className="editor-upload-button">
            <input name="newStl" type="file" value="" title="" onChange={this.handleNewSTL} label="label" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default StlViewer;

StlViewer.propTypes = {
  url: PropTypes.string
};
StlViewer.defaultProps = {
  showUploadButton: false
};
