import React, { Component } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
// import { NikeThemeProvider } from '@nike/nr-sole-xl-theme-provider';

import Router from './routes.js';
import App from './App.js';

export default class Root extends Component {
  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        {/* <>NikeThemeProvider */}
          <Router component={App} />
        {/* </NikeThemeProvider> */}
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object
};
