import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest, downloadCSV } from '../util/index.js';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

function* listener(serviceType, action) {
  try {
    const options = {
      month: action.data.month
    };
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(getRequest, apiConfig.userStatistics.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response));
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* listenerForExcel(serviceType, action) {
  try {
    yield put(createServiceAction('getAllUserData', 'loading')());
    const res = yield call(getRequest, apiConfig.allUserStatistics.endPointUrl);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      console.log(response.errorCode);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction('getAllUserData', 'success')(response));
        let csvData = response.data;
        csvData.forEach(item => {
          item.Name = item.name;
          item.Usertype = item.userType;
          item.Email = item.email;
          delete item.name;
          delete item.userType;
          delete item.email;
          delete item.createdOn;
        });
        downloadCSV('All_Users', response.data);
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* userStatistics() {
  const serviceType = 'userStatistics';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  yield takeEvery(createServiceAction('getAllUserData', 'requested').type, listenerForExcel, serviceType);
}
export default userStatistics;
