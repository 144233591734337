import React, { Component } from 'react';
import { Line } from 'rc-progress';
import PropTypes from 'prop-types';

class ProgressBar extends Component {

  render() {
    const { percent, message } = this.props;
    return (
      <div className="progress-bar">
        <Line percent={percent} strokeWidth=".6" strokeColor="#2db7f5" />
        <div className="progress-bar-message">{message}
          <span  className="progress-bar-percentage">{`(${parseInt(percent)}%)`}</span>
        </div>
      </div>  
    );
  }
}

export default ProgressBar;

ProgressBar.propTypes = {
  percent: PropTypes.number,
  message: PropTypes.string
};
ProgressBar.defaultProps = {
  message: ''
};
