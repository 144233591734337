import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sortBy, isEqual } from 'lodash';
import { ReactSlider, Button } from '../../components/index.js';
import { createServiceAction } from '../../util/actionHelper.js';
import MediaQuery from 'react-responsive';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import '../../stylus/filter.styl';
import {
  categoryGroups,
  selectAllDispatch,
  selectCategory,
  priceRange,
  heightRange,
  priceRangeClear
} from '../../actions/filterAction.js';
import { createCategoryObject, creatPlatformObject } from '../../views/airBagPage/searchJSON.js';
import { Dialog } from '@mui/material';
import DialogHeader from '../../components/dialog/dialogHeader.js';
// import { airbagSearchParams } from '../../actions/airBagActions.js';

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      showFilter: false
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  // componentWillMount() {
  //   const { filtered } = this.props;
  //   const airbagSearchParamsInfo = sessionStorage.airbagSearchParamsInfo
  //     ? JSON.parse(sessionStorage.airbagSearchParamsInfo)
  //     : [];
  //   if (!filtered && airbagSearchParamsInfo && airbagSearchParamsInfo.length > 0) {
  //     const searchQuery = airbagSearchParamsInfo.filter(a => a.type);
  //     this.initialiseFilter(searchQuery);
  //   }
  // }
  componentWillMount() {
    const { getFilterRange, technology } = this.props;
    let tech = null;
    if (Array.isArray(technology)) {
      tech = technology;
    } else {
      tech = [technology];
    }
    getFilterRange({ technology: technology ? tech : ['View All'] });
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  initialiseFilter = params => {
    const minHeight = params.filter(a => a.label === 'minHeight');
    const maxHeight = params.filter(a => a.label === 'maxHeight');
    const priceMinRange = params.filter(a => a.label === 'priceMinRange');
    const priceMaxRange = params.filter(a => a.label === 'priceMaxRange');
    const bagTypes = createCategoryObject();
    const platforms = creatPlatformObject();

    const bagTypeArr = params.filter(a => a.type === 'bagType').map(e => e.value);
    const platformArr = params.filter(a => a.type === 'platform').map(e => e.value);
    const indexBagArr = [],
      indexPlatformArr = [];
    bagTypes.filter(function(element, index) {
      if (bagTypeArr.includes(element.name)) {
        indexBagArr.push(index);
        return true;
      }
      return false;
    });

    platforms.filter(function(element, index) {
      if (platformArr.includes(element.name)) {
        indexPlatformArr.push(index);
        return true;
      }
      return false;
    });

    if (minHeight.length && maxHeight.length) {
      this.onHeightSliderChange([minHeight[0].value, maxHeight[0].value]);
    }
    if (priceMaxRange.length && priceMinRange.length) {
      this.onPriceSliderChange([priceMinRange[0].value, priceMaxRange[0].value]);
    }
    if (indexBagArr.length > 0) {
      indexBagArr.forEach(item => {
        const object = {};
        object.index = item;
        object.key = 'bagType';
        object.clear = false;
        this.props.selectCategory(object);
      });
    }
    if (indexPlatformArr.length > 0) {
      indexPlatformArr.forEach(item => {
        const object = {};
        object.index = item;
        object.key = 'platform';
        object.clear = false;
        this.props.selectCategory(object);
      });
    }
  };

  onPriceSliderChange = values => {
    let obj = {};
    const { priceRange } = this.props;
    obj.data = values;
    // obj.airbagDetails = airbagDetails;
    priceRange(obj);
  };
  onHeightSliderChange = values => {
    let obj = {};
    const { heightRangeReq } = this.props;
    obj.data = values;
    // obj.airbagDetails = airbagDetails;
    heightRangeReq(obj);
  };
  filterCategory() {
    const { categoryGroups } = this.props;
    categoryGroups();
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };
  searchUpdated = term => {
    this.setState({ searchTerm: term });
  };
  showFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      !this.filterRef.contains(event.target) &&
      event.target.className !== 'fas fa-search badge'
    ) {
      this.setState({ showFilter: false });
    }
  }
  selectCategory(index, key, event, clear = false) {
    const object = {};
    object.index = index;
    object.key = key;
    object.clear = clear;
    // object.airbagDetails = this.props.airbagDetails;
    if (index === 0) {
      this.props.selectAllDispatch(object, !this.props.Category[key]['values'][index].checked);
    } else {
      this.props.selectCategory(object);
    }
    event.preventDefault();
  }

  //Called on individual close button click for filter
  resetSearchParams(val, key) {
    const { airbagSearchParamsInfo, airbagSearchParams } = this.props;
    let resetData = [];
    if (airbagSearchParamsInfo && airbagSearchParamsInfo.length > 0) {
      if (key === 'airsoleHeight') {
        resetData = airbagSearchParamsInfo.filter(a => a.type !== 'airsoleHeight');
      } else if (key === 'landedPrice') {
        resetData = airbagSearchParamsInfo.filter(a => a.type !== 'landedPrice');
      } else {
        resetData = airbagSearchParamsInfo.filter(a => a.id || a.__isNew__ || a.value !== val);
      }
      airbagSearchParams(resetData);
    }
  }

  clearIndividual(i, key, e) {
    if (key === 'landedPrice') {
      this.resetSearchParams(null, key);
      this.onPriceSliderChange(this.props.Category[key]['values']);
    } else if (key === 'airsoleHeight') {
      this.resetSearchParams(null, key);
      this.onHeightSliderChange(this.props.Category[key]['values']);
    } else {
      const val = this.props.Category[key]['values'][i].name;
      this.resetSearchParams(val, key);
      this.selectCategory(i, key, e, true);
    }
  }

  clearFilter(key) {
    const object = {};
    object.key = key;
    object.clear = true;
    object.clearAll = true;
    // object.airbagDetails = this.props.airbagDetails;
    this.props.selectAllDispatch(object);
  }
  clearAll = () => {
    Object.keys(this.props.Category).map(item => {
      return this.clearFilter(item);
    });
  };

  applyFilter = (clear = false) => {
    const { filterValue, searchAirbag, isFromDashboard, searchWithDashboardFilter } = this.props;
    let bagTypeQuery = [],
      airsoleQuery = [],
      priceQuery = [],
      platformQuery = [],
      dashboardSearchQuery = '';
    //clear set to true for Clear all button click
    if (!clear) {
      //create search parameter object for bagtype
      if (filterValue.bagType && filterValue.bagType.length > 0) {
        bagTypeQuery = filterValue.bagType.map(v => ({ value: v, label: v.toUpperCase(), type: 'bagType' }));
        dashboardSearchQuery += `BagType: ${filterValue.bagType.join(',')} `;
      }

      if (filterValue.platform && filterValue.platform.length > 0) {
        platformQuery = filterValue.platform.map(v => ({ value: v, label: v.toUpperCase(), type: 'platform' }));
        dashboardSearchQuery += `Platform: ${filterValue.platform.join(',')}  `;
      }

      //create search param obj for airsoleHt
      if (filterValue.airsoleHeight && filterValue.airsoleHeight.length > 0) {
        airsoleQuery = filterValue.airsoleHeight.map((el, index) => {
          var o = Object.assign({}, el);
          o.value = el;
          o.label = index === 0 ? 'minHeight' : 'maxHeight';
          o.type = 'airsoleHeight';
          return o;
        });
        dashboardSearchQuery += `AirsoleHeight: ${filterValue.airsoleHeight[0].toFixed(2) +
          '-' +
          filterValue.airsoleHeight[1].toFixed(2)}  `;
      }

      //create search param obj for landed price
      if (filterValue.landedPrice && filterValue.landedPrice.length > 0) {
        priceQuery = filterValue.landedPrice.map((el, index) => {
          var o = Object.assign({}, el);
          o.value = el;
          o.label = index === 0 ? 'priceMinRange' : 'priceMaxRange';
          o.type = 'landedPrice';
          return o;
        });
        dashboardSearchQuery += `LandedPrice: ${filterValue.landedPrice[0].toFixed(2) +
          '-' +
          filterValue.landedPrice[1].toFixed(2)}  `;
      }
    }
    if (!isFromDashboard) {
      searchAirbag([...bagTypeQuery, ...airsoleQuery, ...priceQuery, ...platformQuery], clear);
    } else {
      searchWithDashboardFilter(
        {
          heightValue: filterValue.airsoleHeight,
          priceValue: filterValue.landedPrice,
          availSizeValues: filterValue.availableSize,
          launchValues: filterValue.launchProductSampleSize,
          bagType: filterValue.bagType,
          technology: filterValue.platform
        },
        dashboardSearchQuery
      );
    }
  };

  render() {
    const {
      Category,
      filtered,
      priceRangeValues,
      heightRangeValues,
      filterClassName,
      isFromDashboard,
      isFromAirbagList,
      technology
    } = this.props;

    return (
      <div className={`ncss-col-sm-3 ${filterClassName ? filterClassName : 'filter filter-position'}`}>
        {this.state.showFilter && (
          <>
            <MediaQuery maxWidth={900}>
              <Dialog fullScreen open={this.state.showFilter}>
                <DialogHeader title="Filter" />
                <div
                  className={`ncss-col-sm-9  ${isFromAirbagList ? 'filter-push-right' : ''} ${
                    isFromDashboard ? 'height-filter-scroll-res' : ''
                  }`}
                  ref={this.setWrapperRef}
                >
                  {filtered ? (
                    <div className=" mb2-sm">
                      <div className="ncss-col-sm-12">
                        <Button
                          className="ncss-btn-black ncss-brand u-uppercase push_left pt1-sm pr2-sm pb1-sm pl2-sm"
                          onClick={() => {
                            this.applyFilter(false);
                          }}
                        >
                          APPLY Filter
                        </Button>
                      </div>
                      <div className="align-left">
                        Filter By
                        <button
                          className="push_right clr_all_btn"
                          onClick={() => {
                            this.clearAll();
                            if (!isFromDashboard) {
                              this.applyFilter(true);
                            }
                          }}
                        >
                          Clear All
                        </button>
                      </div>
                      <div className="mt2-sm mb2-sm">
                        {Object.keys(Category).map(item => {
                          return (
                            <ul className="p0-sm align-left">
                              {Category[item]['type'] === 'checkbox' &&
                                Category[item]['values'].map((data, i) => {
                                  return (
                                    data.checked &&
                                    i !== 0 && (
                                      <li className="text_upper">
                                        {item}: {data.name}
                                        <i
                                          class="fa fa-times push_right"
                                          aria-hidden="true"
                                          onClick={this.clearIndividual.bind(this, i, item)}
                                        ></i>
                                      </li>
                                    )
                                  );
                                })}
                              {Category[item]['type'] === 'slider' &&
                              priceRangeValues.length &&
                              item === 'landedPrice' &&
                              !isEqual(sortBy(priceRangeValues), sortBy(Category[item].values)) ? (
                                <li className="text_upper">
                                  {item}: {Category[item]['unit'] + priceRangeValues[0].toFixed(2)}-{' '}
                                  {Category[item]['unit'] + priceRangeValues[1].toFixed(2)}
                                  <i
                                    class="fa fa-times push_right"
                                    aria-hidden="true"
                                    onClick={this.clearIndividual.bind(this, 0, item)}
                                  ></i>
                                </li>
                              ) : null}
                              {Category[item]['type'] === 'slider' &&
                              heightRangeValues.length &&
                              item === 'airsoleHeight' &&
                              !isEqual(sortBy(heightRangeValues), sortBy(Category[item].values)) ? (
                                <li className="text_upper">
                                  {item}: {heightRangeValues[0].toFixed(2) + Category[item]['unit']}-
                                  {heightRangeValues[1].toFixed(2) + Category[item]['unit']}
                                  <i
                                    class="fa fa-times push_right"
                                    aria-hidden="true"
                                    onClick={this.clearIndividual.bind(this, -1, item)}
                                  ></i>
                                </li>
                              ) : null}
                            </ul>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  <div>
                    {Object.keys(Category).map(item => {
                      return (
                        <div className="mb1-sm">
                          {(item === 'airsoleHeight' && Category[item]['values'].length === 0) ||
                          item === 'availableSize' ||
                          item === 'launchProductSampleSize' ||
                          (item === 'platform' && technology !== 'View All') ? null : (
                            <span className="align-left modal-text">
                              {item === 'airsoleHeight' ? 'Maximum AirsoleHeight' : item}
                              {(Category[item]['type'] === 'checkbox' &&
                                item === 'platform' &&
                                technology === 'View All') ||
                              (Category[item]['type'] === 'checkbox' && item === 'bagType') ? (
                                <div>
                                  <ul className="align-left">
                                    {Category[item]['values'].map((data, i) => {
                                      return (
                                        <li
                                          className="ncss-checkbox-container"
                                          onClick={this.selectCategory.bind(this, i, item)}
                                        >
                                          <input
                                            type="checkbox"
                                            className="ncss-checkbox"
                                            checked={data.checked}
                                            id={data + 'id'}
                                          />
                                          <label className="ncss-label pl6-sm" for={data + 'id'}>
                                            {data.name.toLowerCase()}
                                          </label>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <button
                                    className="ncss-btn-black ncss-brand u-uppercase clear_filter display-none"
                                    onClick={this.clearFilter.bind(this, item)}
                                  >
                                    Clear Filter
                                  </button>
                                </div>
                              ) : null}
                              {Category[item]['type'] === 'slider' &&
                              Category[item]['values'].length > 0 &&
                              (item === 'landedPrice' || item === 'airsoleHeight') ? (
                                <ReactSlider
                                  domain={Category[item]['values']}
                                  values={item === 'landedPrice' ? priceRangeValues : heightRangeValues}
                                  onChange={
                                    item === 'landedPrice' ? this.onPriceSliderChange : this.onHeightSliderChange
                                  }
                                  unit={Category[item]['unit']}
                                  unitconcat={Category[item]['unitconcat']}
                                  itemclass={item === 'landedPrice' ? 'range_label' : 'range_label_airsole'}
                                />
                              ) : null}
                            </span>
                          )}
                        </div>
                      );
                    })}
                    ;
                  </div>
                </div>
              </Dialog>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
              <div
                className={`ncss-col-sm-9 filter_dropdown ${isFromAirbagList ? 'filter-push-right' : ''} ${
                  isFromDashboard ? 'height-filter-scroll' : ''
                }`}
                ref={this.setWrapperRef}
              >
                {filtered ? (
                  <div className=" mb2-sm">
                    <div className="ncss-col-sm-12">
                      <Button
                        className="ncss-btn-black ncss-brand u-uppercase push_left pt1-sm pr2-sm pb1-sm pl2-sm"
                        onClick={() => {
                          this.applyFilter(false);
                        }}
                      >
                        APPLY Filter
                      </Button>
                    </div>
                    <div className="align-left">
                      Filter By
                      <button
                        className="push_right clr_all_btn"
                        onClick={() => {
                          this.clearAll();
                          if (!isFromDashboard) {
                            this.applyFilter(true);
                          }
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                    <div className="mt2-sm mb2-sm">
                      {Object.keys(Category).map(item => {
                        return (
                          <ul className="p0-sm align-left">
                            {Category[item]['type'] === 'checkbox' &&
                              Category[item]['values'].map((data, i) => {
                                return (
                                  data.checked &&
                                  i !== 0 && (
                                    <li className="text_upper">
                                      {item}: {data.name}
                                      <i
                                        class="fa fa-times push_right"
                                        aria-hidden="true"
                                        onClick={this.clearIndividual.bind(this, i, item)}
                                      ></i>
                                    </li>
                                  )
                                );
                              })}
                            {Category[item]['type'] === 'slider' &&
                            priceRangeValues.length &&
                            item === 'landedPrice' &&
                            !isEqual(sortBy(priceRangeValues), sortBy(Category[item].values)) ? (
                              <li className="text_upper">
                                {item}: {Category[item]['unit'] + priceRangeValues[0].toFixed(2)}-{' '}
                                {Category[item]['unit'] + priceRangeValues[1].toFixed(2)}
                                <i
                                  class="fa fa-times push_right"
                                  aria-hidden="true"
                                  onClick={this.clearIndividual.bind(this, 0, item)}
                                ></i>
                              </li>
                            ) : null}
                            {Category[item]['type'] === 'slider' &&
                            heightRangeValues.length &&
                            item === 'airsoleHeight' &&
                            !isEqual(sortBy(heightRangeValues), sortBy(Category[item].values)) ? (
                              <li className="text_upper">
                                {item}: {heightRangeValues[0].toFixed(2) + Category[item]['unit']}-
                                {heightRangeValues[1].toFixed(2) + Category[item]['unit']}
                                <i
                                  class="fa fa-times push_right"
                                  aria-hidden="true"
                                  onClick={this.clearIndividual.bind(this, -1, item)}
                                ></i>
                              </li>
                            ) : null}
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                <Accordion allowZeroExpanded={true}>
                  {Object.keys(Category).map(item => {
                    return (
                      <div className="mb1-sm">
                        <AccordionItem>
                          {(item === 'airsoleHeight' && Category[item]['values'].length === 0) ||
                          item === 'availableSize' ||
                          item === 'launchProductSampleSize' ||
                          (item === 'platform' && technology !== 'View All') ? null : (
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <span className="subHeadings">
                                  {item === 'airsoleHeight' ? 'Maximum AirsoleHeight' : item}
                                </span>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          )}
                          <AccordionItemPanel>
                            {(Category[item]['type'] === 'checkbox' &&
                              item === 'platform' &&
                              technology === 'View All') ||
                            (Category[item]['type'] === 'checkbox' && item === 'bagType') ? (
                              <div>
                                <ul className="align-left">
                                  {Category[item]['values'].map((data, i) => {
                                    return (
                                      <li
                                        className="ncss-checkbox-container"
                                        onClick={this.selectCategory.bind(this, i, item)}
                                      >
                                        <input
                                          type="checkbox"
                                          className="ncss-checkbox"
                                          checked={data.checked}
                                          id={data + 'id'}
                                        />
                                        <label className="ncss-label pl6-sm" for={data + 'id'}>
                                          {data.name.toLowerCase()}
                                        </label>
                                      </li>
                                    );
                                  })}
                                </ul>
                                <button
                                  className="ncss-btn-black ncss-brand u-uppercase clear_filter display-none"
                                  onClick={this.clearFilter.bind(this, item)}
                                >
                                  Clear Filter
                                </button>
                              </div>
                            ) : null}
                            {Category[item]['type'] === 'slider' &&
                            Category[item]['values'].length > 0 &&
                            (item === 'landedPrice' || item === 'airsoleHeight') ? (
                              <ReactSlider
                                domain={Category[item]['values']}
                                values={item === 'landedPrice' ? priceRangeValues : heightRangeValues}
                                onChange={item === 'landedPrice' ? this.onPriceSliderChange : this.onHeightSliderChange}
                                unit={Category[item]['unit']}
                                unitconcat={Category[item]['unitconcat']}
                                itemclass={item === 'landedPrice' ? 'range_label' : 'range_label_airsole'}
                              />
                            ) : null}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>
            </MediaQuery>
          </>
        )}
        <span
          ref={ref => {
            this.filterRef = ref;
          }}
          onClick={this.showFilter}
        >
          <i className="fas fa-filter filter_icon"></i>
          <span className="filter_name">Filters</span>
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    Category: state.filter.Category,
    filtered: state.filter.filtered,
    priceRangeValues: state.filter.priceRangeValues,
    heightRangeValues: state.filter.heightRangeValues,
    filterValue: state.filter.filterValue,
    airbagSearchParamsInfo: state.airBag.airbagSearchParams
  };
};

export const mapDispatchToProps = dispatch => ({
  getFilterRange: data => {
    dispatch(createServiceAction('getFilterRange', 'requested')({ ...data }));
    dispatch(priceRangeClear(data));
  },
  categoryGroups: () => {
    dispatch(categoryGroups());
  },
  selectAllDispatch: data => {
    dispatch(selectAllDispatch(data));
  },
  selectCategory: object => {
    dispatch(selectCategory(object));
  },
  priceRange: obj => {
    dispatch(priceRange(obj));
  },
  heightRangeReq: obj => {
    dispatch(heightRange(obj));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filter));
