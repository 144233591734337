import keys from 'lodash/keys';
import pick from 'lodash/pick';
import every from 'lodash/every';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, postRequest, deleteRequest, displayMessage, imageputRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call, all } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    const model = {
      airbagId: null,
      productCode: null,
      platform: null,
      fileName: null,
      imgData: null,
      techSpec: null,
      launchImgs: null,
      initialInflationPressure: null,
      airbagComments: null,
      availableSizes: null,
      launchProductSampleSizes: null,
      launchShoes: null,
      pdmId: null,
      pcxId: null,
      airsoleHeight: null,
      bagType: null,
      handed: null,
      landedPrice: null,
      launchCategories: null,
      launchSeasons: null,
      status: null,
      isStatusChange: null,
      comments: null,
      reason: null,
      sheetThickness: null,
      dcsMaterialId: null,
      recycledContent: null,
      decoration: null,
      newDec: null,
      newInflPress: null,
      newAirHeight: null,
      removeDec: null,
      newSheetThick: null,
      removeSheetThick: null,
      fabricHeight: null,
      newFabHeight: null,
      removeFabHeight: null,
      newLaunchCateg: null,
      newLaunchShoes: null,
      uom: null,
      description: null,
      archiveDate: null,
      toolingExist: null,
      multiImgArray: null,
      removedSecondaryImg: null,
      stlImg: null,
      stlImgId: null,
      shPrice: null,
      airBagPrice: null
    };
    const result = pick(action.data, keys(model)),
      url = result.airbagId
        ? apiConfig.getCompleteAirbagDetails.endPointUrl + '/' + result.airbagId
        : apiConfig.getCompleteAirbagDetails.endPointUrl;

    for (var prop in result) {
      if (
        result[prop] === null ||
        result[prop] === undefined ||
        (prop === 'techSpec' && result.techSpec[0] && !result.techSpec[0].base64)
      ) {
        delete result[prop];
      }
    }
    let stlImgPayload = null;
    let removedstlAssetId = null;
    if (result.stlImg) {
      stlImgPayload = result.stlImg;
      delete result['stlImg'];
    }
    if (result.stlImgId) {
      removedstlAssetId = result.stlImgId;
      delete result['stlImgId'];
    }
    const removedLaunchImgs = action.data.removedLaunchImgs;
    const removedSecondaryImg = action.data.removedSecondaryImg ? action.data.removedSecondaryImg : [];

    const launchImgcount = action.data.launchImgCount;
    // const secImgCount = action.data.secImgCount;
    const infoPopDuration = 1500;

    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(putRequest, url, result);
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      const response = yield res.json(),
        serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        let showSuccessMsg = true;
        if (removedLaunchImgs.length > 0) {
          let url = apiConfig.launchProdDelete.endPointUrl.replace('id', result.airbagId);
          const res = yield all(
            removedLaunchImgs.map(imgData =>
              call(deleteRequest, url.replace('launchProductId', imgData.launchProductId), imgData)
            )
          );
          const resLaunchImgRemove = yield all(res.map(res => (res.status === 200 ? call([res, 'json']) : [])));
          if (resLaunchImgRemove.length > 0) {
            const serverError = yield all(
              resLaunchImgRemove.map(resVal => call(getServerValidationMessage, resVal, infoPopDuration))
            );
            if (!serverError.filter(err => err !== '').length === 0 && !every(resLaunchImgRemove, { errorCode: 0 })) {
              showSuccessMsg = false;
            }
          }
        }
        if (result.launchImgs.length > 0) {
          const id = result.airbagId;
          const res = yield all(
            result.launchImgs
              .filter(imgData => !imgData.launchProductId)
              .map(imgData => {
                //for getting count of total attachments to be inserted
                const fileRequest = {
                  ...imgData,
                  count: launchImgcount
                };
                return call(postRequest, apiConfig.addLaunchImg.endPointUrl + `/${id}/launchProduct`, fileRequest);
              })
          );
          const resLaunchImg = yield all(res.map(res => (res.status === 200 ? call([res, 'json']) : [])));
          if (resLaunchImg.length > 0) {
            const serverError = yield all(
              resLaunchImg.map(resVal => call(getServerValidationMessage, resVal, infoPopDuration))
            );
            if (!serverError.filter(err => err !== '').length === 0 && !every(resLaunchImg, { errorCode: 0 })) {
              showSuccessMsg = false;
            }
          }
        }
        if (removedSecondaryImg.length > 0) {
          const res = yield all(
            removedSecondaryImg.map(imgData =>
              call(deleteRequest, apiConfig.secondaryImgDel.endPointUrl.replace('secondaryImageId', imgData.id))
            )
          );
          const resSecImgRemove = yield all(res.map(res => (res.status === 200 ? call([res, 'json']) : [])));
          if (resSecImgRemove.length > 0) {
            const serverError = yield all(
              resSecImgRemove.map(resVal => call(getServerValidationMessage, resVal, infoPopDuration))
            );
            if (!serverError.filter(err => err !== '').length === 0 && !every(resSecImgRemove, { errorCode: 0 })) {
              showSuccessMsg = false;
            }
          }
        }
        if (result.multiImgArray && result.multiImgArray.length > 0) {
          const addImgArr = result.multiImgArray.filter(item => !item.id);
          const editedImgArr = result.multiImgArray.filter(item => item.id && item.isEdited);
          if (addImgArr.length > 0) {
            yield put(
              createServiceAction(
                'multiImgUpload',
                'submitted'
              )({
                airbagId: result.airbagId,
                multiImgArray: addImgArr,
                count: result.multiImgArray.length,
                type: serviceType
              })
            );
          }
          if (editedImgArr && editedImgArr.length > 0) {
            yield put(
              createServiceAction(
                'multiImgEdit',
                'submitted'
              )({
                airbagId: result.airbagId,
                multiImgArray: editedImgArr,
                count: result.multiImgArray.length,
                type: serviceType
              })
            );
          }
        }
        if (stlImgPayload) {
          const airbagId = result.airbagId;
          const resMeta = yield call(postRequest, apiConfig.createSTLMeta.endPointUrl, {
            name: stlImgPayload.fileName,
            contentType: 'application/octet-stream',
            assetClass: '3DImage',
            attributes: {
              airbagAssetId: airbagId
            }
          });
          const response = yield resMeta.json();
          if (response.assetId) {
            const url = apiConfig.createSTLImg.endPointUrl + response.assetId;
            yield call(imageputRequest, url, stlImgPayload.data);
          }
        }
        if (removedstlAssetId) {
          yield call(deleteRequest, `${apiConfig.deleteSTLImg.endPointUrl}/${removedstlAssetId}`);
        }
        if (showSuccessMsg) {
          yield put(createServiceAction(serviceType, 'success')(response.data));
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* editProductSubmitSagas() {
  const serviceType = 'editProductSubmit';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default editProductSubmitSagas;
