import React, { useState } from 'react';
import SizeBubbleRenderer from '../../views/adminGridView/adminRenderers/SizeBubbleRenderer';
import { Checkbox, FormControl, ListItemText, MenuItem, Select } from '@mui/material';
import { sortBy } from 'lodash';
import { store } from '../../index';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
const appendList = ['initialInflationPressure', 'airsoleHeight'];

const CheckSelect = props => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(props.params && props.params.value);
  const [tempCell, setTempCell] = useState({});
  const handleChange = e => {
    let tempData = _.cloneDeep(props.editedData);
    let tempArr = _.cloneDeep(props.params.data);
    if (props.params.colDef.field === 'airsoleHeight') {
      tempArr[props.params.colDef.field] = e.target.value.map(val => Number(val));
    } else {
      tempArr[props.params.colDef.field] = e.target.value;
    }
    if (props.params.data.platform === 'equipment' && tempArr.availableSizes && tempArr.availableSizes.length <= 0) {
      delete tempArr.availableSizes;
    }
    var elementIndex = tempData.findIndex(obj => obj.airbagId === props.params.data.airbagId);
    if (elementIndex !== -1) {
      tempData[elementIndex] = tempArr;
    } else {
      tempData.push(tempArr);
    }
    store.dispatch({ type: 'MASS_UPLOAD_DATA', payload: tempData });
    setValue(tempArr[props.params.colDef.field]);
    setTempCell(_.cloneDeep(tempArr));
  };
  const handleClick = () => {
    setEdit(true);
  };
  const handleClose = () => {
    let tempAdmin = _.cloneDeep(props.adminGridInfo);
    let index = _.findIndex(tempAdmin, { airbagId: props.params.data.airbagId });
    tempAdmin.splice(index, 1, tempCell);
    store.dispatch({ type: 'UPDATE_ADMIN_INFO', payload: tempAdmin });
    setEdit(false);
  };
  return !edit && value && value.length > 0 ? (
    appendList.includes(props.menuItems) ? (
      <div onClick={handleClick}>{value.join(',')}</div>
    ) : (
      <SizeBubbleRenderer handleClick={handleClick} data={sortBy(value)} field={props.field} />
    )
  ) : edit ? (
    <FormControl sx={{ m: 1, minWidth: 70, maxWidth: 70, float: 'left' }} size="small">
      <Select
        autoWidth={true}
        onClose={Object.keys(tempCell).length > 0 ? handleClose : ''}
        id="sample"
        multiple
        value={value}
        onChange={handleChange}
        renderValue={selected => selected.join(', ')}
      >
        {typeof props.menuItems !== 'string'
          ? props.menuItems.map(obj => (
              <MenuItem key={obj.id} value={obj.value}>
                <Checkbox style={{ color: '#000000cc' }} checked={value.indexOf(obj.value) > -1} />
                <ListItemText primary={obj.label} />
              </MenuItem>
            ))
          : props.dynamicOptionsList[props.menuItems].map((obj, index) => (
              <MenuItem key={obj.id + 'dynamic' + index} value={obj.value}>
                <Checkbox
                  style={{ color: '#000000cc' }}
                  checked={
                    props.params.colDef.field === 'airsoleHeight'
                      ? value.indexOf(Number(obj.value)) > -1
                      : value.indexOf(obj.value) > -1
                  }
                />
                <ListItemText primary={obj.label} />
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  ) : (
    <div onClick={handleClick}>&nbsp;</div>
  );
};
const mapStateToProps = state => ({
  dynamicOptionsList: state.adminInfo.dynamicOptionsList,
  editedData: state.adminInfo.editedData,
  adminGridInfo: state.adminInfo.adminGridInfo
});
export default compose(
  reduxForm({
    form: 'addProduct',
    enableReinitialize: true
  }),
  connect(mapStateToProps)
)(CheckSelect);
