import {
  selectAllDispatch,
  selectCategory,
  priceRange,
  priceRangeClear,
  heightRange,
  availableSizeRange,
  launchSizeRange
} from '../actions/filterAction.js';
import { createServiceAction } from '../util/actionHelper.js';
import { fromJS } from 'immutable';
import { createCategoryObject, creatPlatformObject } from '../views/airBagPage/searchJSON.js';
import { isEqual } from 'lodash';

const defaultState = {
  Category: {
    platform: { type: 'checkbox', values: [] },
    bagType: { type: 'checkbox', values: [] },
    landedPrice: { type: 'slider', values: [], unit: '$' },
    airsoleHeight: { type: 'slider', values: [], unit: 'mm', unitconcat: 'right' },
    availableSize: { type: 'slider', values: [], unit: '-', unitconcat: 'none' },
    launchProductSampleSize: { type: 'slider', values: [], unit: '-', unitconcat: 'none' }
  },
  filtered: false,
  airbagDetails: [],
  priceRangeValues: [],
  filteredData: {},
  heightRangeValues: [],
  availableSizeValues: [],
  launchSizeValues: []
};

export default function filter(state = defaultState, action) {
  let mapCategory = fromJS(state.Category);
  const payload = action.payload;
  const Update = (value, key, i, obj) => {
    let temp = mapCategory.updateIn([value, key, i], index => obj);
    state.Category = temp.toJS();
    return fromJS(state.Category);
  };

  const airBagFiltered = () => {
    let filteredData = {};
    state.filtered = false;
    for (var key of Object.keys(state.Category)) {
      let temp = [];
      if (state.Category[key].type === 'checkbox') {
        for (var value of state.Category[key]['values']) {
          if (value.checked) {
            state.filtered = true;
            if (value.name !== '(Select All)') {
              temp.push(value.name);
            }
          }
        }
        if (temp.length > 0) filteredData[key] = temp;
      } else if (state.Category[key].type === 'slider') {
        if (key === 'landedPrice' && state.priceRangeValues && state.priceRangeValues.length > 0) {
          if (isEqual(state.Category[key].values, state.priceRangeValues)) {
            state.filtered = state.filtered || false;
          } else {
            state.filtered = true;
            filteredData[key] = state.priceRangeValues;
          }
        } else if (key === 'airsoleHeight' && state.heightRangeValues && state.heightRangeValues.length > 0) {
          if (isEqual(state.Category[key].values, state.heightRangeValues)) {
            state.filtered = state.filtered || false;
          } else {
            state.filtered = true;
            filteredData[key] = state.heightRangeValues;
          }
        } else if (
          key === 'availableSize' &&
          state.availableSizeRangeValues &&
          state.availableSizeRangeValues.length > 0
        ) {
          if (isEqual(state.Category[key].values, state.availableSizeRangeValues)) {
            state.filtered = state.filtered || false;
          } else {
            state.filtered = true;
            filteredData[key] = state.availableSizeRangeValues;
          }
        } else {
          if (isEqual(state.Category[key].values, state.launchSizeRangeValues)) {
            state.filtered = state.filtered || false;
          } else {
            state.filtered = true;
            filteredData[key] = state.launchSizeRangeValues;
          }
        }
      }
    }
    return filteredData;
  };

  const checkUndefinedOrNull = value => {
    return !(value === undefined || value === null);
  };

  switch (action.type) {
    case createServiceAction('getFilterRange', 'success').type: {
      const landPrice =
        action.data.landPrice.min && action.data.landPrice.max
          ? [action.data.landPrice.min, action.data.landPrice.max]
          : [];
      const soleHeight =
        action.data.soleHeight.min && action.data.soleHeight.max
          ? [action.data.soleHeight.min, action.data.soleHeight.max]
          : [];
      const launchProductSampleSiz =
        checkUndefinedOrNull(action.data.launchProductSampleSizes.min) && action.data.launchProductSampleSizes.max
          ? [action.data.launchProductSampleSizes.min, action.data.launchProductSampleSizes.max]
          : [];
      const availableSiz =
        checkUndefinedOrNull(action.data.availableSizes.min) && action.data.availableSizes.max
          ? [action.data.availableSizes.min, action.data.availableSizes.max]
          : [];

      var keys = mapCategory.keySeq().toArray();
      for (let value of keys) {
        if (state.Category[value].type === 'checkbox') {
          //bag  Type
          state.Category[value]['values'] = value === 'platform' ? creatPlatformObject() : createCategoryObject();
        } else if (state.Category[value].type === 'slider') {
          if (value === 'landedPrice' && landPrice.length > 0) {
            mapCategory = Update(value, 'values', 1, landPrice[1]);
            if (landPrice[0] === landPrice[1]) {
              mapCategory = Update(value, 'values', 0, 0);
            } else {
              mapCategory = Update(value, 'values', 0, landPrice[0]);
            }
          } else if (value === 'airsoleHeight' && soleHeight.length > 0) {
            let heightList = soleHeight;
            if (heightList && heightList.length > 0) {
              const minValue = heightList[0],
                maxValue = heightList[heightList.length - 1];
              mapCategory = Update(value, 'values', 1, maxValue);
              if (minValue === maxValue) {
                mapCategory = Update(value, 'values', 0, 0);
              } else {
                mapCategory = Update(value, 'values', 0, minValue);
              }
            }
          } else if (value === 'availableSize' && availableSiz.length > 0) {
            let sizeList = availableSiz;
            if (sizeList && sizeList.length > 0) {
              const minValue = sizeList[0],
                maxValue = sizeList[sizeList.length - 1];
              mapCategory = Update(value, 'values', 1, maxValue);
              if (minValue === maxValue) {
                mapCategory = Update(value, 'values', 0, 0);
              } else {
                mapCategory = Update(value, 'values', 0, minValue);
              }
            }
          } else {
            let launchProductSampleSizList = launchProductSampleSiz;
            if (launchProductSampleSizList && launchProductSampleSizList.length > 0) {
              const minValue = launchProductSampleSizList[0],
                maxValue = launchProductSampleSizList[launchProductSampleSizList.length - 1];
              mapCategory = Update(value, 'values', 1, maxValue);
              if (minValue === maxValue) {
                mapCategory = Update(value, 'values', 0, 0);
              } else {
                mapCategory = Update(value, 'values', 0, minValue);
              }
            }
          }
        }
        mapCategory = fromJS(state.Category);
      }

      return { ...state, Category: state.Category };
    }

    case selectAllDispatch.type: {
      let clearBagType = true,
        clearPrice = true,
        clearHeight = true,
        clearAvailSize = true,
        clearLaunchSize = true;
      if (
        state.Category[payload.key] &&
        state.Category[payload.key].type === 'checkbox' &&
        state.Category[payload.key]['values'][0]
      ) {
        let value = !state.Category[payload.key]['values'][0].checked;
        state.Category[payload.key]['values'].map(function(el, i) {
          if (payload.clear) {
            value = false;
            clearBagType = true;
          } else {
            clearBagType = false;
          }
          let temp = mapCategory.updateIn([payload.key, 'values', i, 'checked'], key => value);
          state.Category = temp.toJS();
          mapCategory = fromJS(state.Category);
          return mapCategory;
        });
      } else if (state.Category[payload.key] && state.Category[payload.key].type === 'slider') {
        clearPrice = payload.key === 'landedPrice' && payload.clear ? true : false;
        clearHeight = payload.key === 'airsoleHeight' && payload.clear ? true : false;
        clearLaunchSize = payload.key === 'launchProductSampleSize' && payload.clear ? true : false;
        clearAvailSize = payload.key === 'availableSize' && payload.clear ? true : false;
        payload.key === 'landedPrice'
          ? (state.priceRangeValues = state.Category.landedPrice.values)
          : payload.key === 'airsoleHeight'
          ? (state.heightRangeValues = state.Category.airsoleHeight.values)
          : payload.key === 'availableSize'
          ? (state.availableSizeRangeValues = state.Category.availableSize.values)
          : (state.launchSizeRangeValues = state.Category.launchProductSampleSize.values);
      }
      let filterValue =
        clearPrice && clearBagType && clearHeight && clearAvailSize && clearLaunchSize ? [] : airBagFiltered();
      let filteredFlag = true;
      if (payload.clearAll || filterValue.length === 0) {
        filteredFlag = false;
      }
      return {
        ...state,
        Category: state.Category,
        filtered: filteredFlag,
        filterValue: filterValue,
        priceRangeValues: state.priceRangeValues,
        heightRangeValues: state.heightRangeValues,
        availableSizeRangeValues: state.availableSizeRangeValues,
        launchSizeRangeValues: state.launchSizeRangeValues
      };
    }

    case selectCategory.type:
      let temp = mapCategory,
        filteredCategory = Object.assign({}, state.filterValue),
        filteredBags = [];
      state.Category[payload.key]['values'].map((item, index) => {
        if (index === payload.index) {
          temp = mapCategory.updateIn([payload.key, 'values', index, 'checked'], key => !item.checked);
          if (payload.clear && filteredCategory && filteredCategory.bagType) {
            filteredBags = filteredCategory.bagType.filter(value => value !== item.name);
          }
          if (payload.clear && filteredCategory && filteredCategory.platform) {
            filteredBags = filteredCategory.platform.filter(value => value !== item.name);
          }
        }
        state.Category = temp.toJS();
        mapCategory = fromJS(state.Category);
        return mapCategory;
      });
      let checkedcount = state.Category[payload.key]['values'].filter(
        (element, i) => i !== 0 && element.checked === false
      ).length;
      if (checkedcount > 0) {
        temp = mapCategory.updateIn([payload.key, 'values', 0, 'checked'], key => false);
      } else {
        temp = mapCategory.updateIn([payload.key, 'values', 0, 'checked'], key => true);
      }
      state.Category = temp.toJS();
      mapCategory = fromJS(state.Category);
      const chkObj = o => Object.values(o).every(a => a.length === 0);
      // console.log(mapCategory.values(o).every(a => a.length === 0), 'trace')
      return {
        ...state,
        Category: state.Category,
        filterValue: !payload.clear
          ? airBagFiltered()
          : {
              ...state.filterValue,
              bagType: filteredBags
            },
        filtered: chkObj(airBagFiltered()) ? false : true
      };

    case priceRange.type: {
      state.priceRangeValues = payload.data;
      let filterValue = airBagFiltered();
      return { ...state, filterValue: filterValue, priceRangeValues: payload.data };
    }
    case heightRange.type: {
      state.heightRangeValues = payload.data;
      let filterValue = airBagFiltered();
      return { ...state, filterValue: filterValue, heightRangeValues: payload.data };
    }
    case availableSizeRange.type: {
      state.availableSizeRangeValues = payload.data;
      let filterValue = airBagFiltered();
      return { ...state, filterValue: filterValue, availableSizeRangeValues: payload.data };
    }
    case launchSizeRange.type: {
      state.launchSizeRangeValues = payload.data;
      let filterValue = airBagFiltered();
      return { ...state, filterValue: filterValue, launchSizeRangeValues: payload.data };
    }
    case priceRangeClear.type:
      // if (action.payload !== 'View All') {
      //   return {
      //     ...state,
      //     Category: {
      //       bagType: { type: 'checkbox', values: [] },
      //       landedPrice: { type: 'slider', values: [], unit: '$' },
      //       airsoleHeight: { type: 'slider', values: [], unit: 'mm', unitconcat: 'right' },
      //       availableSize: { type: 'slider', values: [], unit: '-', unitconcat: 'none' },
      //       launchProductSampleSize: { type: 'slider', values: [], unit: '-', unitconcat: 'none' }
      //     },
      //     filtered: false,
      //     airbagDetails: [],
      //     priceRangeValues: [],
      //     heightRangeValues: [],
      //     availableSizeRangeValues: [],
      //     launchSizeRangeValues: [],
      //     filteredData: {}
      //   };
      // }
      return {
        ...state,
        Category: {
          platform: { type: 'checkbox', values: [] },
          bagType: { type: 'checkbox', values: [] },
          landedPrice: { type: 'slider', values: [], unit: '$' },
          airsoleHeight: { type: 'slider', values: [], unit: 'mm', unitconcat: 'right' },
          availableSize: { type: 'slider', values: [], unit: '-', unitconcat: 'none' },
          launchProductSampleSize: { type: 'slider', values: [], unit: '-', unitconcat: 'none' }
        },
        filtered: false,
        airbagDetails: [],
        priceRangeValues: [],
        heightRangeValues: [],
        availableSizeRangeValues: [],
        launchSizeRangeValues: [],
        filteredData: {}
      };
    default:
      return state;
  }
}
