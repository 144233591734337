import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest, getRequestJSONUrl, downloadCSV } from '../util/index.js';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import isEmpty from 'lodash/isEmpty';

function* listener(serviceType, action) {
  try {
    const options =
      action.data === 'ALL'
        ? {
            isFAQ: true
          }
        : {
            isFAQ: true,
            technology: action.data
          };

    const res = action.data
      ? yield call(getRequest, apiConfig.cheatSheet.endPointUrl, options)
      : yield call(getRequest, apiConfig.cheatSheet.endPointUrl, { imgWidth: 110 });
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      // Parse response object
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        if (!isEmpty(response.data)) {
          let responseJSON = response.data;
          if (!action.data) {
            const resJSON = yield call(getRequestJSONUrl, response.data);
            responseJSON = yield resJSON.json();
            yield put(createServiceAction(serviceType, 'success')(responseJSON));
          }
          if (action.data) {
            let csvData = responseJSON;
            if (action.data !== 'Equipment') {
              csvData.forEach(item => {
                item.Series = item.series;
                item.Code = item.code;
                item.Platform = item.platform;
                item['Inflation Pressure'] = item.inflPrssr;
                item['Fabric Height'] = item.fabHeight;
                item['Available Sizes'] = item.availSizes;
                item['Comments'] = item.airbagComments;
                delete item.series;
                delete item.code;
                delete item.platform;
                delete item.inflPrssr;
                delete item.fabHeight;
                delete item.availSizes;
                delete item.airbagComments;
              });
            } else {
              csvData.forEach(item => {
                item.Series = item.series;
                item.Code = item.code;
                item.Platform = item.platform;
                item['UOM'] = item.uom;
                item['Available Sizes'] = item.availSizes;
                item['Comments'] = item.airbagComments;
                delete item.series;
                delete item.code;
                delete item.platform;
                delete item.uom;
                delete item.availSizes;
                delete item.airbagComments;
              });
            }
            downloadCSV(action.data, csvData);
          }
        } else {
          yield put(createServiceAction(serviceType, 'success')([]));
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getCheatSheetData() {
  const serviceType = 'getCheatSheetData';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}
export default getCheatSheetData;
