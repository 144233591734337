import bagTypes from '../../constants/json/admin/bagType.json';
const platform = [
  { id: '1', value: 'Blowmold', label: 'BLOWMOLD' },
  { id: '2', value: 'Zoom', label: 'ZOOM' },
  { id: '3', value: 'Max', label: 'MAX' },
  { id: '4', value: '2-Film', label: '2-FILM' },
  { id: '5', value: 'Visi Zoom', label: 'VISI ZOOM' },
  { id: '6', value: 'Tuned Air', label: 'TUNED AIR' },
  { id: '7', value: 'Emax', label: 'EMAX' },
  { id: '8', value: 'Vapormax', label: 'VAPORMAX' },
  { id: '9', value: 'Equipment', label: 'EQUIPMENT' }
];

export const groupedOptions = [
  {
    label: 'Bag Types',
    options: bagTypes
  },
  {
    label: 'Platforms',
    options: platform
  }
];

export const createCategoryObject = () => {
  return [
    { name: '(Select All)', checked: false },
    { name: 'heel', checked: false },
    { name: 'full length', checked: false },
    { name: 'forefoot', checked: false },
    { name: 'arch', checked: false },
    { name: 'midsole', checked: false },
    { name: 'collar', checked: false }
  ];
};

export const creatPlatformObject = () => {
  return [
    { name: '(Select All)', checked: false },
    { name: '2-Film', checked: false },
    { name: 'Blowmold', checked: false },
    { name: 'Zoom', checked: false },
    { name: 'Max', checked: false },
    { name: 'Emax', checked: false },
    { name: 'Vapormax', checked: false },
    { name: 'Visi Zoom', checked: false },
    { name: 'Tuned air', checked: false }
  ];
};

export const bagTypeValues = bagTypes;
export const platformValues = platform;
