import React from 'react';

import { Container, H1 } from '../index.js';

const NotFound = () => (
  <Container section className="user-404">
    <H1>
      <span role="img" aria-label=" Not Authorized" className = "color-error">
        <i class="fas fa-exclamation-triangle"></i>
      </span>
      <span className="invalid-res">The page you requested was not found.</span>
    </H1>
  </Container>
);

export default NotFound;
