import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spinner, H4, Card, PredictiveSearch } from '../../components/index.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { platFormTech } from '../../actions/dashboardActions.js';
import { priceRangeClear } from '../../actions/filterAction.js';
import { airbagSearchParams, dataReset } from '../../actions/airBagActions.js';
import { throttle } from 'lodash';
import MediaQuery from 'react-responsive';
import Filter from '../airBagPage/Filter.js';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { compose } from 'redux';

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      cardData: [],
    };
  }

  componentWillMount() {
    const { requestDashBoardData, history } = this.props;
    if (history.action === 'POP') {
      if (!sessionStorage.previousPage || JSON.parse(sessionStorage.currentPage) === 0) {
        requestDashBoardData();
      } else if (sessionStorage.load === 'true') {
        sessionStorage.setItem('load', 'false');
        requestDashBoardData();
      } else if (sessionStorage.selectedTechnology) {
        this.updateRoute(sessionStorage.selectedTechnology);
      }
    } else {
      requestDashBoardData();
    }
  }

  techResponseClick = (tech) => {
    const { platFormTech, history, dataReset } = this.props;
    dataReset();
    history.push('/airbag-details', { technology: tech });
    sessionStorage.setItem('previousPage', 0);
    sessionStorage.setItem('currentPage', 0);
    if (Array.isArray(tech)) {
      sessionStorage.setItem('selectedTechnology', JSON.stringify(tech));
    } else {
      sessionStorage.setItem('selectedTechnology', tech);
    }
    console.log(sessionStorage.getItem('selectedTechnology'));
    sessionStorage.setItem('load', 'false');
    sessionStorage.removeItem('universalSearchParams');
    platFormTech(tech);
  };

  delayedSearch = throttle((searchParam) => {
    const { searchAirBagDetails } = this.props;
    searchAirBagDetails(searchParam);
  }, 2000);

  fetchRemote = (e, newval, prev) => {
    if (newval !== '' && e.key !== 'Enter' && newval !== prev) {
      let searchParam = {
        offset: 0,
        searchLaunch: newval,
      };
      this.delayedSearch(searchParam);
    }
  };

  onSelectingOption = (val, item) => {
    const { change, history } = this.props;
    change('searchValue', '');
    history.push(`/airbag-complete-details/${item.airbagId}`, {
      airBagId: item.airbagId,
      showSearchLabel: 'Dashboard',
    });
  };

  onKeyPress = () => {
    const { change, searchFormValue } = this.props;
    if (searchFormValue && searchFormValue.searchValue) {
      change('searchValue', '');
      this.delayedSearch.cancel();
      const query = {
        technology: 'View All',
        searchLaunch: searchFormValue.searchValue,
        priceRange: [],
        heightRange: [],
        availSizeRange: [],
        launchSizeRange: [],
        imgWidth: 300,
        queryString: searchFormValue.searchValue,
      };
      this.doSearchForValueSelected(query);
    }
  };

  doSearchForValueSelected = (universalSearchParams) => {
    const { history } = this.props;
    sessionStorage.setItem('previousPage', 0);
    sessionStorage.setItem('currentPage', 0);
    sessionStorage.setItem('selectedTechnology', 'View All');
    sessionStorage.setItem('load', 'false');
    sessionStorage.setItem('previousPage', 0);
    sessionStorage.setItem('airbagSearchParamsInfo', []);
    sessionStorage.setItem('universalSearchParams', []);
    sessionStorage.setItem('universalSearchParams', JSON.stringify(universalSearchParams));
    history.push('/airbag-details', {
      technology: 'View All',
      showSearchLabel: 'Dashboard',
    });
  };

  searchWithDashboardFilter = (searchParams, query) => {
    const { history, searchFormValue, change } = this.props;
    const { priceValue, heightValue, availSizeValues, launchValues, bagType, technology } = searchParams;
    const searchValue = searchFormValue && searchFormValue.searchValue ? searchFormValue.searchValue : '';
    change('searchValue', '');
    const universalSearchParams = {
      searchLaunch: searchValue,
      priceRange: priceValue,
      heightRange: heightValue,
      availSizeRange: availSizeValues,
      launchSizeRange: launchValues,
      queryString: query + searchValue,
    };
    if (bagType && bagType.length > 0) {
      universalSearchParams.bagType = bagType.join(',');
    }
    if (technology && technology.length > 0) {
      universalSearchParams.technology = technology.join(',');
    } else {
      universalSearchParams.technology = 'View All';
    }
    sessionStorage.setItem('previousPage', 0);
    sessionStorage.setItem('currentPage', 0);
    sessionStorage.setItem('selectedTechnology', universalSearchParams.technology);
    sessionStorage.setItem('load', 'false');
    sessionStorage.setItem('previousPage', 0);
    sessionStorage.setItem('airbagSearchParamsInfo', []);
    sessionStorage.setItem('universalSearchParams', JSON.stringify(universalSearchParams));
    history.push('/airbag-details', {
      technology: 'View All',
      showSearchLabel: 'Dashboard',
    });
  };

  updateRoute = (tech) => {
    const { requestRouteChange, history } = this.props;
    const currentPage = JSON.parse(sessionStorage.currentPage);
    sessionStorage.setItem('currentPage', JSON.parse(sessionStorage.previousPage));
    sessionStorage.setItem('previousPage', currentPage);
    history.push('/airbag-details', { technology: tech });
    requestRouteChange(tech);
  };

  updateFavorate(val) {}
  render() {
    const { dashboardInfo, airbagDetails, noData, isFetching } = this.props;
    if (dashboardInfo) {
      dashboardInfo.filter((item, i) => {
        if (item.id === 3) {
          // dashboardInfo.splice(i, 1);
          // dashboardInfo.push(item);
          item.title = 'Emax / Vapormax';
        }
        return dashboardInfo;
      });
    }
    const menuStyle = {
      borderRadius: '3px',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 12px',
      background: '#fff',
      padding: '0px',
      position: 'absolute',
      overflow: 'auto',
      maxWidth: '61%',
      left: '4%',
      minWidth: '61%',
    };
    return (
      <div className="fixed-fluid ncss-container">
        <div className="dashboard pt3-sm pb1-sm ncss-col-sm-12 u-bold">
          <h1>Welcome to the Nike Air Digital Library</h1>
          <H4>An online information tool for the airsoles produced at Air Manufacturing Innovation</H4>
          <p>
            Disclaimer: the product information presented here is for reference only and has not been approved for any
            brand marketing or external use.
          </p>
        </div>
        <MediaQuery minWidth={1025}>
          <div className="ncss-col-sm-12 align-right push-right-search pb2-sm">
            <div className="ncss-col-sm-9 top-vertical">
              <Field
                component={PredictiveSearch}
                name="searchValue"
                type="text"
                isFromDashboard={true}
                className="dashboard-search"
                options={airbagDetails && airbagDetails.length > 0 ? airbagDetails : []}
                onChange={this.fetchRemote.bind(this)}
                menuStyle={menuStyle}
                showLoader={isFetching}
                onSelectingOption={this.onSelectingOption.bind(this)}
                onKeyPress={this.onKeyPress.bind(this)}
                isFetchingData={isFetching}
                noData={noData}
                searchAllFlag={false}
                wrapperStyle={{ width: '100% !important' }}
                hint={'Search with bagtype, platform, product code, description'}
              />
            </div>
            <Filter
              key={Math.random()}
              searchAirbag={() => {}}
              airbagSearchParams={[]}
              technology={'View All'}
              resetFilter={{}}
              searchWithDashboardFilter={this.searchWithDashboardFilter}
              priceRangeClear={() => {}}
              currentPage={0}
              isFromDashboard={true}
              filterClassName="ncss-col-sm-3 search-filt filter filter-dashboard-position"
            />
          </div>
        </MediaQuery>
        {dashboardInfo ? (
          <div className="ncss-row ">
            {dashboardInfo.map((info) => (
              <div key={info.title} className="ncss-col-sm-12 ncss-col-md-4 ncss-col-xl-4 mb1-sm mb1-xl">
                <div
                  onClick={() => {
                    if (info.title === 'Emax / Vapormax') {
                      this.techResponseClick(['Emax', 'Vapormax']);
                    } else {
                      this.techResponseClick(info.title);
                    }
                  }}
                  className="pointer-cursor"
                >
                  <Card category={info.title.split('.')[0]} path={info.img_url} faviorateSelect={this.updateFavorate} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Spinner large />
        )}
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  dashboardInfo: state.dashboardInfo.dashboardInfo,
  airbagDetails: state.search.searchInfo,
  noData: state.search.noData,
  isFetching: state.search.isLoading,
  isLoading: state.airBag.isLoading,
  searchFormValue: getFormValues('dashboardForm')(state),
  airbagDetailsTest: state.airBag.adminGridInfo,
});

export const mapDispatchToProps = (dispatch) => ({
  requestDashBoardData: () => {
    dispatch(createServiceAction('getDashboardData', 'requested')());
    // dispatch(createServiceAction('getFilterRange', 'requested')({ technology: ['View All'] }));
  },
  platFormTech: (title) => {
    dispatch(platFormTech(title));
    dispatch(priceRangeClear(title));
    dispatch(airbagSearchParams([]));
    // if(Array.isArray(title)) {
    //   dispatch(createServiceAction('getFilterRange', 'requested')({ technology: ['Emax', 'Vapormax'] }));
    // }else{
    //   dispatch(createServiceAction('getFilterRange', 'requested')({ technology: [title] }));
    // }
  },
  requestRouteChange: (title) => {
    dispatch(platFormTech(title));
  },
  searchAirBagDetails: (data) => {
    dispatch(createServiceAction('searchUniversalData', 'requested')({ ...data }));
  },
  dataReset: () => {
    dispatch(dataReset());
  },
});

export default withRouter(
  compose(
    reduxForm({
      form: 'dashboardForm',
      enableReinitialize: true,
    }),
    connect(mapStateToProps, mapDispatchToProps)
  )(Dashboard)
);
