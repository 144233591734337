import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SizeBubbleRenderer extends Component {
  render() {
    const { data, field, handleClick } = this.props;
    return (
      <div className="ncss-col-sm-12 left-text-align" onClick={() => handleClick()}>
        {data &&
          typeof data === 'object' &&
          data.map((label, index) => (
            <div className={`m1-sm ${field === 'sizes' ? 'ncss-col-sm-2 ' : 'ncss-col-sm-8'}`} key={index}>
              <div className={`size-bubble-background ${field === 'sizes' ? 'bubble-size ' : ''}`}>{`${
                field === 'sizes'
                  ? '-' + label
                  : field === 'decorations'
                  ? label.decorationName +
                    ' - ' +
                    (label.decorationPrice ? '$' + parseFloat(label.decorationPrice.replace('$', '')).toFixed(2) : null)
                  : label
              }`}</div>
            </div>
          ))}
      </div>
    );
  }
}

SizeBubbleRenderer.propTypes = {
  data: PropTypes.array,
  key: PropTypes.string
};
