export function getUserGroup(openIdClaims) {
  return (
    openIdClaims &&
    openIdClaims.groups &&
    openIdClaims.groups.map(value => {
      const grpInfoArr = value.split('.');
      let returnvalue = '';
      if (grpInfoArr[grpInfoArr.length - 1] === 'Admins') {
        returnvalue = 'Admins';
      } else if (grpInfoArr[grpInfoArr.length - 1] === 'Users') {
        returnvalue = 'Users';
      }
      return returnvalue;
    })[0]
  );
}

const tokenBase = {
  get isExpired() {
    return (this.expiresAt - 300) * 1000 < Date.now();
  }
};

const makeToken = token => Object.assign(Object.create(tokenBase), token);

// function parseJwtPayload(payload) {
//   return JSON.parse(window.atob(payload));
// }

function unpackEncodedToken(authStates) {
  if (!authStates || !authStates.accessToken) return null;

  const accessValues = authStates.accessToken;
  const openIdValues = authStates.idToken;
  const accessClaims = accessValues.claims;
  const openIdClaims = openIdValues.claims;
  const fullName = openIdClaims.name;
  const nameSplit = fullName.split(' ');

  // The name of these values will depend on the token issuer
  // Ping and Okta have different values for scope and username
  return {
    isAuthenticated: authStates.isAuthenticated,
    accessToken: authStates.accessToken.accessToken,
    openIdToken: authStates.idToken,
    expiresAt: accessClaims.exp,
    username: accessClaims.sub,
    email: accessClaims.sub,
    firstName: nameSplit[0],
    lastName: nameSplit[1],
    fullName: fullName,
    scope: accessClaims.scp,
    group: getUserGroup(accessClaims)
  };
}

export function getFullToken(authStates) {
  let tokenInfo = unpackEncodedToken(authStates);
  tokenInfo = makeToken(tokenInfo);
  return tokenInfo;
}
