import React from 'react';
import { IconButton } from '../index.js';
import isEmpty from 'lodash/isEmpty';

export const Card = ({ category, fav, path, faviorateSelect }) => {
  return (
    <div className="view card mb3-sm pb3-sm mb1-xl pb1-xl">
      <div className="card-body3">
        {/* Fav Icon Code */}
        {/* {fav? <i className=" fas fa-heart card-favourite" onClick={() => faviorateSelect(true)}></i>
                        :
                        <i className="far fa-heart card-favourite" onClick={() =>faviorateSelect(false)}></i>}
                        <br/> */}
        <div className="card-title">{category}</div>
      </div>
      <img className="blur test-img-class" src={`data:image/gif;base64,${path} `} alt="Background-Blur" />
      <img className="card-img-top test-img-class" src={`data:image/gif;base64,${path} `} alt="Background" />
    </div>
  );
};

export class DetailsCard extends React.Component {
  linkToNext = (to, id, technology, event) => {
    if (
      event.target.className !== 'control-arrow control-next' &&
      event.target.className !== 'control-arrow control-prev'
    ) {
      const { history } = this.props;
      history.push(to, {
        airBagId: id,
        technology: technology,
        searchLabel:
          history.location.state && history.location.state.showSearchLabel ? history.location.state.showSearchLabel : ''
      });
    }
  };
  render() {
    const {
      code,
      sizes,
      technology,
      landed_price,
      image_url,
      airbagId,
      quickViewClick,
      cateogryLoaded,
      to = '#',
      fabricHeight
    } = this.props;

    return (
      <div className="details-card  mb1-xl pb1-xl">
        <span>
          {code !== '' ? (
            <div className="modal-area">
              <span
                id="nextlink"
                to={to}
                onClick={e => {
                  this.linkToNext(to, airbagId, cateogryLoaded, e);
                }}
              >
                <div className="airbag-link">
                  <span className="airbag-heading pl2-sm"> {code} </span>
                  {/* {code === '2-film01'?
                  <Carousel 
                    showArrows 
                    showThumbs={false} 
                    showIndicators={false}  
                    showStatus={false}
                    autoPlay
                    infiniteLoop 
                    className="airbag-img-carousel">
                      
                    {image_list.map(url=>( 
                    <div 
                    // to={to}
                    // onClick={() => {
                    //   this.linkToNext(to, airbagId, cateogryLoaded);
                    // }}
                    >
                      <img className="airbag-img-carousel modal-img" src={url} alt="Background" />
                    </div>
                   ))}
                   {/* <div>
                      <img className="airbag-img-carousel modal-img" src={image_url} alt="Background" />
                    </div> */}
                  {/* </Carousel> */}
                  {/* :  */}
                  <div>
                    <img className="airbag-img-carousel modal-img" src={image_url} alt="Background" />
                  </div>
                  {/* } */}
                  <div
                    className="airbag-details"
                    //  to={to}
                    //  onClick={() => {
                    //    this.linkToNext(to, airbagId, cateogryLoaded);
                    //  }}
                  >
                    <span className="airbag-size pr2-sm">{sizes} Size(s)</span>
                    <div className="airbag-type pl2-sm">{technology}</div>
                    {(technology === 'zoom' || technology === 'visi zoom') && fabricHeight ? (
                      <span className="airbag-fabheight pr2-sm">{fabricHeight.join(',')}</span>
                    ) : null}
                    {landed_price ? <div className="airbag-price pl2-sm">{'$' + landed_price.toFixed(2)}</div> : null}
                  </div>
                </div>
              </span>
              <button className="modal-button" onClick={() => quickViewClick(airbagId)}>
                Quick View
              </button>
            </div>
          ) : (
            <span>
              <span className="airbag-heading pl2-sm"> {code} </span>
              <img className="airbag-img" src={image_url} alt="Background" />

              <div className="airbag-details">
                <span className="airbag-size pr2-sm">{sizes} Size(s)</span>
                <div className="airbag-type pl2-sm">{technology}</div>
                {(technology === 'zoom' || technology === 'visi zoom') && fabricHeight ? (
                  <span className="airbag-fabheight pr2-sm">{fabricHeight.join(',')}</span>
                ) : null}
                <div className="airbag-price pl2-sm">{landed_price}</div>
              </div>
            </span>
          )}
        </span>
      </div>
    );
  }
}

export const SheetCard = ({ code, image_url, price, airsole_height, equipment, comments }) => {
  let height = [...airsole_height];

  if (airsole_height && (airsole_height.indexOf('N/A') > -1 || airsole_height.length === 0)) {
    height = 'N/A';
  }

  return (
    <div className="sheet-card  mb1-xl pb1-xl text-center">
      <span>
        <div className="sheet-code">
          {code} (<span className="sheet-price-code">${price}</span>)
        </div>
        {equipment ? (
          <div className="sheet-code">
            Comments-<span className="sheet-price-code">{comments !== '' ? comments : 'N/A'}</span>
          </div>
        ) : (
          <div className="sheet-code">
            Maximum Airsole Height-<span className="sheet-price-code">{height}</span>
          </div>
        )}
        <img className="sheet-img" src={image_url} alt="Background" />
      </span>
    </div>
  );
};

export const FaqCard = ({
  category,
  questions,
  onSelectQuestion,
  openModel,
  isAdmin,
  catId,
  enableDeleteQstnsubmit
}) => {
  return (
    <div className="sheet-card-faq  mb1-xl pb1-xl text-center">
      <span>
        <div>
          <span className="faq-category ">{category}</span>
          {isAdmin && (
            <span className="cat-option-position">
              <IconButton
                icon="pen"
                extraClassName="faq-add margin-adjust"
                onClick={() => {
                  openModel(category);
                }}
              />
              <IconButton
                icon="trash"
                extraClassName="faq-add margin-adjust"
                onClick={() => {
                  enableDeleteQstnsubmit(catId);
                }}
              />
            </span>
          )}
          <hr color="#ccc" size="5" className="margin-line" />
        </div>
        <div className="qstn-scroll">
          {questions.map(item => {
            return (
              <div>
                <span
                  className="faq-qstn"
                  onClick={() =>
                    onSelectQuestion({ ...item, category, topicid: catId, contentid: item.id, faqDoc: item.faqDoc })
                  }
                >
                  {item.qstn}
                </span>
              </div>
            );
          })}
        </div>
      </span>
    </div>
  );
};

export const QstnCard = ({ obj, index, onDelete }) => {
  return (
    <div className="ncss-col-sm-12 pb4-sm pr4-sm ncss-input-container ">
      <div className="newQuestionsAns">
        <span className="ncss-col-sm-3 head">Question{index + 1}:</span>
        <span className="ncss-col-sm-7 text">{obj.qstn}</span>
        <button type="button" onClick={() => onDelete(index)} className="btn push-right">
          <i className="fas fa-trash fa-fw text-color-black"></i>
        </button>

        <span className="ncss-col-sm-3 head">Answer{index + 1} :</span>
        <span className="ncss-col-sm-7 text">{obj.ans} </span>
        {obj.faqDoc && !isEmpty(obj.faqDoc) && (
          <span>
            <span className="ncss-col-sm-3 head">Attachment :</span>
            <span className="ncss-col-sm-7 text">{obj.faqDoc.fileName} </span>
          </span>
        )}
      </div>
    </div>
  );
};
