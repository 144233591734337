import { platFormTech } from '../actions/dashboardActions.js';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = {
  attachmentData: {}
};

export default function dashboardInfo(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getDashboardData', 'success').type:
      return { ...state, dashboardInfo: action.data.data };

    case platFormTech.type:
      return { ...state, platform: action.payload };

    default:
      return state;
  }
}
