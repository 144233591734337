import { createServiceAction } from '../util/actionHelper.js';

const defaultState = {
  attachmentData: {}
};
const formatOptions = data => {
  return (
    data &&
    data.values &&
    data.values.map((item, index) => {
      return { id: index, value: item, label: item };
    })
  );
};

export default function addEditProductInfo(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getDecorationOptions', 'success').type:
      const decorationOptions = formatOptions(action.data);
      return { ...state, decorationList: decorationOptions };

    case createServiceAction('getFabricHeightOptions', 'success').type:
      const fabricHeightOptions = formatOptions(action.data);
      return { ...state, fabricHeightList: fabricHeightOptions };

    case createServiceAction('getSheetThicknessOptions', 'success').type:
      const sheetThicknessOptions = formatOptions(action.data);
      return { ...state, sheetThicknessList: sheetThicknessOptions };

    default:
      return state;
  }
}
