import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
// import PropTypes from 'prop-types';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { oidc } from '../configs/oAuthConfig.js';
import Login from '../views/auth/login.js';
import appHistory from '../util/history.js';
import { history } from '../util/index.js';
import { ConnectedRouter } from 'react-router-redux';
// import Login from '../views/auth/login.js'
import UserNotExistsError from '../views/Generic/UserNotExistsError.js';

function customAuthHandler(authService) {
  // Redirect to the /login page that has a CustomLoginComponent
  // This example is specific to React-Router
  history.push('/');
}

const oktaAuth = new OktaAuth(oidc);

const Routes = ({ history = appHistory }) => {
  //const history = useHistory();

  const restoreOriginalUri = async (oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  const implicitCallback = () => (
    <div>
      <LoginCallback errorComponent={UserNotExistsError} />
    </div>
  );

  try {
    if (
      localStorage.getItem('okta-token-storage') &&
      JSON.parse(localStorage.getItem('okta-token-storage')).idToken &&
      JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims &&
      JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.exp &&
      new Date() > new Date(JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.exp * 1000)
    ) {
      localStorage.removeItem('okta-cache-storage');
      localStorage.removeItem('okta-token-storage');
    }
    if (window.performance) {
      if (performance.navigation.type === 1) {
        history = appHistory;
      } else {
        history = appHistory;
      }
    }

    return (
      <Router history={history}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Route path="/implicit/callback" component={implicitCallback} />
          <SecureRoute path="/" component={Login} />
        </Security>
      </Router>
    );
  } catch (e) {
    return (
      <Router>
        <ConnectedRouter history={history}>
          <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            <Route path="/implicit/callback" component={implicitCallback} />
            <SecureRoute path="/" component={Login} />
          </Security>
        </ConnectedRouter>
      </Router>
    );
  }
};

const RouterAccess = () => (
  <Router>
    <Routes />
  </Router>
);

export default RouterAccess;
