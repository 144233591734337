import { createAction } from '../util/index.js';

export const toggleFaqModal = createAction('TOGGLE_FAQ_MODAL', requestObject => requestObject);

export const toggleFaqAddModal = createAction('TOGGLE_FAQ_ADD_MODAL', requestObject => requestObject);

export const resetFaq = createAction('RESET_FAQ');

export const resetFaqAttchment = createAction('RESET_FAQ_ATTACHMENT');

export const sortChangeDelete = createAction('SORT_CHANGE_DELETE');
