import moment from 'moment';
import React from 'react';

const config = [
  {
    colId: 'userName',
    headerName: 'User Name',
    field: 'userName',
    suppressMenu: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellClass: 'cell-wrap',
    cellRendererFramework: params => {
      return params.data.name || params.data.userName;
    }
  },
  {
    colId: 'email',
    headerName: 'Email',
    field: 'email',
    suppressMenu: true,
    autoHeight: true,
    cellClass: 'cell-wrap',
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'userType',
    headerName: 'User Type',
    field: 'userType',
    suppressMenu: true,
    autoHeight: true,
    cellClass: 'cell-wrap',
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'newuser',
    headerName: 'New User',
    field: 'name',
    suppressMenu: true,
    autoHeight: true,
    cellRendererFramework: params => {
      return params.data ? (
        <div className={`ncss-col-sm-2 pt2-sm`}>
          <span>
            <i className={`icon-size-adjust fas ${params.value ? 'fa-check' : 'fa-times'}`}></i>
          </span>
        </div>
      ) : null;
    },
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'date',
    headerName: 'Active/Created Date',
    field: 'eventDate',
    suppressMenu: true,
    autoHeight: true,
    cellClass: 'cell-wrap',
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellRenderer: data => {
      return moment(data.value).format('MM/DD/YYYY');
    }
  }
];
export default config;
