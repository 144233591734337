import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

function* listener(serviceType, action) {
  try {
    const res = yield call(getRequest, apiConfig.faqCategories.endPointUrl),
      statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json(),
        serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response.data));
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getFAQCategories() {
  const serviceType = 'getFAQCategories';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}
export default getFAQCategories;
