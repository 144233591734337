import { createServiceAction } from '../util/actionHelper.js';
import {
  toggleFaqModal,
  toggleFaqAddModal,
  resetFaq,
  resetFaqAttchment,
  sortChangeDelete
} from '../actions/faqAction.js';

const defaultState = {
  faqInfo: [],
  faqModelOpen: false,
  faqAddModelOpen: false,
  faqCat: [],
  enableFaqSubmit: false,
  fetchStatus: false,
  faqAttachment: null,
  deleteFinish: false,
  startDestUpdate: false,
  destCallStart: false
};

export default function faq(state = defaultState, action) {
  const formateCategoriesOptions = data => {
    return (
      data &&
      data.topics.map((item, index) => {
        return { label: item, value: item, id: index };
      })
    );
  };
  switch (action.type) {
    case createServiceAction('getFAQData', 'success').type:
      let sortedFaq = action.data && action.data.sort((a, b) => a.sort - b.sort);
      sortedFaq.map(item => item && item.content.sort((a, b) => a.sort - b.sort));
      return { ...state, faqInfo: sortedFaq, fetchStatus: true, startDestUpdate: false, destCallStart: true };
    case createServiceAction('deleteFaq', 'success').type:
      return { ...state, deleteFinish: true, destCallStart: false };
    case toggleFaqModal.type:
      return { ...state, faqModelOpen: action.payload };
    case toggleFaqAddModal.type:
      return { ...state, faqAddModelOpen: action.payload, faqAttachment: null };
    case sortChangeDelete.type:
      return { ...state, deleteFinish: false };
    case createServiceAction('getFAQCategories', 'success').type:
      const finalOptions = formateCategoriesOptions(action.data);
      return { ...state, faqCat: finalOptions };
    case createServiceAction('updateFAQOrder', 'success').type:
      return { ...state, deleteFinish: false, destCallStart: false };
    case createServiceAction('updateFAQOrder', 'loading').type:
      return { ...state, destCallStart: false };
    case createServiceAction('destUpdation', 'requested').type:
      return { ...state, startDestUpdate: true, destCallStart: false };
    case resetFaq.type:
      return {
        ...state,
        faqInfo: [],
        faqModelOpen: false,
        faqAddModelOpen: false,
        faqCat: [],
        enableFaqSubmit: false,
        fetchStatus: false,
        faqAttachment: null
      };
    case createServiceAction('getFAQAttachment', 'success').type:
      return { ...state, faqAttachment: action.data, isLoading: false };
    case createServiceAction('getFAQAttachment', 'nodata').type:
      return { ...state, faqAttachment: null, isLoading: false };
    case createServiceAction('getFAQAttachment', 'loading').type:
      return { ...state, isLoading: true, faqAttachment: null };
    case resetFaqAttchment.type:
      return { ...state, faqAttachment: null };
    default:
      return state;
  }
}
