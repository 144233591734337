import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { getHTTPStatusValidationMessage } from '../services/serverValidations.js';
import isEmpty from 'lodash/isEmpty';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    console.log('stl,fileName 2', action.data);
    const url = apiConfig.fetchSTL.endPointUrl + action.data;
    const res = yield call(getRequest, url);
    yield delay(900, true);

    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    console.log('stl,fileName', statusErrorMessage);
    if (!statusErrorMessage) {
      // Display server error message
      const serverError = res && res.status === 200 && res.statusText === '' ? false : true;
      // const response = yield call([res, res.text]);

      const resBlob = yield res.blob();
      let blob = yield new Blob([resBlob], { type: 'stl' });

      // console.log('json respons', res.clone().text());

      const response = yield URL.createObjectURL(blob);

      if (!isEmpty(response) && !serverError) {
        console.log('inside data IF');
        yield put(createServiceAction(serviceType, 'success')(response));
      } else {
        yield put(createServiceAction(serviceType, 'nodata')());
      }
    }
  } catch (e) {
    console.log('stl,fileName', e);
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* stlDetailsSaga() {
  const serviceType = 'getSTLData';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default stlDetailsSaga;
