import React, { Component, lazy, Suspense } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import App from '../../root/App';
import { history } from '../../util/index.js';
import { setUserLogin, setUserLogout, authCheckRequested } from '../../actions/authActions.js';
import { selectUserDetails, loginAuthFinish, logout, validUser, validGroup } from '../../reducers/authReducer.js';
import { getUserGroup, getFullToken } from './authHelpers';
import { Container, H1, Button } from '../../components/index.js';

import { InvalidRoute } from '../../components/invalid_route/InvalidRoute.js';
import LogoutUser from '../../views/auth/logoutUser.js';
import UserNotExistsError from '../../views/Generic/UserNotExistsError.js';
import UserMismatchError from '../../views/Generic/UserMismatchError.js';
import Dashboard from '../../views/dashboard/dashboard.js';
import AdminGridView from '../../views/adminGridView/adminGridView.js';
import CheatSheet from '../../views/cheatSheet/cheatsheet.js';
// import ApprovedInnovation from '../views/airsoleRequest/index.js';
// import FAQ from '../../views/faq/faq.js';
import userStatistics from '../../views/userStatistics/userStatistics.js';
const FAQ = lazy(() => import('../../views/faq/faq.js'));
const airBagDetails = lazy(() => import('../../views/airBagDetails/airBagDetails.js'));
const AddProduct = lazy(() => import('../../views/addProduct/addProduct.js'));
const AirBagPage = lazy(() => import('../../views/airBagPage/airBagPage.js'));
const AirsoleRequest = lazy(() => import('../../views/airsoleRequest/index.js'));
const Cart = lazy(() => import('../../views/cart/cart.js'));
const SearchPage = lazy(() => import('../../views/searchAirbag/SearchPage.js'));

export const routes = [
  {
    path: '/',
    title: 'Dashboard',
    component: Dashboard,
    nav: true,
    icon: 'home',
    id: 1,
  },
  {
    path: '/product-brochure',
    title: 'Product Brochure',
    component: CheatSheet,
    nav: true,
    icon: 'file-alt',
    id: 2,
  },
  {
    path: '/AddProduct',
    title: 'Add Product',
    component: AddProduct,
    nav: false,
    id: 5,
  },
  {
    path: '/airbag-details',
    title: 'AirBagPage',
    component: AirBagPage,
    nav: false,
    admin: false,
    icon: '',
    id: 3,
  },
  {
    path: '/airbag-details/:category',
    title: 'AirBagPageLink',
    component: AirBagPage,
    nav: false,
    admin: false,
    icon: '',
    id: 6,
  },
  {
    path: '/admin',
    title: 'Admin Panel',
    component: AdminGridView,
    nav: true,
    admin: true,
    icon: 'user-shield',
    heading: 'My Links',
    id: 4,
  },
  {
    path: '/requestform',
    title: 'Request Form',
    component: AirsoleRequest,
    nav: true,
    icon: 'align-justify',
    id: 21,
  },
  {
    path: '/help',
    title: 'Get Help',
    component: null,
    nav: true,
    icon: 'question-circle',
    heading: 'My Links',
    id: 11,
    url: 'mailto:Lst-Digital.Library.Support@nike.com',
  },
  {
    path: '/faq',
    title: 'FAQ',
    component: FAQ,
    nav: true,
    icon: 'comments',
    id: 10,
  },
  {
    path: '/user-stats',
    title: 'User Statistics',
    component: userStatistics,
    nav: true,
    admin: true,
    icon: 'chart-bar',
    id: 22,
  },
  {
    path: '/logout',
    title: 'Logout',
    component: LogoutUser,
    nav: false,
    heading: '',
    icon: 'sign-out-alt',
    id: 12,
  },
  // Routes starting with '/error' will not have access to Nav bar
  {
    path: '/error',
    title: 'Authentication Issues',
    component: UserNotExistsError,
    nav: false,
    heading: null,
    id: 18,
  },
  {
    path: '/error-mismatch',
    title: 'Authentication Issues',
    component: UserMismatchError,
    nav: false,
    heading: null,
    id: 18,
  },
  {
    path: '/airbag-complete-details/:airBagId',
    title: 'airbag Details',
    component: airBagDetails,
    nav: false,
    heading: null,
    id: 19,
  },
  {
    path: '/airbag-cart-details',
    title: 'airbag cart Details',
    component: Cart,
    nav: false,
    heading: null,
    id: 20,
  },
  {
    path: '/airbag-search-details',
    title: 'airbag cart Details',
    component: SearchPage,
    nav: false,
    heading: null,
    id: 20,
  },
  { component: InvalidRoute, id: 21 },
];

export const navRoutes = routes ? routes.filter((r) => r.nav) : [];

async function checkUser() {
  const { authState, oktaAuth, requestSetUser, logout } = this.props;
  const { authCheckRequested } = this.props;
  const { userInfo } = this.state;

  if (sessionStorage.getItem('token-satus') !== 'expired') {
    authCheckRequested();
    if (authState.isAuthenticated && userInfo === null) {
      const authStates = await authState;
      let userInfo = await oktaAuth.token.getUserInfo();
      const tokens = await oktaAuth.tokenManager.getTokens();

      const getAccessToken = tokens.accessToken;
      if (getAccessToken && getAccessToken.expiresAt && !logout) {
        authState.expiresAt = getAccessToken.expiresAt;
        this.setState({ currentExpireTime: getAccessToken.expiresAt });
      }

      if (userInfo) {
        userInfo.group = getUserGroup(userInfo);

        let tokenInfo = null;
        if (!logout) {
          tokenInfo = getFullToken(authStates, userInfo, getAccessToken);
        } else {
          tokenInfo = null;
        }

        if (this._isMounted) {
          if (authStates) {
            requestSetUser(tokenInfo);
          }

          this.setState({ userInfo, authStates });
        }
      }
    }
  }
}

class Login extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { userInfo: null, currentExpireTime: null };
    this.checkUser = checkUser.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  async login() {
    const { oktaAuth } = this.props;
    await oktaAuth.signInWithRedirect('/login');
  }

  async logout() {
    const { oktaAuth } = this.props;
    await oktaAuth.signOut({ postLogoutRedirectUri: window.location.orign + '/login' });
  }

  async componentDidMount() {
    this._isMounted = true;
    this.checkUser();
    // if (window.performance) {
    //   if (performance.navigation.type === 1) {
    //     console.log( "accessClaims This page is reloaded" );
    //   } else {
    //     console.log ( "accessClaims This page is not reloaded");
    //   }
    // }
  }

  loginFlagChange = async () => {
    const { requestLogin, oktaAuth } = this.props;
    this.setState({ userInfo: null });
    await oktaAuth.signInWithRedirect('/');
    requestLogin(false);
    sessionStorage.setItem('token-satus', 'login-again');
    //this._isMounted = true;
    history.push('/');
    this.checkUser();
  };

  async componentDidUpdate(prevProps) {
    this._isMounted = true;
    const { authCheckRequested, selectUserDetails, logout, oktaAuth, authState, requestSetUser } = this.props;

    const { currentExpireTime } = this.state;

    const tokens = await oktaAuth.tokenManager.getTokens();
    const accessToken = tokens.accessToken;

    if (
      currentExpireTime &&
      accessToken.expiresAt &&
      accessToken.expiresAt !== currentExpireTime &&
      sessionStorage.getItem('token-satus') !== 'expired'
    ) {
      let tokenInfo = null;
      const authStates = await authState;
      let userInfo = await oktaAuth.token.getUserInfo();
      await authCheckRequested();
      userInfo.group = getUserGroup(userInfo);

      tokenInfo = getFullToken(authStates);
      if (authStates) {
        requestSetUser(tokenInfo);
      }

      this.setState({ currentExpireTime: accessToken.expiresAt });
    }

    if (Object.keys(prevProps.selectUserDetails).length === 0 && prevProps.selectUserDetails !== selectUserDetails) {
      await authCheckRequested();
    }
    if (!logout && prevProps.logout !== logout && sessionStorage.getItem('token-satus') !== 'expired') {
      await authCheckRequested();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { loginAuthFinish, authState, userValid, groupValid } = this.props;
    const { userInfo } = this.state;

    /* if (authState.isPending) {
      return <Spinner large />;
    } else  */

    if (userInfo && !userInfo.group) {
      return <UserNotExistsError />;
    } else if (!groupValid) {
      return <UserMismatchError />;
    } else if (!userValid) {
      return <UserNotExistsError />;
    }
    return authState.isAuthenticated && sessionStorage.getItem('token-satus') !== 'expired' ? (
      <div>
        {userInfo !== null && loginAuthFinish ? (
          <Router>
            <App userInfo={userInfo} navRoutes={navRoutes}>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  {routes.map((route) => (
                    <Route key={route.id} path={route.path} exact component={route.component} />
                  ))}
                </Switch>
              </Suspense>
            </App>
          </Router>
        ) : null}
      </div>
    ) : (
      <div>
        <div className="page-align-items">
          <Container fluid>
            <H1 className="u-sm-ib u-va-m">You must be logged in to view this page</H1>
            <Button extraClassName="m2-sm u-sm-ib u-va-m" onClick={this.loginFlagChange}>
              Login
            </Button>
          </Container>
        </div>
      </div>
    );
  }
}
export const mapStateToProps = (state) => {
  return {
    selectUserDetails: selectUserDetails(state),
    loginAuthFinish: loginAuthFinish(state),
    logout: logout(state),
    userValid: validUser(state),
    groupValid: validGroup(state),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  requestSetUser: (params) => {
    dispatch(setUserLogin(params));
  },
  requestLogin: (params) => {
    dispatch(setUserLogout(params));
  },
  authCheckRequested: () => {
    dispatch(authCheckRequested());
  },
});
export { Login };
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(Login));
